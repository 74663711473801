import { type Ctx, downloader, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  AccountingClientsList,
  AccountingDocumentsList,
  AccountingPaymentsList,
  VariousOperationListType,
  MatchingTransfersExceptionsList
} from 'api/models'

export const accountingRouter = ({ token }: Ctx) => ({
  documentsList: async (params: string) =>
    fetcher<AccountingDocumentsList>({
      url: `${BASE_URL}${API.ACCOUNTING_DOCUMENTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  clientsList: async (params: string) =>
    fetcher<AccountingClientsList>({
      url: `${BASE_URL}${API.ACCOUNTING_CLIENTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  paymentsList: async (params: string) =>
    fetcher<AccountingPaymentsList>({
      url: `${BASE_URL}${API.ACCOUNTING_PAYMENTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getClientsExport: async (params: string) =>
    downloader(
      {
        url: `${BASE_URL}${API.ACCOUNTING_CLIENTS_EXPORT}${formatParams(params)}`,
        method: 'GET',
        token: token
      },
      'client_export.xlsx'
    ),
  getClientsExportSage: async (params: string) =>
    downloader(
      {
        url: `${BASE_URL}${API.ACCOUNTING_CLIENTS_EXPORT_SAGE}${formatParams(params)}`,
        method: 'GET',
        token: token
      },
      'client_export_sage.csv'
    ),
  getPaymentsExport: async (params: string) =>
    downloader(
      {
        url: `${BASE_URL}${API.ACCOUNTING_PAYMENTS_EXPORT}${formatParams(params)}`,
        method: 'GET',
        token: token
      },
      'journaldereglement.csv'
    ),
  getMatchingTransfersExceptions: async (params: string) =>
    fetcher<MatchingTransfersExceptionsList>({
      url: `${BASE_URL}${API.ACCOUNTING_MATCHING_TRANSFERS_EXCEPTIONS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getVariousOperationsList: async (params: string) =>
    fetcher<VariousOperationListType>({
      url: `${BASE_URL}${API.ACCOUNTING_VARIOUS_OPERATIONS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getVariousOperationsExport: async (params: string) =>
    downloader(
      {
        url: `${BASE_URL}${API.ACCOUNTING_VARIOUS_OPERATIONS_EXPORT}${formatParams(params)}`,
        method: 'GET',
        token: token
      },
      'journalod.csv'
    )
})
