import { Card, CardContent } from '@mui/material'

import { TitleComponent } from 'app/components/titles/title.component'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContractInvoiceList } from 'api/models'
import { formatCurrency, formatDate, formatInvoiceStatus } from 'app/utils/format'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { API } from 'api/constants'

interface IInvoicesProps {
  invoiceValues: ContractInvoiceList
  isLoading: boolean
}

export const InvoicesComponent = ({ invoiceValues, isLoading }: IInvoicesProps) => {
  const { t } = useTranslation()
  const [initMap, setInitMap] = useState<Map<string, any>>(new Map())

  useEffect(() => {
    setInitMap(() => {
      const map = new Map()
      map.set('items', [
        {
          label: t('state').toUpperCase(),
          value: 'state',
          component: (row: any) => formatInvoiceStatus(row.state, row.stateLabel)
        },
        {
          label: t('reference').toUpperCase(),
          value: 'reference',
          link: API.INVOICES_INFORMATIONS
        },
        { label: t('type').toUpperCase(), value: 'type' },
        { label: t('due_date').toUpperCase(), value: 'dueDate', format: formatDate },
        { label: t('amount_ttc').toUpperCase(), value: 'totalCost', format: formatCurrency }
      ])
      map.set('data', invoiceValues)
      return map
    })
  }, [invoiceValues])

  return (
    <Card>
      <CardContent>
        <TitleComponent text={t('invoices')} variant={'h3'} paddingLeft={12} />
        <ListSheetComponent isLoading={isLoading} data={initMap} />
      </CardContent>
    </Card>
  )
}
