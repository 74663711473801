import styled from '@emotion/styled'
import {
  Cake,
  CalendarMonth,
  CalendarToday,
  EventBusy,
  Handshake,
  HourglassTop,
  Person,
  Receipt,
  SquareFoot,
  Apartment,
  AssignmentInd,
  Language
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Button, Grid, MenuItem, Paper, Select, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'

import { Contract, FormItems } from 'api/models'
import {
  formatCommitment,
  formatCurrency,
  formatDate,
  formatRate,
  formatSurface
} from 'app/utils/format'
import { darken } from 'polished'
import { useTheme } from '@mui/system'
import { Link } from 'app/components/link.component'
import { TooltipGuarantees } from 'modules/contracts/tooltip-guarantees.component'
import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'

const AlternatePaper = styled(Paper)`
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  background: ${(props) =>
    props.theme.palette.mode === 'dark'
      ? darken(0.03, props.theme.palette.background.paper)
      : props.theme.palette.grey[400]};
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0);
  height: 100%;
`

const Icon = styled('div')`
  background-color: #fff;
  color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 4px;
  padding: 8px;
`

const Item = styled(Stack)`
  gap: 8px;
  flex-direction: row;
  height: fit-content;
  align-items: center;
`

const ItemName = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 600;
`

const AlternateCustomGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 1rem;
  gap: 1rem;
`

const CustomGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding: 1rem;

  ${({ theme }) => theme.breakpoints.between('md', 'lg')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: repeat(2, 1fr);
  }
`

interface IContractDetailsProps {
  contract: Contract
}

export const ContractDetails = ({ contract }: IContractDetailsProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { updateContract, getFormItems } = useFetcher()
  const { handleMutation } = useFeedback()

  const [individuals, setIndividuals] = React.useState('')
  const [languages, setLanguages] = React.useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([['languages', 'language']])
  )
  const [formItems, setFormItems] = useState({} as FormItems)

  const initOptions = useCallback(async (commonOptions: Map<string, string>) => {
    await getFormItems
      .mutateAsync(Array.from(commonOptions.keys() as any))
      .then((optionsData) => {
        setFormItems(optionsData as FormItems)
      })
      .finally(() => setIsLoading(false))
  }, [])

  const handleIndividualsChange = useCallback(
    (e: any) => {
      if (e.target.value) setIndividuals(e.target.value)
    },
    [setIndividuals]
  )

  const send = useCallback(
    async (dataKey: string, dataValue: string, confirmContent: string) => {
      await handleMutation({
        onStart: () => setIsLoading(true),
        mutation: updateContract,
        data: { id: contract.id, data: { [dataKey]: Number(dataValue) } },
        confirm: {
          title: t('confirm'),
          content: t(confirmContent)
        },
        onEnd: () => setIsLoading(false)
      })
    },
    [handleMutation, contract, updateContract]
  )

  const handleLanguagesChange = useCallback(
    (e: any) => {
      if (e.target.value) setLanguages(e.target.value)
    },
    [setLanguages]
  )

  useEffect(() => {
    initOptions(commonOptions).then()
    setLanguages(String(contract.language))
    setIndividuals(String(contract.signatoryId) ?? '')
  }, [])

  return (
    <Box sx={{ paddingLeft: 0, marginY: '2rem' }}>
      <Paper sx={{ boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0)' }}>
        <Grid
          container
          columns={12}
          sx={{
            borderRadius: 2,
            background:
              theme.palette.mode === 'dark'
                ? darken(0.03, theme.palette.background.paper)
                : theme.palette.grey[100],
            boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0)'
          }}
        >
          <Grid item xs={9}>
            <CustomGrid>
              <Item>
                <Icon>
                  <Apartment />
                </Icon>
                <Stack>
                  <Typography variant="caption">{t('center')}</Typography>
                  <ItemName variant="body2">{contract.centerName}</ItemName>
                </Stack>
              </Item>

              <Item>
                <Icon>
                  <SquareFoot />
                </Icon>
                <Stack>
                  <Typography variant="caption">{t('surface')}</Typography>
                  <ItemName variant="body2">{formatSurface(contract.surface)}</ItemName>
                </Stack>
              </Item>

              <Item>
                <Icon>
                  <CalendarToday />
                </Icon>
                <Stack>
                  <Typography variant="caption">{t('begin_date')}</Typography>
                  <ItemName variant="body2">{formatDate(contract.begin)}</ItemName>
                </Stack>
              </Item>

              <Item>
                <Icon>
                  <EventBusy />
                </Icon>
                <Stack>
                  <Typography variant="caption">{t('end_date_commitment')}</Typography>
                  <ItemName variant="body2">{formatDate(contract.deadline)}</ItemName>
                </Stack>
              </Item>

              <Item>
                <Icon>
                  <Handshake />
                </Icon>
                <Stack>
                  <Typography variant="caption">{t('commitment')}</Typography>
                  <ItemName variant="body2">
                    {formatCommitment(String(contract.commitmentReal))}
                  </ItemName>
                </Stack>
              </Item>

              <Item>
                <Icon>
                  <HourglassTop />
                </Icon>
                <Stack>
                  <Typography variant="caption">{t('months_remaining')}</Typography>
                  <ItemName variant="body2">{contract.remainingMonth}</ItemName>
                </Stack>
              </Item>

              <Item>
                <Icon>
                  <CalendarMonth />
                </Icon>
                <Stack>
                  <Typography variant="caption">{t('prior_notice')}</Typography>
                  <ItemName variant="body2">{formatDate(contract.priorNotice)}</ItemName>
                </Stack>
              </Item>

              <Item>
                <Icon>
                  <Cake />
                </Icon>
                <Stack>
                  <Typography variant="caption">{t('next_indexation')}</Typography>
                  <ItemName variant="body2">{formatDate(contract.nextIndexation)}</ItemName>
                </Stack>
              </Item>

              <Item>
                <Icon>
                  <Receipt />
                </Icon>
                <Stack>
                  <Typography variant="caption">{t('biling')}</Typography>
                  <ItemName variant="body2">{contract.invoicingTypeLabel}</ItemName>
                </Stack>
              </Item>

              <Item>
                <Icon>
                  <Person />
                </Icon>
                <Stack>
                  <Typography variant="caption">{t('staff')}</Typography>
                  <ItemName variant="body2">
                    {contract.staffFirstName} {contract.staffLastName}
                  </ItemName>
                </Stack>
              </Item>

              <Item>
                <Icon>
                  <AssignmentInd />
                </Icon>
                {contract.signatoryId && contract.state != 1 && (
                  <Stack>
                    <Typography variant="caption">{t('signatory')}</Typography>
                    <ItemName variant="body2">
                      <Link to={`/individuals/${contract.signatoryId}`}>
                        {contract.signatoryName}
                      </Link>
                    </ItemName>
                  </Stack>
                )}
                {contract.state === 1 && (
                  <Stack>
                    <Typography variant="caption">{t('signatory')}</Typography>
                    <ItemName variant="body2">
                      <Select
                        label={t('signatory')}
                        name={'individuals'}
                        value={individuals || String(contract.signatoryId)}
                        onChange={handleIndividualsChange}
                        size={'small'}
                        fullWidth
                      >
                        {contract.individuals.map((individual) => (
                          <MenuItem key={individual.id} value={individual.id}>
                            {individual.labelledName}
                          </MenuItem>
                        ))}
                      </Select>
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => send('individual', individuals, 'confirm_update_signatory')}
                      >
                        {t('save')}
                      </Button>
                    </ItemName>
                  </Stack>
                )}
              </Item>

              <Item>
                <Icon>
                  <Language />
                </Icon>
                {contract.language && contract.state != 1 && (
                  <Stack>
                    <Typography variant="caption">{t('language')}</Typography>
                    <ItemName variant="body2">
                      {formItems.languages?.values[contract.language].label}
                    </ItemName>
                  </Stack>
                )}
                {contract.language && contract.state === 1 && (
                  <Stack>
                    <Typography variant="caption">{t('language')}</Typography>
                    <ItemName variant="body2">
                      <Select
                        label={t('language')}
                        size={'small'}
                        name={'language'}
                        value={languages || String(contract.language)}
                        onChange={handleLanguagesChange}
                        fullWidth
                      >
                        {formItems.languages?.values.map((language) => (
                          <MenuItem key={language.id} value={language.id}>
                            {language.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => send('language', languages, 'confirm_update_language')}
                      >
                        {t('save')}
                      </Button>
                    </ItemName>
                  </Stack>
                )}
              </Item>
            </CustomGrid>
          </Grid>
          <Grid item xs={3} style={{ flexGrow: 1 }}>
            <AlternatePaper>
              <AlternateCustomGrid>
                <Stack>
                  <Typography variant="caption">{t('month_payment')}</Typography>
                  <ItemName variant="body2">
                    {formatCurrency(+contract.mainPrice + +contract.optionsPrice)}
                  </ItemName>
                </Stack>

                <Stack>
                  <Typography variant="caption">{t('main_price')}</Typography>
                  <ItemName variant="body2">{formatCurrency(contract.mainPrice)}</ItemName>
                </Stack>

                <Stack>
                  <Typography variant="caption">{t('options')}</Typography>
                  <ItemName variant="body2">{formatCurrency(contract.optionsPrice)}</ItemName>
                </Stack>

                <Stack>
                  <Typography variant="caption">{t('square_meter_price')}</Typography>
                  <ItemName variant="body2">{formatCurrency(contract.squareMeterPrice)}</ItemName>
                </Stack>

                <Stack>
                  <Typography variant="caption">{t('reduction')}</Typography>
                  <ItemName variant="body2">{formatRate(contract.reduction)}</ItemName>
                </Stack>
                <Stack>
                  <Typography variant="caption">{t('guarantees')}</Typography>
                  <ItemName variant="body2">
                    <Stack direction={'row'} gap={2}>
                      {formatCurrency(contract.guaranteesInformation.current)}
                      {(contract.guaranteesInformation.diff > 0 ||
                        contract.guaranteesInformation.previous > 0) && (
                        <TooltipGuarantees contract={contract} />
                      )}
                    </Stack>
                  </ItemName>
                </Stack>
              </AlternateCustomGrid>
            </AlternatePaper>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}
