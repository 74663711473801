import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { DiscountGridAdd, DiscountGridRead, DiscountGridsList } from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const discountGridRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<DiscountGridsList>({
      url: `${BASE_URL}${API.DISCOUNT_GRIDS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  read: async (id: string) =>
    fetcher<DiscountGridRead>({
      url: `${BASE_URL}${urlHelper(API.DISCOUNT_GRID, { id })}`,
      method: 'GET',
      token: token
    }),
  create: async (data: DiscountGridAdd) =>
    fetcher<void>({
      url: `${BASE_URL}${API.DISCOUNT_GRIDS}`,
      method: 'POST',
      token: token,
      body: data
    })
})
