import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import type { DuplicateEnterpriseType } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { FiltersBox } from 'app/components/filters/filter-box'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { List } from 'app/components/lists/list'

export const DuplicateEnteprisesView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getDuplicateEnterprises, setSearchParams, searchParams } = useFetcher()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter
  } = useList()
  const [duplicateEnterprises, setDuplicateEnterprises] = useState<DuplicateEnterpriseType[]>([])
  const [commonFilters] = useState<Map<string, string>>(new Map<string, string>([['', '']]))
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      setListIsLoading(true)
      getDuplicateEnterprises
        .mutateAsync()
        .then((data) => {
          setIsLast(data.last)
          setOffset(data.actual)
          setTotal(data.total)
          setDuplicateEnterprises(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))
    },
    [getDuplicateEnterprises, setIsLast, setOffset, setTotal]
  )

  useEffect(() => {
    initFilters(commonFilters).then(() => refreshList(true))
  }, [])

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom display="inline">
          {t('duplicate_enterprises')}
        </Typography>
        <Grid>
          <FiltersBox
            filters={filtersList}
            handleFilters={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          >
            <SearchClientInput
              slug="client"
              defaultIsIndividual={false}
              onChange={() => handleFilter(refreshList, true)}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
            />
          </FiltersBox>
        </Grid>
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom>
            {duplicateEnterprises.length} / {total}
          </Typography>
        </Grid>
        <List
          items={duplicateEnterprises}
          columns={[
            {
              label: t('reference'),
              slug: 'reference',
              link: { base: '/enterprises', slug: 'id' }
            },
            { label: t('name'), slug: 'name', link: { base: '/enterprises', slug: 'id' } },
            { label: t('siret'), slug: 'siret' },
            {
              label: t('duplicate_reference'),
              slug: 'reference2',
              link: { base: '/enterprises', slug: 'id2' }
            },
            {
              label: t('duplicate_name'),
              slug: 'name2',
              link: { base: '/enterprises', slug: 'id2' }
            },
            { label: t('duplicate_siret'), slug: 'siret2' }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {duplicateEnterprises.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Grid>
  )
}
