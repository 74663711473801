import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useQueryFetcherList } from 'app/hooks/use-query-fetcher-list'
import { useFetcher } from 'app/providers/fetcher.provider'

export const useCompaniesList = () => {
  const { Companies } = useFetcherV2()
  const { searchParams } = useFetcher()
  return useQueryFetcherList({
    queryKey: ['companies', 'list'],
    queryFn: () => Companies.list(searchParams.toString())
  })
}
