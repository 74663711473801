import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'

import type { Payment, PaymentsSum } from 'api/models'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { formatCurrency, formatPaymentMode, formatPaymentStatus } from 'app/utils/format'
import { FiltersBox } from 'app/components/filters/filter-box'
import { RangePicker } from 'app/components/filters/range-picker'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { useList } from 'app/providers/list.provider'
import { SummaryComponent } from 'app/components/lists/summary.component'

export const PaymentsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter
  } = useList()
  const { getPayments, getPaymentsSum, searchParams, setSearchParams } = useFetcher()
  const [payments, setPayments] = useState<Payment[]>([])
  const [summary, setSummary] = useState<PaymentsSum>({} as PaymentsSum)
  const [summaryItems, setSummaryItems] = useState<Map<string, string>>(new Map<string, string>())
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['payment_modes', 'paymentMode'],
      ['payment_types', 'paymentType'],
      ['payment_links', 'paymentLink']
    ])
  )

  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [summaryIsLoading, setSummaryIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      setListIsLoading(true)
      await getPayments
        .mutateAsync()
        .then((data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setPayments(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))

      setSummaryIsLoading(true)
      await getPaymentsSum
        .mutateAsync()
        .then((sum) => {
          setSummary(sum)
        })
        .finally(() => setSummaryIsLoading(false))
    },
    [getPayments, getPaymentsSum, setIsLast, setOffset, setTotal]
  )

  useEffect(() => {
    const newSummaryItems = new Map<string, string>()
    newSummaryItems.set('total_amount', formatCurrency(summary.totalAmount ?? 0))
    setSummaryItems(newSummaryItems)
  }, [summary])

  useEffect(() => {
    initFilters(commonFilters).then(() => refreshList(true))
  }, [])

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('payments')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePicker
            slug="created"
            label={'created_at'}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <RangePicker
            slug="paid"
            label={'paid_at'}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <SearchClientInput
            slug="client"
            defaultIsIndividual={false}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <SearchCenterInput
            slug="center"
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
          />
        </FiltersBox>
      </Grid>
      <Grid>
        <SummaryComponent items={summaryItems} isLoading={summaryIsLoading} />

        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {payments.length} / {total}
          </Typography>
        </Grid>
        <List
          items={payments}
          columns={[
            {
              label: t('status'),
              slug: 'state',
              text: 'stateLabel',
              link: { base: '/payments', slug: 'id' },
              valueFormatter: formatPaymentStatus
            },
            {
              label: '',
              slug: 'paymentMode',
              text: 'paymentModeLabel',
              valueFormatter: formatPaymentMode,
              link: { base: '/payments', slug: 'id' }
            },
            { label: t('id'), slug: 'id', link: { base: '/payments', slug: 'id' } },
            {
              label: t('accounting_document'),
              slug: 'accountingDocument',
              link: { base: '/invoices', slug: 'accountingDocumentId' }
            },
            { label: t('client'), slug: 'client', link: { base: '/clients', slug: 'clientId' } },
            {
              label: t('contract'),
              slug: 'contract',
              link: { base: '/contracts', slug: 'contractId' }
            },
            { label: t('center'), slug: 'center' },
            { label: t('payment_type'), slug: 'paymentTypeLabel' },
            { label: t('reference'), slug: 'reference' },
            { label: t('created_at'), slug: 'created' },
            { label: t('paid_at'), slug: 'paid' },
            { label: t('amount'), slug: 'amount' }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {payments.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
