import { ApexOptions } from 'apexcharts'
import { OpportunitiesStats } from 'api/models/stats'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import getLocales from 'app/utils/apexlocales.util'
import i18n, { t } from 'i18next'
import { Card, CardContent, useTheme, Box } from '@mui/material'
import localeData from 'dayjs/plugin/localeData'
import dayjs from 'dayjs'
import { ThemedChart } from 'app/components/charts/timeline.component'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { useFeedback } from 'app/providers/feedback.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
dayjs.extend(localeData)

export const LeadsNumberComponent = function ({
  height,
  params,
  labels
}: {
  height: number
  params: URLSearchParams
  labels: Array<any>
}) {
  const [locales, setLocales] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const theme = useTheme()
  const [formatedRows, setFormatedRows] = useState<any>([])
  const { handleMutation } = useFeedback()
  const { getStatsOpportunities } = useFetcher()

  const refreshList = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getStatsOpportunities,
      onSuccess: (data: OpportunitiesStats) => {
        if (!data) return
        const formated = Object.entries(data)
          .filter(([label]) => label !== 'Total')
          .map(([name, values]) => ({
            name: name,
            data: Object.entries(values).map(([key, value]) => ({
              x: key,
              y: Object.values(value)[0]
            })),
            type: 'bar'
          }))
        setFormatedRows(formated)
      },
      onEnd: () => setIsLoading(false)
    })
  }, [getStatsOpportunities, handleMutation])

  useEffect(() => {
    refreshList().then()
  }, [params])

  useEffect(() => {
    getLocales().then((locales: any) => setLocales(locales))
  }, [])

  const options = useMemo(() => {
    const data: ApexOptions = {
      chart: {
        type: 'bar',
        stacked: true,
        locales: [locales],
        defaultLocale: i18n.language,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: false
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        x: {
          formatter: function (val) {
            return dayjs(val).format('MMMM YYYY')
          }
        },
        y: {
          title: {
            formatter: function (val) {
              return val + ' :'
            }
          }
        }
      },
      yaxis: {
        title: { text: t('leads_number') }
      },
      xaxis: {
        type: 'numeric',
        categories: labels,
        tickAmount: labels.length,
        labels: {
          rotate: -45,
          rotateAlways: true,
          format: 'MMM yyyy',
          formatter: function (val) {
            return dayjs(val).format('MMM YYYY')
          }
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      legend: {
        position: 'top',
        markers: {
          shape: 'circle'
        }
      },
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '70%'
        }
      },
      colors: [
        '#C4F5FC', // Light cyan
        '#613F75', // Finn
        '#156064', // Caribbean
        '#B7FFD8', // Aquamarine
        '#F5D7E3', // Mimi pink
        '#4357AD', // Violet blue
        '#C9F299', // Mindaro
        '#E55381', // Blush
        '#FFF07C', // Maize
        '#52D1DC' // Robin egg blue
      ],
      theme: {
        mode: theme.palette.mode
      }
    }
    return data
  }, [labels, theme.palette.mode, locales])
  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardContent>
        {isLoading ? (
          <CardSkeleton height={height} />
        ) : (
          <Box className="line">
            <ThemedChart options={options} series={formatedRows} type="bar" height={height} />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
