import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import {
  AccountCircle,
  Euro,
  Handshake,
  HourglassTop,
  PlayArrow,
  SquareFoot,
  Stop,
  TextSnippet,
  InsertDriveFile,
  Check,
  CalendarToday
} from '@mui/icons-material'

import type { ContractItem, ContractSum } from 'api/models'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import {
  formatContractState,
  formatContractType,
  formatCurrency,
  formatDate,
  formatSurface,
  formatSurfacePrice
} from 'app/utils/format'
import { RangePicker } from 'app/components/filters/range-picker'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { useList } from 'app/providers/list.provider'
import { SummaryComponent } from 'app/components/lists/summary.component'
import { useApp } from 'app/providers/app.provider'
import { FiltersContainer } from 'app/components/filters/filters-container'

export const ContractsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { user } = useApp()
  const { isLast, total, orderBy, setOffset, setTotal, setIsLast, handleSort, handleFilter } =
    useList()
  const { getContracts, getContractSum, getContractExport, setSearchParams, searchParams } =
    useFetcher()
  const [contracts, setContracts] = useState<ContractItem[]>([])
  const [summary, setSummary] = useState<ContractSum>({} as ContractSum)
  const [summaryItems, setSummaryItems] = useState<Map<string, string>>(new Map<string, string>())
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['contract_status', 'state'],
      ['contract_type_filtered', 'type'],
      ['commitment', 'commitment']
    ])
  )

  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [summaryIsLoading, setSummaryIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      setListIsLoading(true)
      await getContracts
        .mutateAsync()
        .then((data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setContracts(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))

      setSummaryIsLoading(true)
      await getContractSum
        .mutateAsync()
        .then((sum) => {
          setSummary(sum)
        })
        .finally(() => setSummaryIsLoading(false))
    },
    [getContractSum, getContracts, setIsLast, setOffset, setTotal]
  )

  useEffect(() => {
    const newSummaryItems = new Map<string, string>()
    newSummaryItems.set('total_amount', formatCurrency(summary.totalPrice ?? 0))
    newSummaryItems.set('main_price', formatCurrency(summary.mainPrice ?? 0))
    newSummaryItems.set('options_price', formatCurrency(summary.optionsPrice ?? 0))
    newSummaryItems.set('total_surface', `${formatCurrency(summary.surface ?? 0)} m²`)
    setSummaryItems(newSummaryItems)
  }, [summary])

  const exportToPDF = useCallback(async () => {
    await getContractExport.mutateAsync()
  }, [getContractExport])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('contracts')}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          commonFilters={commonFilters}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          onChange={() => handleFilter(refreshList, true)}
        >
          <RangePicker
            slug="agreement"
            label={'agreement_on'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <RangePicker
            slug="confirm"
            label={'confirmed_on'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <RangePicker
            slug="begin_at"
            label={'begin_at'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <RangePicker
            slug="end_at"
            label={'end_at'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <RangePicker
            slug="deadline"
            label={'end'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <RangePicker
            slug="prior_notice"
            label={'prior_notice'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <SearchClientInput
            slug="client"
            defaultIsIndividual={false}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <SearchCenterInput
            slug="center"
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
            defaultIsCluster={true}
            initialValue={user?.mainCenter}
          />
          <Button variant="contained" startIcon={<TextSnippet />} onClick={exportToPDF}>
            {t('export')}
          </Button>
        </FiltersContainer>
      </Grid>
      <Grid>
        <SummaryComponent items={summaryItems} isLoading={summaryIsLoading} />

        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {contracts.length} / {total}
          </Typography>
        </Grid>
        <List
          items={contracts}
          columns={[
            {
              label: t('state'),
              slug: 'state',
              text: 'stateLabel',
              valueFormatter: formatContractState
            },
            { label: t('reference'), slug: 'reference', link: { base: '/contracts', slug: 'id' } },
            {
              label: t('client'),
              slug: 'client',
              link: { base: '/enterprises', slug: 'clientId' }
            },
            { label: t('center'), slug: 'center' },
            { label: t('type'), slug: 'type', valueFormatter: formatContractType },
            { label: AccountCircle, tooltip: t('staff'), slug: 'staffLight' },
            { label: PlayArrow, tooltip: t('begin'), slug: 'begin', valueFormatter: formatDate },
            { label: Stop, tooltip: t('end'), slug: 'end', valueFormatter: formatDate },
            {
              label: HourglassTop,
              tooltip: t('end_date_commitment'),
              slug: 'deadline',
              valueFormatter: formatDate
            },
            { label: Handshake, tooltip: t('commitment'), slug: 'commitment' },
            {
              label: SquareFoot,
              tooltip: t('surface'),
              slug: 'surface',
              valueFormatter: formatSurface
            },
            {
              label: Euro,
              tooltip: t('main_price'),
              slug: 'mainPrice',
              valueFormatter: formatCurrency
            },
            { label: t('options'), slug: 'optionsPrice', valueFormatter: formatCurrency },
            {
              label: t('surface_price'),
              slug: 'squareMeterPrice',
              valueFormatter: formatSurfacePrice
            },
            { label: InsertDriveFile, slug: 'agreement', valueFormatter: formatDate },
            { label: Check, slug: 'confirm', valueFormatter: formatDate },
            { label: CalendarToday, slug: 'priorNotice', valueFormatter: formatDate }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {contracts.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
