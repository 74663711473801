import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useQuery } from '@tanstack/react-query'
import { formatCurrency } from 'app/utils/format'
import { BorderedListComponent } from 'app/components/lists/bordered-list.component'

interface IProps {
  center: number
}

const TableParkingsOccupation = ({ center }: IProps) => {
  const { t } = useTranslation()
  const { Stats } = useFetcherV2()
  const { data = [], isFetching } = useQuery({
    queryKey: ['stats', 'parking-occupation', 'clients', center],
    queryFn: () => Stats.parkingsOccupationByClients(center)
  })

  return (
    <BorderedListComponent
      columns={[
        {
          key: 'clientName',
          label: t('client'),
          link: '/enterprises/:clientId'
        },
        {
          key: 'count',
          label: t('number_of_spots')
        },
        {
          key: 'totalPrice',
          label: t('total'),
          valueFormatter: (data) => formatCurrency(data.totalPrice)
        },
        {
          key: 'avgPrice',
          label: t('avg'),
          valueFormatter: (data) => formatCurrency(data.avgPrice)
        }
      ]}
      data={data}
      keyBy={'clientId'}
      isLoading={isFetching}
    />
  )
}

export default TableParkingsOccupation
