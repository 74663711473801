import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { RightList, Right } from 'api/models'

export const rightsRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<RightList>({
      url: `${BASE_URL}${API.RIGHTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  get: async (id: string) =>
    fetcher<Right>({
      url: `${BASE_URL}${API.RIGHTS}/${id}`,
      method: 'GET',
      token: token
    }),
  edit: async (data: Right) =>
    fetcher({
      url: `${BASE_URL}${API.RIGHTS}/${data.id}`,
      method: 'PATCH',
      token: token,
      body: data
    })
})
