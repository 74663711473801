import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Card, CardContent as MuiCardContent, Grid, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { ApexOptions } from 'apexcharts'
import { useFetcher } from 'app/providers/fetcher.provider'
import dayjs from 'dayjs'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import getLocales from 'app/utils/apexlocales.util'
import { ThemedChart } from 'app/components/charts/timeline.component'
import { useFeedback } from 'app/providers/feedback.provider'

const CardContent = styled(MuiCardContent)`
  position: relative;
`

export const OccupancyChart = ({
  height,
  params,
  labels
}: {
  height: number
  params: URLSearchParams
  labels: Array<any>
}) => {
  const { getStatsOccupations } = useFetcher()
  const [occupations, setOccupations] = useState<any>([])
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const theme = useTheme()
  const [locales, setLocales] = useState<any>([])
  const { handleMutation } = useFeedback()

  const refreshList = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getStatsOccupations,
      onSuccess: (data) => {
        if (!data) return
        const occupiedRate: number[] = []
        const renewedRate: number[] = []
        const targetRate: number[] = []
        const totalRate: number[] = []

        data.forEach(({ items }) => {
          const currentItem = items.length > 2 ? items[items.length - 1] : items[0]
          const renewed = currentItem.occupiedRate - currentItem.renewedRate
          const occupied = currentItem.renewedRate

          totalRate.push(renewed + occupied)
          occupiedRate.push(occupied)
          renewedRate.push(renewed)
          targetRate.push(currentItem.targetRate)
        })

        const formatData = [
          { name: t('in_progress'), type: 'bar', data: occupiedRate, color: '#227fd2' },
          { name: t('renewed'), type: 'bar', data: renewedRate, color: '#98d9d9' },
          { name: t('bp'), type: 'line', data: targetRate, color: '#ff8219' },
          { name: t('total_contracts'), type: 'scatter', data: totalRate, color: 'red' }
        ]

        setOccupations(formatData)
      },
      onEnd: () => setIsLoading(false)
    })
  }, [getStatsOccupations, handleMutation, t])

  const searchParams = useMemo(() => params.toString(), [params])

  useEffect(() => {
    refreshList().then()
  }, [searchParams])

  useEffect(() => {
    getLocales().then((locales: any) => setLocales(locales))
  }, [])

  const options = useMemo(() => {
    const data: ApexOptions = {
      chart: {
        toolbar: {
          tools: {
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false
          }
        },
        stacked: true,
        locales: [locales],
        defaultLocale: i18n.language
      },
      stroke: {
        width: [0, 0, 2],
        curve: 'smooth'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      legend: {
        position: 'top'
      },
      yaxis: {
        labels: {
          formatter: (val) => Math.floor(val) + ' %'
        }
      },
      xaxis: {
        categories: labels,
        labels: {
          rotate: -45,
          rotateAlways: true,
          formatter: function (value) {
            return dayjs(value).format('MMM YY')
          }
        },
        tooltip: {
          enabled: false
        }
      },
      tooltip: {
        x: {
          formatter: function (val) {
            return dayjs(labels[val - 1]).format('MMMM YYYY')
          }
        },
        y: {
          title: {
            formatter: function (val) {
              return val + ' :'
            }
          },
          formatter: function (y) {
            if (typeof y !== 'undefined') {
              return y.toFixed(2) + ' %'
            }
            return y
          }
        }
      },
      theme: {
        palette: 'palette5',
        mode: theme.palette.mode
      }
    }
    return data
  }, [labels, theme.palette.mode, i18n.language, locales])

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Card>
          <CardContent>
            {isLoading ? (
              <CardSkeleton height={height} />
            ) : (
              <Box className="line">
                <ThemedChart options={options} series={occupations} type="line" height={height} />
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
