import React, { useEffect, useMemo, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { FiltersBox } from 'app/components/filters/filter-box'
import { useList } from 'app/providers/list.provider'
import { LeadsNumberComponent } from 'modules/stats/components/leads-number.component'
import { EngagedCostsComponent } from 'modules/stats/components/engaged-costs.component'
import dayjs from 'dayjs'
import { RangePeriodPicker } from 'app/components/filters/range-period-picker'

export const PerformanceChannelView = () => {
  const { t } = useTranslation()
  const { setSearchParams, searchParams } = useFetcher()
  const { filtersList, initFilters, handleFilter } = useList()

  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['centers', 'centers'],
      ['main_services_types_multiple', 'types']
    ])
  )

  const paramsFilters = useMemo(() => {
    return {
      types: {
        all: true,
        multiple: true
      }
    }
  }, [])

  const labels = useMemo(() => {
    const startDate = dayjs(searchParams.get('begin') ?? dayjs().startOf('month')).startOf('month')
    const endDate = dayjs(searchParams.get('end') ?? dayjs().startOf('month')).endOf('month')
    const monthsDifference = endDate.diff(startDate, 'month')
    return Array.from({ length: monthsDifference + 1 }, (_, i) =>
      startDate.add(i, 'month').format('YYYY-MM-DD')
    )
  }, [searchParams])

  useEffect(() => {
    if (!searchParams.get('begin') || !searchParams.get('end')) {
      const now = dayjs()
      searchParams.set('begin', now.subtract(12, 'months').startOf('month').format('YYYY-MM-DD'))
      searchParams.set('end', now.endOf('month').format('YYYY-MM-DD'))
    }

    initFilters(commonFilters, paramsFilters).then()
  }, [])

  return (
    <Container>
      <Box marginBottom={'2rem'}>
        <Typography variant="h2" gutterBottom display={'inline'}>
          {t('performance_channel')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(async () => {}, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePeriodPicker
            slugStart="begin"
            slugEnd="end"
            labelStart={t('begin_date')}
            labelEnd={t('end_date')}
            onChange={() => handleFilter(async () => {}, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersBox>
      </Grid>
      <Grid container>
        <Box marginBottom={'2rem'}>
          <Typography variant="h3">{t('leads_number')}</Typography>
        </Box>
      </Grid>

      <LeadsNumberComponent height={350} params={searchParams} labels={labels} />
      <Grid container>
        <Box marginBottom={'2rem'} marginTop={'2rem'}>
          <Typography variant="h3">{t('engaged_costs')}</Typography>
        </Box>
      </Grid>
      <EngagedCostsComponent height={350} params={searchParams} labels={labels} />
    </Container>
  )
}
