import React, { useCallback, useState } from 'react'
import { Box, Grid, Typography, Container } from '@mui/material'
import { PresenceLog } from 'api/models'
import { useTranslation } from 'react-i18next'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { List } from 'app/components/lists/list'
import { formatDate } from 'app/utils/format'
import { useFeedback } from 'app/providers/feedback.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { FiltersContainer } from 'app/components/filters/filters-container'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { dates, RangePicker } from 'app/components/filters/range-picker'

export const PresenceLogsView = () => {
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { getPresenceLogs, searchParams, setSearchParams } = useFetcher()
  const [attributionLogs, setAttributionLogs] = useState<PresenceLog[]>([])
  const { isLast, total, orderBy, setOffset, setTotal, setIsLast, handleFilter, handleSort } =
    useList()
  const refreshList = useCallback(
    async (reset: boolean) => {
      await handleMutation({
        onStart: () => setIsLoading(true),
        mutation: getPresenceLogs,
        onSuccess: (data) => {
          setIsLast(data.last)
          setOffset(data.actual)
          setTotal(data.total)
          setAttributionLogs(reset ? data.items : (prev) => [...prev, ...data.items])
        },
        onEnd: () => setIsLoading(false)
      })
    },
    [getPresenceLogs, setIsLast, setOffset, setTotal, setIsLoading, handleMutation]
  )

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('presence_logs')}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          onChange={() => handleFilter(refreshList, true)}
          initialOrder={[{ property: 'presenceDate', order: 'desc' }]}
          groupBy={['subcategory']}
        >
          <SearchCenterInput
            slug="center"
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
            withDefaultValue
            disableClearable
          />
          <SearchClientInput
            slug="client"
            defaultIsEnterprise={false}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <RangePicker
            slug="date"
            label={'Period_of_presence'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            valueLabel={dates.TODAY}
            isNoDate={false}
          />
        </FiltersContainer>
      </Grid>
      <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
        <Typography variant="body2" gutterBottom>
          {attributionLogs?.length} / {total}
        </Typography>
      </Grid>
      <List
        items={attributionLogs || []}
        columns={[
          {
            label: t('#'),
            slug: 'presenceId'
          },
          {
            label: t('center'),
            slug: 'centerName'
          },
          {
            label: t('client'),
            slug: 'individualName',
            link: { base: '/individuals', slug: 'individualId' }
          },
          {
            label: t('date'),
            slug: 'presenceDate',
            valueFormatter: formatDate
          },
          {
            label: t('type'),
            slug: 'presenceType'
          },
          {
            label: t('0h'),
            slug: 'h0'
          },
          {
            label: t('1h'),
            slug: 'h1'
          },
          {
            label: t('2h'),
            slug: 'h2'
          },
          {
            label: t('3h'),
            slug: 'h3'
          },
          {
            label: t('4h'),
            slug: 'h4'
          },
          {
            label: t('5h'),
            slug: 'h5'
          },
          {
            label: t('6h'),
            slug: 'h6'
          },
          {
            label: t('7h'),
            slug: 'h7'
          },
          {
            label: t('8h'),
            slug: 'h8'
          },
          {
            label: t('9h'),
            slug: 'h9'
          },
          {
            label: t('10h'),
            slug: 'h10'
          },
          {
            label: t('11h'),
            slug: 'h11'
          },
          {
            label: t('12h'),
            slug: 'h12'
          },
          {
            label: t('13h'),
            slug: 'h13'
          },
          {
            label: t('14h'),
            slug: 'h14'
          },
          {
            label: t('15h'),
            slug: 'h15'
          },
          {
            label: t('16h'),
            slug: 'h16'
          },
          {
            label: t('17h'),
            slug: 'h17'
          },
          {
            label: t('18h'),
            slug: 'h18'
          },
          {
            label: t('19h'),
            slug: 'h19'
          },
          {
            label: t('20h'),
            slug: 'h20'
          },
          {
            label: t('21h'),
            slug: 'h21'
          },
          {
            label: t('22h'),
            slug: 'h22'
          },
          {
            label: t('23h'),
            slug: 'h23'
          }
        ]}
        handleReset={() => handleFilter(refreshList, true)}
        sort={orderBy}
        handleSort={(property) => handleSort(() => refreshList(true), property)}
        isLoading={isLoading}
      />
      <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
        <Typography variant="body2" gutterBottom marginTop={2}>
          {attributionLogs?.length} / {total}
        </Typography>
      </Grid>
      {!isLast && <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />}
    </Container>
  )
}
