import { type Ctx, downloader, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { OccupancyList, OccupancySum } from 'api/models/occupancy'

export const occupancyRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<OccupancyList>({
      url: `${BASE_URL}${API.SERVICE_OCCUPANCY}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getSum: async (params: string) =>
    fetcher<OccupancySum>({
      url: `${BASE_URL}${API.SERVICE_OCCUPANCY_SUM}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getExport: async (params: string) =>
    downloader(
      {
        url: `${BASE_URL}${API.SERVICE_OCCUPANCY_EXPORT}${formatParams(params)}`,
        method: 'GET',
        token: token
      },
      'occupants.xlsx'
    )
})
