import { type Ctx, fetcher } from 'api'
import { BASE_URL, API } from 'api/constants'
import { TertiariesList, TertiaryBody, TertiaryCreate } from 'api/models'

export const tertiariesRouter = ({ token }: Ctx) => ({
  list: async () =>
    fetcher<TertiariesList>({
      url: `${BASE_URL}${API.TERTIARIES}`,
      method: 'GET',
      token: token
    }),
  create: async (data: TertiaryBody) =>
    fetcher<TertiaryCreate>({
      url: `${BASE_URL}${API.TERTIARIES}`,
      method: 'POST',
      token: token,
      body: data
    })
})
