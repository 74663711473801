import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { TertiariesList } from 'api/models'
import { AddingIndexComponent } from 'modules/tertiary/components/adding-index.component'

export const TertiariesView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { orderBy, handleSort, handleFilter } = useList()
  const { getTertiaries } = useFetcher()
  const [tertiaries, setTertiaries] = useState<TertiariesList>([])
  const { handleMutation } = useFeedback()
  const [isLoading, setIsLoading] = useState(true)

  const getData = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getTertiaries,
      onSuccess: (data) => setTertiaries(data),
      onEnd: () => setIsLoading(false)
    })
  }, [getTertiaries])

  useEffect(() => {
    getData().then()
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('ilat_title')}
        </Typography>
      </Box>
      <Grid>
        <AddingIndexComponent refreshData={getData} />
        <List
          items={tertiaries}
          columns={[
            {
              label: t('year'),
              slug: 'year',
              unsorted: true
            },
            {
              label: t('trimester'),
              slug: 'trimester',
              unsorted: true
            },
            {
              label: t('value'),
              slug: 'value',
              unsorted: true
            }
          ]}
          handleReset={() => handleFilter(getData, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(getData, property)}
          isLoading={isLoading}
        />
      </Grid>
    </Container>
  )
}
