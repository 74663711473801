import React from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { SearchInput } from 'app/components/filters/search-input'
import { AddCircle } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'
import { FiltersContainer } from 'app/components/filters/filters-container'
import { useQueryFetcherList } from 'app/hooks/use-query-fetcher-list'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'

export const HappeningListPartnersView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { isLast, total, orderBy, handleSort, handleFilter } = useList()
  const { getRight } = useApp()
  const { setSearchParams, searchParams } = useFetcher()
  const { Happenings } = useFetcherV2()

  const {
    isFetching: isLoading,
    items,
    refreshList
  } = useQueryFetcherList({
    queryKey: ['partner list', 'list'],
    queryFn: () => Happenings.getPartners(searchParams.toString())
  })

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('partners')}
          {getRight('happening', 'isAdd') && (
            <IconButton
              title={t('add_partner')}
              aria-label={t('add_partner')}
              color="primary"
              href={'/partner/add'}
              style={{ marginLeft: 7 }}
            >
              <AddCircle fontSize="small" />
            </IconButton>
          )}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          onChange={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <SearchInput
            slug={'name'}
            label={'name'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersContainer>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {items.length} / {total}
          </Typography>
        </Grid>
        <List
          items={items}
          columns={[
            {
              label: t('name'),
              slug: 'name'
            },
            {
              label: t('website'),
              slug: 'website'
            },
            {
              label: t('logo'),
              slug: 'logo'
            }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={isLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {items.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !isLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
