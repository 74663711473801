import { z } from 'zod'
import dayjs, { Dayjs } from 'dayjs'

const termsOfSaleSchema = z.object({
  id: z.number(),
  begin: z.string(),
  end: z.string(),
  indexationTypeLabel: z.string()
})

const termsOfSalesListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(termsOfSaleSchema)
})

export const uploadTermOfSalesInput = z.object({
  file: z.instanceof(File),
  begin: z.instanceof(dayjs as unknown as typeof Dayjs)
})

export const updateTermOfSalesInput = z.object({
  begin: z.instanceof(dayjs as unknown as typeof Dayjs).optional(),
  end: z.instanceof(dayjs as unknown as typeof Dayjs)
})

export type TermOfSalesItem = z.infer<typeof termsOfSaleSchema>
export type TermsOfSalesList = z.infer<typeof termsOfSalesListSchema>
export type UploadTermOfSalesInput = z.infer<typeof uploadTermOfSalesInput>
export type UpdateTermOfSalesInput = z.infer<typeof updateTermOfSalesInput>
