import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Typography, Container } from '@mui/material'
import { Attribution } from 'api/models'
import { useTranslation } from 'react-i18next'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { List } from 'app/components/lists/list'
import { formatDateWithTime } from 'app/utils/format'
import { useFeedback } from 'app/providers/feedback.provider'
import { ListPagination } from 'app/components/lists/list-pagination'

export const AttributionLogsView = () => {
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const { getAttributionLogs } = useFetcher()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [attributionLogs, setAttributionLogs] = useState<Attribution[]>([])
  const {
    total,
    isLast,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initSort,
    handleSort,
    handleFilter
  } = useList()

  const refreshList = useCallback(
    async (reset: boolean) => {
      await handleMutation({
        onStart: () => setIsLoading(true),
        mutation: getAttributionLogs,
        onSuccess: (data) => {
          setIsLast(data.last)
          setOffset(data.actual)
          setTotal(data.total)
          data.items.forEach((item) => {
            item.date = formatDateWithTime(item.date)
          })
          setAttributionLogs(reset ? data.items : (prev) => [...prev, ...data.items])
        },
        onEnd: () => setIsLoading(false)
      })
    },
    [getAttributionLogs, setIsLast, setOffset, setTotal, setIsLoading, handleMutation]
  )

  useEffect(() => {
    initSort([{ property: 'date', order: 'desc' }]).then(() => refreshList(true))
  }, [])

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('awards_history')}
        </Typography>
      </Box>
      <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
        <Typography variant="body2" gutterBottom>
          {attributionLogs?.length} / {total}
        </Typography>
      </Grid>
      <List
        items={attributionLogs || []}
        columns={[
          {
            label: t('reference'),
            slug: 'reference'
          },
          {
            label: t('type'),
            slug: 'type'
          },
          {
            label: t('name'),
            slug: 'labelledName'
          },
          {
            label: t('center'),
            slug: 'center'
          },
          {
            label: t('attribution_date_hour'),
            slug: 'date'
          }
        ]}
        handleReset={() => handleFilter(refreshList, true)}
        sort={orderBy}
        handleSort={(property) => handleSort(() => refreshList(true), property)}
        isLoading={isLoading}
      />
      <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
        <Typography variant="body2" gutterBottom marginTop={2}>
          {attributionLogs?.length} / {total}
        </Typography>
      </Grid>
      {!isLast && <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />}
    </Container>
  )
}
