import { z } from 'zod'

const noSpecialChar = new RegExp('^[a-zA-Z0-9]*$')

export const clientCenterFormSchema = z.object({
  accountingName: z.string().min(1).max(10).regex(noSpecialChar),
  accountingNumber: z.string().min(1).max(8).regex(noSpecialChar),
  isAccounted: z.boolean()
})

export type ClientCenterForm = z.infer<typeof clientCenterFormSchema>

export const clientCenterMailFormSchema = z.object({
  accountingMail: z.string().email()
})

export type ClientCenterMailForm = z.infer<typeof clientCenterMailFormSchema>
