import { AccoutingStatementItem } from 'api/models'
import { useNavigate } from 'react-router-dom'
import { Box, Paper, Stack, Typography } from '@mui/material'
import { formatCurrency, formatDateWithFormat, formatPaymentMode } from 'app/utils/format'
import { Link } from 'app/components/link.component'
import React from 'react'

export const TimelinePaymentCard = function ({ item }: { item: AccoutingStatementItem }) {
  const navigate = useNavigate()

  return (
    <Paper
      variant={'outlined'}
      onClick={() => navigate(`/payments/${item.id}`)}
      sx={{
        width: '560px',
        maxWidth: '50vw',
        position: 'absolute',
        padding: 2,
        right: 0
      }}
    >
      <Stack
        paddingX={2}
        direction={'row'}
        flexWrap={['wrap', 'nowrap']}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant={'caption'}>{formatCurrency(item.amount)}</Typography>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Box>
            <Typography variant={'caption'}>
              {item.typeLabel} {formatDateWithFormat(item.effectDate, 'L')}
            </Typography>
            <Typography variant={'body2'} fontWeight={'bold'}>
              <Link to={`/payments/${item.id}`}>{item.reference}</Link>
            </Typography>
          </Box>
          <Typography color={'primary'} lineHeight={'1rem'}>
            {item.paymentTypeId && item.paymentTypeLabel
              ? formatPaymentMode(item.paymentTypeId, item.paymentTypeLabel)
              : null}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  )
}
