import { type Ctx, fetcher } from 'api'
import { BASE_URL, API } from 'api/constants'
import { PricerDiscountGrids, PricerInformations, ServicesPricer } from 'api/models'

export const pricerRouter = ({ token }: Ctx) => ({
  services: async (id: string) =>
    fetcher<ServicesPricer>({
      url: `${BASE_URL}${API.PRICER_SERVICES.replace(':id', id)}`,
      method: 'GET',
      token: token
    }),

  informations: async (id: string) =>
    fetcher<PricerInformations>({
      url: `${BASE_URL}${API.PRICER_INFORMATIONS.replace(':id', id)}`,
      method: 'GET',
      token: token
    }),

  discountGrids: async (center: string, begin: string) =>
    fetcher<PricerDiscountGrids>({
      url: `${BASE_URL}${API.CONFIGURATION_DISCOUNT_GRIDS}?center=${center}&begin=${begin}`,
      method: 'GET',
      token: token
    })
})
