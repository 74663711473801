import { type Ctx, downloader, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  InvoiceSum,
  type InvoiceList,
  InvoiceInformation,
  InvoicePdf,
  Activities
} from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const invoicesRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<InvoiceList>({
      url: `${BASE_URL}${API.INVOICES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  exportInvoices: async (params: string) =>
    downloader(
      {
        url: `${BASE_URL}${API.INVOICES_EXPORT}${formatParams(params)}`,
        method: 'GET',
        token: token
      },
      'invoices_' + new Date().toLocaleDateString() + '.xlsx'
    ),
  getSum: async (params: string) =>
    fetcher<InvoiceSum>({
      url: `${BASE_URL}${API.INVOICE_SUM}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getInformation: async (id: number) =>
    fetcher<InvoiceInformation>({
      url: `${BASE_URL}${urlHelper(API.INVOICES_INFORMATIONS, { id })}`,
      method: 'GET',
      token: token
    }),
  getPdf: async (id: number) =>
    fetcher<InvoicePdf>({
      url: `${BASE_URL}${urlHelper(API.INVOICES_PDF, { id })}`,
      method: 'GET',
      token: token
    }),
  getActivities: async (id: number, type: string) =>
    fetcher<Activities>({
      url: `${BASE_URL}${urlHelper(API.INVOICES_ACTIVITIES, { id })}${type ? `?type=${type}` : ''}`,
      method: 'GET',
      token: token
    }),
  patchRegeneratePdf: async (id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.INVOICES_REGENERATE, { id: id })}`,
      method: 'PATCH',
      token: token
    }),
  patchAccount: async (id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.PATCH_INVOICES_ACCOUNT, { id: id })}`,
      method: 'PATCH',
      token: token
    }),
  patchUnAccount: async (id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.PATCH_INVOICES_UNACCOUNT, { id: id })}`,
      method: 'PATCH',
      token: token
    }),
  patchBlock: async (id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.PATCH_INVOICES_BLOCK, { id: id })}`,
      method: 'PATCH',
      token: token
    }),
  patchUnBlock: async (id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.PATCH_INVOICES_UNBLOCK, { id: id })}`,
      method: 'PATCH',
      token: token
    }),
  patchLitigation: async (id: number, data: { reference: string }) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.PATCH_INVOICES_LITIGATION, { id: id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  postUnCollectible: async (id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.INVOICES_UNCOLLECTIBLE, { id: id })}`,
      method: 'POST',
      token: token
    }),
  postManualReminder: async (id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.INVOICES_MANUAL_REMINDER, { id: id })}`,
      method: 'POST',
      token: token
    }),
  getRows: async (id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.INVOICES_ROWS, { id: id })}`,
      method: 'GET',
      token: token
    }),
  patchDiscount: async (id: number, data: any) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.INVOICES_DISCOUNT, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  patchLanguage: async (id: number, data: { language: number }) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.PATCH_INVOICES_LANGUAGE, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  generatePartialContractInvoice: async (id: number, contractServices: number[]) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.CONTRACT_INVOICE_PARTIAL, { id: id })}`,
      method: 'POST',
      token: token,
      body: { contractServices }
    }),
  postManualBankDebit: async (id: number, data: string) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.INVOICES_MANUAL_BANK_DEBIT, { id })}`,
      method: 'POST',
      token: token,
      body: { chargeDate: data }
    }),
  addPayment: async (id: number, data: any) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.INVOICES_ADD_PAYMENT, { id })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  linkPayment: async (id: number, payment: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.INVOICES_LINK_PAYMENT, { id, payment })}`,
      method: 'PATCH',
      token: token
    }),
  unLinkPayment: async (id: number, payment: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.INVOICES_UNLINK_PAYMENT, { id, payment })}`,
      method: 'PATCH',
      token: token
    }),
  unLinkCredit: async (id: number, credit: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.DELETE_INVOICES_UNLINK_CREDIT, { id, credit })}`,
      method: 'DELETE',
      token: token
    }),
  cancelledGc: async (id: number, payment: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.INVOICES_CANCELLED_PAYMENT, { id, payment })}`,
      method: 'PATCH',
      token: token
    }),
  getPayments: async (id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.INVOICES_PAYMENTS, { id })}`,
      method: 'GET',
      token: token
    }),
  getCredits: async (id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.INVOICES_CREDITS, { id })}`,
      method: 'GET',
      token: token
    }),
  patchDueDate: async (id: number, data: { dueDate: string }) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.PATCH_INVOICES_DUE_DATE, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    })
})
