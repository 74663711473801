import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'
import { ConsumptionService } from 'api/models'
import { formatCurrency, formatDate, formatYesNo } from 'app/utils/format'
import { useApp } from 'app/providers/app.provider'
import InfoCard from 'app/components/card/info-card.component'
import { useFetcher } from 'app/providers/fetcher.provider'

interface IMainServiceDetailsProps {
  service: ConsumptionService
  children?: React.ReactNode
}

export const ConsumptionServiceDetails = ({ service }: IMainServiceDetailsProps) => {
  const { t } = useTranslation()
  const { getLanguages } = useApp()

  const { useGetServiceTypeAccountingById } = useFetcher()
  const { data: serviceTypeAccounting } = useGetServiceTypeAccountingById(service.serviceTypeId)

  const { savedLabels, info } = useMemo(() => {
    let savedLabels = getLanguages().map((language) => ({
      label: language.label,
      value:
        service.savedLabels.find((savedLabel) => savedLabel.language.id === language.id)?.label ??
        ''
    }))

    const info = [
      {
        label: t('type'),
        value: service.serviceTypeLabel
      },
      {
        label: '',
        value: ''
      },
      {
        label: t('price'),
        value: service.price ? formatCurrency(service.price) : service.isFreePrice ? t('free') : ''
      },
      {
        label: t('fees'),
        value: service.commissioningFees
          ? formatCurrency(service.commissioningFees)
          : service.isFreeFees
          ? t('free')
          : ''
      },
      {
        label: t('begin'),
        value: service.begin ? formatDate(service.begin) : ''
      },
      {
        label: t('end'),
        value: service.end ? formatDate(service.end) : ''
      },
      {
        label: t('available_online'),
        value: t(formatYesNo(service.isOnline))
      },
      {
        label: t('accounting_number'),
        value: service.firstAccountingNumber ? (
          service.firstAccountingNumber
        ) : (
          <i>{serviceTypeAccounting?.accountingNumber}</i>
        )
      },
      {
        label: t('analytic_plan'),
        value: service.firstAnalyticPlan ? (
          service.firstAnalyticPlan
        ) : (
          <i>{serviceTypeAccounting?.analyticPlan}</i>
        )
      },
      {
        label: t('accounting_name'),
        value: service.firstAccountingName ? (
          service.firstAccountingName
        ) : (
          <i>{serviceTypeAccounting?.accountingName}</i>
        )
      }
    ]

    return { savedLabels, info }
  }, [service, t, serviceTypeAccounting])

  return (
    <>
      <InfoCard title={t('label')} columns={savedLabels} />
      <InfoCard title={t('informations')} columns={info} />
    </>
  )
}
