import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { CheckoutList, CheckoutSum } from 'api/models'

export const checkoutsRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<CheckoutList>({
      url: `${BASE_URL}${API.CHECKOUTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getSum: async (params: string) =>
    fetcher<CheckoutSum>({
      url: `${BASE_URL}${API.CHECKOUT_SUM}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
})
