import { type Ctx, fetcher, formatParams, uploader } from 'api'
import { BASE_URL, API } from 'api/constants'
import { AcquisitionCostList, CreateSourceFormData, SourcesList } from 'api/models/sources'
import { UpdateAcquisitionCostProps } from 'api/models/forms/sources'
import urlHelper from 'app/helpers/url.helper'
import { IdReturn } from 'api/models'

export const sourcesRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<SourcesList>({
      url: `${BASE_URL}${API.SOURCES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getAcquisitionCosts: async (params: string) =>
    fetcher<AcquisitionCostList>({
      url: `${BASE_URL}${API.SOURCE_ACQUISITION_COSTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  uploadCSV: async (file: any) => {
    const formData = new FormData()
    formData.append('body', file)
    return uploader<void>({
      url: `${BASE_URL}${API.SOURCE_ACQUISITION_COSTS}`,
      method: 'POST',
      token: token,
      body: formData
    })
  },
  update: async (id: number, data: UpdateAcquisitionCostProps) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.UPDATE_SOURCE_ACQUISITION_COST, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  create: async (data: CreateSourceFormData) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${API.SOURCES}`,
      method: 'POST',
      token: token,
      body: data
    })
})
