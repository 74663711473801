import { useTranslation } from 'react-i18next'
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper as MuiPaper,
  Select,
  Stack
} from '@mui/material'
import type { IndividualDetails } from 'api/models'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Box } from '@mui/system'
import { TitleComponent } from 'app/components/titles/title.component'
import { useApp } from 'app/providers/app.provider'
import { ModulesEnum } from 'app/constants/modules'
import { Add, Close, Edit } from '@mui/icons-material'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import {
  formVisorAccessData,
  FormVisorAccessDataForm,
  IndividualAccessList,
  IndividualAccess as IndividualAccessType,
  AccessGroup,
  Visors
} from 'api/models'
import { AutocompleteSelect } from 'app/components/form/autocomplete-select'
import { useFeedback } from 'app/providers/feedback.provider'

interface IIndividualEnterprises {
  individual: IndividualDetails
}

const formatAccessGroups = (items: any) => {
  return items?.map((item: any) => item.groupName).join(',')
}

export function IndividualAccess({ individual }: IIndividualEnterprises) {
  const { t } = useTranslation()
  const [items, setItems] = useState<IndividualAccessList>([])
  const [visors, setVisors] = useState<Visors>([])
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState<FormVisorAccessDataForm | null>({
    center: null,
    groups: []
  })
  const dialogRef = useRef<DialogRef>(null)
  const [visorGroups, setVisorGroups] = useState<AccessGroup[]>([])
  const {
    getIndividualAccess,
    createIndividualAccess,
    updateIndividualAccess,
    getCenterVisors,
    getCenterAccessGroups
  } = useFetcher()
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [hasVisorAccess, setHasVisorAccess] = useState(false)
  const { getModule, getRight } = useApp()
  const { handleMutation } = useFeedback()

  const refreshList = useCallback(async () => {
    setIsLoading(true)
    await handleMutation({
      mutation: getIndividualAccess,
      data: String(individual.id),
      onSuccess: (data) => setItems(data)
    })
    await handleMutation({
      mutation: getCenterVisors,
      onSuccess: (data) =>
        setVisors(data.filter((center) => !items.find((c) => c.centerId === Number(center.id))))
    })
    setIsLoading(false)
  }, [individual, getIndividualAccess, setIsLoading, setItems, items, getCenterVisors, setVisors])

  useEffect(() => {
    refreshList().then()
  }, [])

  useEffect(() => {
    const _module = getModule(ModulesEnum.ACCESS)
    if (_module !== undefined) {
      setHasVisorAccess(true)
    }
  }, [getModule])

  const handleOpenDialog = (item: IndividualAccessType) => {
    setFormData({
      center: String(item.centerId),
      groups: item.groups.map((g: any) => g.groupIndex)
    })
    setIsMenuOpen(false)
    dialogRef.current?.open()
  }

  const getEditButton = useCallback(
    (item: IndividualAccessType) => (
      <IconButton size={'small'} title={t('edit_access')} onClick={() => handleOpenDialog(item)}>
        <Edit />
      </IconButton>
    ),
    [setFormData, t, handleOpenDialog]
  )

  const map = useMemo(() => {
    const map = new Map()
    map.set('items', [
      { label: t('center'), value: 'centerName' },
      { label: t('access'), value: 'groups', format: formatAccessGroups },
      { label: '', component: getRight('visor_access', 'isEdit') ? getEditButton : undefined }
    ])
    map.set('data', items)
    return map
  }, [items, getEditButton, getRight, t])

  const handleConfirmUpdate = useCallback(async () => {
    if (!formData) return
    await handleMutation({
      confirm: {
        content: t('confirm_edit_access')
      },
      onStart: () => setIsLoading(true),
      mutation: updateIndividualAccess,
      data: {
        id: individual.id,
        data: formVisorAccessData.parse(formData)
      },
      toastSuccess: t('edit_access_success'),
      onSuccess: () => refreshList().then(),
      onEnd: () => {
        setFormData({ center: null, groups: [] })
        setIsLoading(false)
        dialogRef.current?.close()
      }
    })
  }, [updateIndividualAccess, setIsLoading, formData, setFormData, individual])

  const handleConfirmAdd = useCallback(async () => {
    if (!formData) return
    await handleMutation({
      confirm: {
        content: t('confirm_add_access')
      },
      onStart: () => setIsLoading(true),
      mutation: createIndividualAccess,
      data: {
        id: individual.id,
        data: formVisorAccessData.parse(formData)
      },
      toastSuccess: t('add_access_success'),
      onSuccess: () => refreshList().then(),
      onEnd: () => setIsLoading(false)
    })
  }, [setIsLoading, createIndividualAccess, formData, individual])

  const updateAccessGroups = (items: any) => {
    if (!formData) return
    setFormData({ ...formData, groups: items.map((i: any) => i.groupIndex) })
  }

  const fetchGroups = useCallback(async () => {
    setVisorGroups([])
    if (!formData || !formData.center) return
    const visorGroups = await getCenterAccessGroups.mutateAsync(formData.center)
    setVisorGroups(visorGroups)
  }, [setIsLoading, setVisorGroups, formData, getCenterAccessGroups])

  useEffect(() => {
    fetchGroups().then()
  }, [formData?.center])

  const updateSelectedCenter = useCallback(
    (e: any) => {
      if (!e.target.value) return setFormData({ center: null, groups: [] })
      setFormData({ center: e.target.value, groups: [] })
    },
    [setFormData]
  )

  if (!hasVisorAccess) return null

  return (
    <MuiPaper>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <TitleComponent text={t('access_list')} variant={'h3'} paddingTop={0} paddingLeft={12} />
          {getRight('visor_access', 'isAdd') && visors.length > 0 && (
            <IconButton
              size={'small'}
              color={'primary'}
              title={t('add_access')}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {isMenuOpen ? <Close /> : <Add />}
            </IconButton>
          )}
        </Stack>
      </Box>
      {isMenuOpen && (
        <Grid container columns={12} paddingBottom={4} paddingX={4} gap={4}>
          <Grid item xs={4}>
            <FormControl fullWidth size={'small'}>
              <InputLabel>{t('center')}</InputLabel>
              <Select
                label={t('center')}
                size={'small'}
                name={'center'}
                onChange={updateSelectedCenter}
              >
                {visors.map((value: any) => (
                  <MenuItem key={value.id} value={value.id}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <AutocompleteSelect
              name={'groups'}
              label={t('access')}
              valueSlug={'groupIndex'}
              labelSlug={'groupName'}
              items={visorGroups}
              value={formData?.groups ?? []}
              onChange={updateAccessGroups}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant={'contained'}
              onClick={handleConfirmAdd}
              disabled={!formData || !formData.center}
            >
              {t('save')}
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid container columns={4}>
        <Grid item xs={4}>
          <ListSheetComponent data={map} isLoading={isLoading} />
        </Grid>
      </Grid>
      <Dialog
        ref={dialogRef}
        title={t('edit_access')}
        onClose={() => setFormData({ center: null, groups: [] })}
        actions={
          <>
            <Button variant={'outlined'} onClick={() => dialogRef.current?.close()}>
              {t('cancel')}
            </Button>
            <Button variant={'contained'} onClick={handleConfirmUpdate}>
              {t('save')}
            </Button>
          </>
        }
      >
        <Box paddingTop={4}>
          <AutocompleteSelect
            name={'groups'}
            label={t('access')}
            valueSlug={'groupIndex'}
            labelSlug={'groupName'}
            items={visorGroups}
            value={formData?.groups ?? []}
            onChange={updateAccessGroups}
          />
        </Box>
      </Dialog>
    </MuiPaper>
  )
}
