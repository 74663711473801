import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/system'
import { Box, Grid, Typography } from '@mui/material'
import { KeyboardBackspace } from '@mui/icons-material'
import { InformationsComponent } from 'modules/invoices/components/informations.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import { FormItem, FormItemsInput, InvoiceInformation } from 'api/models'
import { RectangularSkeleton } from 'app/components/skeletons/rectangular.skeleton'
import { Link } from 'app/components/link.component'
import base64ConverterHelper from 'app/helpers/base64_converter.helper'
import { ActionsComponent } from 'modules/invoices/components/actions.component'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { useApp } from 'app/providers/app.provider'
import { PDFViewer } from 'app/components/pdf/PDFViewer'
import FolderOffIcon from '@mui/icons-material/FolderOff'

export const InvoiceView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getRight } = useApp()
  const { getInvoice, getFormItems, getInvoicePdf, searchParams } = useFetcher()
  const { id } = useParams()
  const [information, setInformation] = useState<InvoiceInformation>()
  const [languages, setLanguages] = useState<FormItem>({} as FormItem)
  const [paymentMode, setPaymentMode] = useState<FormItem>({} as FormItem)
  const [activityTypes, setActivityTypes] = useState<any>()
  const [pdf, setPdf] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [expanded, setExpanded] = useState<string | false>('panel1')
  const [refresh, setRefresh] = useState<boolean>(false)
  const [havePdf, setHavePdf] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        const hasPayment = getRight('payment', 'isAdd')
        const hasPaymentTpe = getRight('payment_tpe', 'isAdd')
        const hasTransfer = getRight('transfer', 'isAdd')

        let resources: FormItemsInput = ['languages_to_pdf']
        if (hasPayment && hasTransfer) {
          resources = ['languages_to_pdf', 'credit_payment_mode']
        } else if (hasPayment && !hasTransfer) {
          resources = ['languages_to_pdf', 'credit_payment_mode_without_transfer']
        } else if (hasPaymentTpe) {
          resources = ['languages_to_pdf', 'credit_payment_mode_tpe']
        }
        const results = await Promise.all([
          getFormItems.mutateAsync(resources),
          getInvoice.mutateAsync(Number(id)),
          (async () => {
            try {
              const pdfResult = await getInvoicePdf.mutateAsync(Number(id))
              setHavePdf(true)
              return pdfResult
            } catch (error) {
              setHavePdf(false)
              console.error('Error fetching invoice PDF:', error)
              return null
            }
          })()
        ])
        setLanguages(results[0].languages_to_pdf as FormItem)

        if (hasPayment || hasPaymentTpe) {
          setPaymentMode(results[0].credit_payment_mode as FormItem)
        }
        setActivityTypes(results[1].activities)
        setInformation(results[1])
        if (results[2] && results[2].document_pdf) {
          const blob = base64ConverterHelper().base64ToBlob(
            results[2].document_pdf,
            'application/pdf'
          )
          setPdf(URL.createObjectURL(blob))
        }
      } catch (err) {
      } finally {
        setIsLoading(false)
      }
    })()
  }, [id, refresh, searchParams])

  return (
    <Container>
      <Box marginBottom="2rem">
        {information && (
          <Typography variant="h2" gutterBottom display="inline">
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Link to={`/invoices`}>
                <KeyboardBackspace style={{ marginRight: 5 }} />
              </Link>{' '}
              <Typography variant="h2" style={{ marginRight: 5 }}>
                {t('invoice')}
              </Typography>
              <Typography variant="h3">{information.invoice.reference}</Typography>
            </Box>
          </Typography>
        )}
        {!information && <RectangularSkeleton />}
      </Box>
      <Grid container spacing={8}>
        <Grid item xs={12} md={5}>
          <InformationsComponent
            setRefresh={setRefresh}
            data={information}
            isLoading={isLoading}
            dataFilters={languages}
          />
          {isLoading ? (
            <CardSkeleton />
          ) : (
            <ActionsComponent
              setRefresh={setRefresh}
              translation={t}
              data={information as InvoiceInformation}
              expanded={expanded}
              setExpanded={setExpanded}
              activityTypes={activityTypes}
              paymentMode={paymentMode}
            />
          )}
        </Grid>
        <Grid item xs={12} md={7}>
          {isLoading && <CardSkeleton />}
          {!isLoading && havePdf && pdf && <PDFViewer pdf={pdf} />}
          {!isLoading && !havePdf && (
            <Grid container style={{ height: '100%' }} justifyContent="center">
              <Box display={'flex'} alignItems="center">
                <FolderOffIcon style={{ fontSize: 40, marginRight: 20 }} />
                <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                  {t('no_pdf')}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}
