import React, { useMemo } from 'react'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useTranslation } from 'react-i18next'
import { formatPercentage } from 'app/utils/format'
import dayjs from 'dayjs'

export const TableRateOccupation = () => {
  const { t } = useTranslation()
  const { useGetStatsAllOccupations } = useFetcher()
  const { data: occupations, isLoading } = useGetStatsAllOccupations()

  const occupationsMap = useMemo(() => {
    const periods = new Set<string>()
    const data = new Map<number, { [key: string]: number | string }>()

    for (const occ of occupations ?? []) {
      periods.add(occ.period)

      for (const item of occ.items) {
        if (item.centerId === 0) continue

        if (!data.has(item.centerId)) {
          data.set(item.centerId, { centerName: item.centerName })
        }
        const oldCenter = data.get(item.centerId)
        data.set(item.centerId, { ...oldCenter, [occ.period]: item.occupiedRate })
      }
    }

    const columns = [
      { label: t('center'), value: 'centerName' },
      ...Array.from(periods).map((period) => ({
        label: dayjs(period).format('MMM YY'),
        value: period,
        format: (value: number) => formatPercentage(value / 100)
      }))
    ]

    const map = new Map()
    map.set('items', columns)
    map.set('data', Array.from(data.values()))

    return map
  }, [occupations, t])

  return <ListSheetComponent isLoading={isLoading} data={occupationsMap} />
}
