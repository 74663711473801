import React, { useCallback, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate } from 'react-router-dom'
import { createHappeningPartnerFormSchema, CreateHappeningPartnerForm } from 'api/models'
import { Button, Grid, Typography, Box, Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { FormCard } from 'app/components/form/form-card.component'
import { useFeedback } from 'app/providers/feedback.provider'
import { zodResolver } from '@hookform/resolvers/zod'

export const PartnerAddView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { createPartners } = useFetcher()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { handleMutation } = useFeedback()

  const methods = useForm<CreateHappeningPartnerForm>({
    resolver: zodResolver(createHappeningPartnerFormSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      website: '',
      logo: ''
    }
  })

  const handleSubmit = useCallback(
    async (data: CreateHappeningPartnerForm) => {
      await handleMutation({
        confirm: {
          content: t('confirm_create_partner')
        },
        onStart: () => setIsLoading(true),
        data: data,
        mutation: createPartners,
        onSuccess: () => navigate(`/partner`),
        toastSuccess: t('create_partner_success'),
        toastError: t('create_partner_error'),
        onEnd: () => setIsLoading(false)
      })
    },
    [handleMutation, createPartners, t]
  )

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('add_partner')}
        </Typography>
      </Box>
      <Grid
        container
        rowSpacing={8}
        columnSpacing={{ xs: 2, sm: 4, md: 8 }}
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Grid item xs={12} md={12}>
          <FormCard
            isLoading={isLoading}
            title={t('general_informations')}
            control={methods.control}
            items={[
              { type: 'textfield', label: t('name'), name: 'name', required: true },
              { type: 'textfield', label: t('website'), name: 'website' },
              { type: 'textfield', label: t('logo'), name: 'logo' }
            ]}
          />
        </Grid>
      </Grid>
      <Grid sx={{ marginTop: 4, margin: 2 }} container columns={12} columnSpacing={4}>
        <Grid item xs={12} textAlign={'center'}>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => navigate('/partner')}
            sx={{ marginRight: 2 }}
          >
            {t('cancel')}
          </Button>
          <Button
            disabled={methods.formState.isSubmitting || !methods.formState.isValid}
            variant={'contained'}
            color={'primary'}
            onClick={methods.control.handleSubmit(handleSubmit)}
          >
            {t('save')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
