import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useTranslation } from 'react-i18next'
import {
  AddCircle,
  Boy,
  CalendarMonth,
  Delete,
  Euro,
  EventBusy,
  Handshake,
  Help,
  Layers,
  Percent
} from '@mui/icons-material'
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { formatCurrency, formatPercentage, formatSurface } from 'app/utils/format'
import dayjs, { Dayjs } from 'dayjs'
import { useApp } from 'app/providers/app.provider'
import urlHelper from 'app/helpers/url.helper'
import { API } from 'api/constants'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Contract, EndorsementContract, FormItem } from 'api/models'
import { TitleComponent } from 'app/components/titles/title.component'
import { EndorsementUpdateForm, endorsementUpdateSchema } from 'api/models/forms/endorsement'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { DialogAddEndorsementService } from 'modules/contracts/components/endorsement/dialog-add-endorsement-service'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { useFeedback } from 'app/providers/feedback.provider'
import { Timeline } from 'modules/contracts/components/timeline.component'
import { SmallChip } from 'modules/contracts/components/endorsement/small-chip.component'
import { calculateServiceFinalPriceTotal, calculateServiceGuarantee } from 'app/utils/calculate'

const formatReadInput = (
  title: string,
  value: string,
  startIcon?: ReactNode,
  endIcon?: ReactNode
) => {
  return (
    <Tooltip title={title} placement="top">
      <TextField
        size={'small'}
        sx={{ width: '120px' }}
        type="number"
        defaultValue={value}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          startAdornment: startIcon && (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ),
          endAdornment: endIcon && <InputAdornment position="end">{endIcon}</InputAdornment>,
          inputProps: {
            min: 0
          },
          readOnly: true
        }}
      />
    </Tooltip>
  )
}

export const EndorsementsComponent = ({
  contract,
  isLitigation
}: {
  contract: Contract
  isLitigation: boolean
}) => {
  const { t } = useTranslation()
  const [servicesMap, setServicesMap] = useState<Map<string, any>>(new Map())
  const [optionsMap, setOptionsMap] = useState<Map<string, any>>(new Map())
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const { user } = useApp()
  const [endorsement, setEndorsement] = useState<EndorsementContract | null>(null)
  const [endorsementContract, setEndorsementContract] = useState<Contract | null>(null)
  const {
    getFormItems,
    updateContractEndorsement,
    sendEndorsementToDirection,
    getContractEndorsements,
    getServiceCommitmentPrice,
    getContract,
    deleteContractService,
    validateEndorsement,
    getSelectCommitments
  } = useFetcher()
  const [services, setServices] = useState<any>([])
  const [dates, setDates] = useState<{ min: Dayjs | null; max: Dayjs | null }>({
    min: null,
    max: null
  })
  const isActive = useRef<boolean>(false)
  const [endorsementReasons, setEndorsementReason] = useState<FormItem>()
  const dialogRef = useRef<DialogRef>(null)
  const [formData, setFormData] = useState<EndorsementUpdateForm>({} as EndorsementUpdateForm)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [commitmentData, setCommitmentData] = useState<any>({})
  const [timelineItems, setTimelineItems] = useState<any>({})

  const { handleMutation } = useFeedback()

  const formatOccupants = (row: any, isEdit: boolean) => {
    return (
      <Tooltip title={row.occupantsLabel} placement="top">
        <TextField
          size={'small'}
          type="number"
          sx={{ width: '120px' }}
          value={row.workforce}
          onChange={(e) => handleOccupantChange(e, row)}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            sx: { paddingLeft: 2 },
            startAdornment: (
              <InputAdornment position="start">
                <Boy />
              </InputAdornment>
            ),
            inputProps: {
              min: 0
            },
            readOnly: !isEdit
          }}
        />
      </Tooltip>
    )
  }

  const handleOccupantChange = useCallback(
    (e: any, row: any) => {
      setFormData(({ services: prev, ...otherProps }: any) => {
        const newData = prev?.map((item: any) =>
          item.id === row.id ? { ...item, workforce: e.target.value } : item
        )
        return { ...otherProps, services: newData }
      })
    },
    [setFormData]
  )

  const handleDeleteItem = useCallback(
    async (row: any) => {
      if (!endorsement) return
      await handleMutation({
        confirm: {
          content: t('confirm_delete_service')
        },
        mutation: deleteContractService,
        data: {
          contractId: endorsement.id,
          serviceId: row.id
        },
        onSuccess: () => fetchEndorsement(),
        toastSuccess: t('item_removed_successfully'),
        toastError: t('error')
      })
    },
    [endorsement]
  )

  const updateFormData = useCallback(
    (data: any) => {
      setFormData((prevData) => ({ ...prevData, ...data }))
    },
    [formData, setFormData]
  )

  useEffect(() => {
    if (!endorsement) return
    const services = endorsement.mainContractServices.map((mainContractService) => ({
      ...mainContractService,
      sellPrice: mainContractService.previousPrice,
      finalPrice: mainContractService.price,
      priceDifference: -1 * mainContractService.reduction,
      id: mainContractService.id,
      workforce: mainContractService.occupants,
      serviceId: mainContractService.serviceId
    }))

    const newTotal = calculateServiceFinalPriceTotal(services)
    const newGuarantee = calculateServiceGuarantee(services)
    const priceRate =
      newTotal > 0 ? (1 - (endorsement?.contractMainPrice ?? 0) / newTotal) * 100 : 100

    setFormData({
      begin: dayjs(endorsement.begin),
      commitment: endorsement.commitmentId,
      commitment_difference: endorsement.commitmentDifference,
      deadline: dayjs(endorsement.deadline),
      endorsement_price: newTotal,
      endorsement_state: endorsement.statusId,
      endorsement_reason: endorsement.endorsementLinkReason,
      grid_type: endorsement.gridType,
      price_difference: newTotal - endorsement.contractMainPrice,
      price_difference_rate: priceRate,
      endorsement_guarantees: newGuarantee,
      guarantees_difference: newGuarantee * (priceRate / 100),
      workforce_difference: endorsement.workforceDifference,
      serviceId: [],
      services: services,
      options: endorsement.optionContractServices.map((optionService) => ({
        id: optionService.id,
        workforce: optionService.quantity,
        priceDifference: optionService.reduction,
        finalPrice: optionService.price * optionService.reduction,
        sellPrice: optionService.price,
        serviceId: optionService.serviceId
      }))
    })
  }, [endorsement])

  useEffect(() => {
    setServicesMap(() => {
      const map = new Map()
      map.set('items', [
        { value: 'label' },
        { value: 'serviceTypeLabel' },
        {
          value: 'typology',
          format: (value: string) => (value ? <SmallChip label={value} color="primary" /> : null)
        },
        { value: 'surface', format: formatSurface },
        {
          component: (row: any) => formatOccupants(row, isEdit)
        },
        { label: t('price'), component: (row: any) => formatCurrency(row.sellPrice) },
        {
          label: t('price_diff'),
          component: (row: any) => formatPercentage(row.priceDifference / 100)
        },
        {
          label: t('final_price'),
          component: (row: any) => formatFinalPrice(row, isEdit)
        },
        {
          component: (row: any) =>
            isEdit && <Delete fontSize="small" onClick={() => handleDeleteItem(row)} />
        }
      ])
      map.set('data', formData.services ?? [])
      return map
    })

    setOptionsMap(() => {
      const map = new Map()
      if (endorsement?.optionContractServices && endorsement?.optionContractServices.length > 0) {
        map.set('items', [
          { value: 'label' },
          { value: 'serviceTypeLabel' },
          {
            value: 'typology',
            format: (value: string) => (value ? <SmallChip label={value} color="primary" /> : null)
          },
          { component: (row: any) => formatReadInput(row.quantityLabel, row.quantity, <Layers />) },
          {
            component: (row: any) =>
              formatReadInput(
                row.reductionLabel,
                row.reduction,
                null,
                <Percent fontSize={'small'} />
              )
          },
          { value: 'price', format: formatCurrency }
        ])
        map.set('data', endorsement?.optionContractServices ?? [])
      }
      return map
    })
  }, [isEdit, formData, services])

  useEffect(() => {
    if (!endorsement) return
    setServices(endorsement.mainContractServices)
  }, [formData])

  const fetchEndorsement = useCallback(async () => {
    if (!contract) return
    setIsLoading(true)
    const endorsement = await getContractEndorsements.mutateAsync(contract.id)
    if (endorsement) {
      const endorsementContract = await getContract.mutateAsync(endorsement.id)
      setEndorsementContract(endorsementContract)
      setTimelineItems({
        created_at: {
          date: endorsementContract.createdAt,
          completed: !!endorsementContract.createdAt
        },
        direction_agreement: {
          date: endorsementContract.directionAgreement,
          completed: !!endorsementContract.directionAgreement
        },
        agreement_at: {
          date: endorsementContract.agreement,
          completed: !!endorsementContract.agreement
        },
        paid_at: { date: endorsementContract.confirm, completed: !!endorsementContract.confirm },
        state: { completed: !!endorsementContract.confirm && endorsementContract.state === 1 }
      })
      const select = await getSelectCommitments.mutateAsync(endorsement.id)
      setCommitmentData(select)
    }
    setEndorsement(endorsement)
    setIsLoading(false)
  }, [setIsLoading, contract])

  const fetchOptions = useCallback(async () => {
    const option = await getFormItems.mutateAsync(['endorsement_reason'])
    setEndorsementReason(option?.endorsement_reason)
  }, [])

  const handleModify = useCallback(() => {
    setIsEdit(true)
  }, [setIsEdit])

  const handleFinalPrice = useCallback(
    (e: any, row: any) => {
      if (!endorsement) return
      setFormData(({ services: prev, ...otherProps }: any) => {
        const newData = prev?.map((item: any) =>
          item.id === row.id
            ? {
                ...item,
                finalPrice: parseFloat(e.target.value),
                priceDifference: (parseFloat(e.target.value) / item.sellPrice - 1) * 100
              }
            : item
        )

        const newTotal = calculateServiceFinalPriceTotal(newData)
        const newGuarantee = calculateServiceGuarantee(newData)
        const priceRate =
          newTotal > 0 ? (1 - (endorsement?.contractMainPrice ?? 0) / newTotal) * 100 : 100
        return {
          ...otherProps,
          services: newData,
          endorsement_price: newTotal,
          price_difference: newTotal - endorsement.contractMainPrice,
          price_difference_rate: priceRate,
          endorsement_guarantees: newGuarantee,
          guarantees_difference: newGuarantee * (priceRate / 100)
        }
      })
    },
    [setFormData, endorsement]
  )

  const formatFinalPrice = useCallback(
    (row: any, isEdit: boolean) => {
      return (
        <TextField
          size={'small'}
          type="number"
          sx={{ width: '160px' }}
          defaultValue={row.finalPrice}
          onChange={(e: any) => handleFinalPrice(e, row)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Euro fontSize={'small'} />
              </InputAdornment>
            ),
            inputProps: {
              min: 0,
              step: 0.01
            },
            readOnly: !isEdit
          }}
        />
      )
    },
    [handleFinalPrice]
  )

  const changeDeadline = useCallback(
    (begin: Dayjs, min?: string, max?: string): void => {
      let day = begin.date()
      let dateMin = begin.clone()

      if (day === 1) {
        dateMin = dateMin.subtract(1, 'month')
      }

      if (min) {
        dateMin = dateMin.add(Number(min), 'month')
      }

      let dateMax = dateMin.clone()

      if (max) {
        dateMax = dateMax.add(Number(max), 'month')
      }

      setDates(() => ({ min: dateMin, max: dateMax }))
    },
    [setDates]
  )

  const handleCommitmentDiff = useCallback(
    (endDate: any) => {
      if (!endorsement || !endorsementContract) return
      let originalEndDate = dayjs(contract.deadline).startOf('month')
      let commitmentDiff = endDate.startOf('month').diff(originalEndDate, 'month')
      updateFormData({ commitment_difference: commitmentDiff })
    },
    [endorsement, endorsementContract, updateFormData]
  )

  const getPrice = useCallback(async () => {
    let services = formData.services

    const prices = await Promise.all(
      services.map((service) =>
        getServiceCommitmentPrice.mutateAsync({
          id: String(service.serviceId),
          commitment: Number(formData.commitment)
        })
      )
    )

    prices.forEach((data, index) => {
      services[index].sellPrice = data.price
      services[index].priceDifference =
        (services[index].finalPrice / services[index].sellPrice - 1) * 100
    })

    updateFormData({ services: services })
  }, [formData, updateFormData])

  const handleBegin = useCallback((newBeginDate: any): void => {
    isActive.current = true
    updateFormData({ begin: newBeginDate })
  }, [])

  const handleCommitment = useCallback(
    (e: any): void => {
      let id = e.target.value
      updateFormData({ commitment: id })
    },
    [updateFormData]
  )

  useEffect(() => {
    handleCommitmentDiff(formData.deadline)
  }, [formData.deadline])

  useEffect(() => {
    if (!formData.commitment) return
    if (isEdit) {
      getPrice().then()
    }
  }, [formData.commitment])

  useEffect(() => {
    const commitment = commitmentData[formData.commitment]
    if (!commitment) return
    if (
      formData.commitment_difference < parseInt(commitment.min) ||
      formData.commitment_difference > parseInt(commitment.max)
    ) {
      updateFormData({ deadline: dates.min })
    }
  }, [dates.min])

  useEffect(() => {
    const commitment = commitmentData[formData.commitment]
    if (!commitment) return
    changeDeadline(formData.begin, String(commitment.min), String(commitment.max))
  }, [formData.begin, formData.commitment])

  useEffect(() => {
    fetchOptions().then()
    fetchEndorsement().then()
  }, [contract])

  const handleSubmit = async () => {
    await handleMutation({
      confirm: {
        content: t('confirm_update_endorsement')
      },
      mutation: updateContractEndorsement,
      data: {
        id: contract.id,
        data: endorsementUpdateSchema.parse(formData)
      },
      onSuccess: () => fetchEndorsement(),
      toastSuccess: t('endorsement_update_success'),
      toastError: t('endorsement_update_error')
    })
  }

  const handleSubmitToDirection = useCallback(async () => {
    if (!endorsement) return
    await handleMutation({
      confirm: {
        content: t('confirm_send_to_direction')
      },
      mutation: sendEndorsementToDirection,
      data: String(endorsement.id),
      onSuccess: () => fetchEndorsement(),
      toastSuccess: t('endorsement_send_to_direction_success'),
      toastError: t('endorsement_update_error')
    })
  }, [endorsement])

  const handleValidate = useCallback(async () => {
    if (!endorsement) return
    await handleMutation({
      mutation: validateEndorsement,
      data: String(endorsement.id),
      onSuccess: () => fetchEndorsement(),
      toastSuccess: t('endorsement_validated_success'),
      toastError: t('endorsement_update_error')
    })
  }, [endorsement])

  if (isLoading) return <CardSkeleton />

  return (
    <>
      <DialogAddEndorsementService
        dialogRef={dialogRef}
        endorsement={endorsement}
        contract={contract}
      />
      {endorsementContract && <Timeline items={timelineItems} />}
      <Card>
        <CardContent>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginBottom: 8 }}
          >
            <Grid item>
              <TitleComponent
                text={`${t('endorsement')} ${endorsementContract?.reference}`}
                variant={'h3'}
                paddingTop={0}
                paddingLeft={12}
              />
            </Grid>
            <Grid container item xs={5} alignItems="center" justifyContent={'flex-end'}>
              <Grid item xs={4}>
                <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
                  {t('endorsement_reason')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth size={'small'}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formData?.endorsement_reason ?? ''}
                    disabled={!isEdit || endorsement?.endorsementLinkReason === 1}
                    onChange={(e) => updateFormData({ endorsement_reason: Number(e.target.value) })}
                  >
                    {endorsementReasons?.values.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container columns={12} spacing={7} alignItems="center" sx={{ marginBottom: 8 }}>
            <Grid item xs={4}>
              <DatePicker
                label={t('begin_date')}
                readOnly={!isEdit}
                value={formData?.begin ?? dayjs()}
                onChange={handleBegin}
                slotProps={{
                  textField: {
                    size: 'small',
                    disabled: !isEdit,
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarMonth />
                        </InputAdornment>
                      )
                    },
                    fullWidth: true
                  }
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                disabled={!isEdit}
                size={'small'}
                select
                label={t('commitment')}
                value={String(formData.commitment)}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Handshake />
                    </InputAdornment>
                  )
                }}
                fullWidth
                onChange={handleCommitment}
              >
                {Object.keys(commitmentData)?.map((key) => {
                  const option = commitmentData[key]
                  return (
                    <MenuItem key={key} value={key}>
                      {option.label}
                    </MenuItem>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                readOnly={!isEdit}
                label={`${t('end')} (${formData.commitment_difference >= 0 ? '+' : ''}${
                  formData.commitment_difference
                } ${t('month')})`}
                views={['month', 'year']}
                minDate={dates.min}
                maxDate={dates.max}
                value={formData?.deadline ?? dayjs()}
                onChange={(value) => updateFormData({ deadline: value })}
                slotProps={{
                  textField: {
                    size: 'small',
                    disabled: !isEdit,
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <EventBusy />
                        </InputAdornment>
                      )
                    },
                    fullWidth: true
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container columns={12} spacing={7} sx={{ mb: 5 }}>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={formData.next_indexation_transfer ?? false}
                    disabled={!isEdit}
                    size="small"
                    onChange={(e) =>
                      updateFormData({
                        next_indexation_transfer: e.target.checked ? true : undefined
                      })
                    }
                  />
                }
                label={t('endorsement_nextIndexation_transfer')}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography>{t('discountgrid_label')} :</Typography>
              <Typography>
                <Link
                  href={urlHelper(API.DISCOUNT_GRID, {
                    id: endorsement?.discountGridId
                  })}
                  target="_blank"
                >
                  {endorsement?.discountGridLabel}
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {t('center_price', { price: formatCurrency(endorsement?.centerPrice ?? 0) })}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginBottom: 5 }}
          >
            <Grid item>
              <Stack direction={'row'} alignItems="flex-end" gap={1}>
                <Typography variant="h4">
                  {t('price')} : {formatCurrency(formData.endorsement_price)}/{t('month')}
                </Typography>
                <Typography variant="h5" color={'error'}>
                  ({formData.price_difference > 0 ? '+' : ''}
                  {formatCurrency(formData.price_difference)})
                </Typography>
                <Typography variant="h5" color={'error'}>
                  ({Number(formData.price_difference_rate) > 0 ? '+' : ''}
                  {formatPercentage(Number(formData.price_difference_rate) / 100)})
                </Typography>
              </Stack>
            </Grid>
            <Grid item>
              <Stack direction={'row'} alignItems="flex-end" gap={1}>
                <Typography variant="h4">
                  DG : {formatCurrency(formData.endorsement_guarantees)}
                </Typography>
                <Typography variant="h5" color={'error'}>
                  ({formData.guarantees_difference > 0 ? '+' : ''}{' '}
                  {formatCurrency(formData.guarantees_difference)})
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Divider color={'black'} sx={{ marginBottom: 7 }} />
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Typography variant="h4" sx={{ textTransform: 'uppercase' }}>
              {t('main_services')}
            </Typography>
            {isEdit && (
              <AddCircle
                fontSize={'small'}
                color="primary"
                onClick={() => dialogRef.current?.open()}
              />
            )}
          </Box>
          <ListSheetComponent isLoading={isLoading} data={servicesMap} />
          {optionsMap.size > 0 && (
            <>
              <Box display={'flex'} alignItems={'center'} gap={2} marginTop={3}>
                <Typography variant="h4" sx={{ textTransform: 'uppercase' }}>
                  {t('options')}
                </Typography>
                <Tooltip title={t('endorsement_options')} placement="top">
                  <Help fontSize={'small'} color="primary" />
                </Tooltip>
              </Box>
              <ListSheetComponent isLoading={isLoading} data={optionsMap} />
            </>
          )}
          <Stack spacing={3} sx={{ marginTop: 5 }} direction="row" justifyContent="center">
            {!isLitigation &&
              (isEdit ? (
                <Button size="small" variant="contained" onClick={handleSubmit}>
                  {t('submit')}
                </Button>
              ) : (
                <>
                  <Button size="small" variant="outlined" onClick={handleModify}>
                    {t('modify')}
                  </Button>
                  {endorsement?.statusId === 6 &&
                  user &&
                  user.rights.endorsement_validation.isEdit ? (
                    <Button size="small" variant="contained" onClick={handleValidate}>
                      {t('endorsement_validate')}
                    </Button>
                  ) : (
                    endorsementContract?.directionAgreement === null && (
                      <Button size="small" variant="contained" onClick={handleSubmitToDirection}>
                        {t('endorsement_send_direction')}
                      </Button>
                    )
                  )}
                </>
              ))}
          </Stack>
        </CardContent>
      </Card>
    </>
  )
}
