import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { WifiClientLogsList, WifiLogsList } from 'api/models/wifi'

export const wifiRouter = ({ token }: Ctx) => ({
  getLogs: async (params: string) =>
    fetcher<WifiLogsList>({
      url: `${BASE_URL}${API.WIFI_LOGS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getClientsLogs: async (params: string) =>
    fetcher<WifiClientLogsList>({
      url: `${BASE_URL}${API.WIFI_CLIENTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
})
