import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { AuthentificationRadiusList, MessageList } from 'api/models'

export const monitoringRouter = ({ token }: Ctx) => ({
  messages: async (params: string) =>
    fetcher<MessageList>({
      url: `${BASE_URL}${API.MESSAGES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  authentificationRadius: async (params: string) =>
    fetcher<AuthentificationRadiusList>({
      url: `${BASE_URL}${API.AUTHENTIFICATION_RADIUS}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
})
