import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  PrescriberInformation,
  PrescriberList,
  PrescriberMembers,
  PrescriberOpportunities
} from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const prescribersRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<PrescriberList>({
      url: `${BASE_URL}${API.PRESCRIBERS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getEnterprisePrescriber: async (prescriberId: number, id: number) =>
    fetcher<PrescriberInformation>({
      url: `${BASE_URL}${urlHelper(API.ENTREPRISE_PRESCRIBER, { id, prescriberId })}`,
      method: 'GET',
      token: token
    }),
  listMembers: async (prescriberId: number, id: number) =>
    fetcher<PrescriberMembers>({
      url: `${BASE_URL}${urlHelper(API.ENTREPRISE_PRESCRIBER, { id, prescriberId })}/members`,
      method: 'GET',
      token: token
    }),
  listOpportunities: async (prescriberId: number, id: number) =>
    fetcher<PrescriberOpportunities>({
      url: `${BASE_URL}${urlHelper(API.ENTREPRISE_PRESCRIBER, {
        id,
        prescriberId
      })}/opportunities`,
      method: 'GET',
      token: token
    }),
  createMemberSource: async (prescriberId: number, id: number, individual: number) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.ENTREPRISE_PRESCRIBER, {
        id,
        prescriberId
      })}/members/add_source`,
      method: 'POST',
      token: token,
      body: {
        individual: individual
      }
    })
})
