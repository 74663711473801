import { type Ctx, fetcher } from 'api'
import { BASE_URL, API } from 'api/constants'
import { EventFormData, EventsList } from 'api/models/events'
import { IdReturn } from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const eventsRouter = ({ token }: Ctx) => ({
  create: async (params: EventFormData) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${API.EVENTS}`,
      method: 'POST',
      token: token,
      body: params
    }),
  edit: async (id: number, data: EventFormData) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_EVENT, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  list: async (params: string) =>
    fetcher<EventsList>({
      url: `${BASE_URL}${API.EVENTS}?${params}`,
      method: 'GET',
      token: token
    })
})
