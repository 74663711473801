import React, { forwardRef } from 'react'
import styled from '@emotion/styled'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { rgba, darken } from 'polished'
import { Chip, Collapse, ListItemProps, ListItemButton, ListItemText } from '@mui/material'
import { MenuItemsTypes } from 'app/constants/navbar'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

type ItemType = {
  activeclassname?: string
  onClick?: () => void
  to?: string
  component?: typeof NavLink
  depth: number
  title: string
}

const Item = styled(ListItemButton)<ItemType>`
  padding-top: ${(props) => props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-bottom: ${(props) => props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-left: ${(props) => {
    let spacingValue

    if (props.depth && props.depth === 1) {
      spacingValue = props.theme.spacing(11)
    } else if (props.depth && props.depth === 2) {
      spacingValue = props.theme.spacing(14)
    } else {
      spacingValue = props.theme.spacing(4)
    }

    return spacingValue
  }};
  padding-right: ${(props) => props.theme.spacing(props.depth && props.depth > 0 ? 4 : 4)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  border-radius: ${(props) => props.theme.sidebar.borderRadius};
  margin-bottom: 4px;

  &.modified {
    span {
      color: ${(props) => props.theme.sidebar.linkOpen.color};
      font-weight: ${(props) => props.theme.typography.fontWeightBold};
    }

    svg {
      height: 15px;
      width: 15px;
      color: ${(props) => props.theme.sidebar.linkOpen.color};
    }
  }

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: ${(props) => (props.depth && props.depth > 0 ? 4 : 20)}px;
    height: ${(props) => (props.depth && props.depth > 0 ? 4 : 20)}px;
    opacity: 0.5;
  }

  &:hover {
    background: ${(props) => getBackground(props)};
    span {
      color: ${(props) => props.theme.sidebar.linkOpen.color};
    }

    svg {
      color: ${(props) => props.theme.sidebar.linkOpen.color};
      opacity: 1;
    }
  }

  &.${(props) => props.activeclassname} {
    background: ${(props) =>
      props.depth && props.depth > 0
        ? 'rgba(0,0,0,0)'
        : darken(0.03, props.theme.sidebar.linkActive.background)};

    span {
      color: ${(props) => props.theme.sidebar.linkOpen.color};
      font-weight: ${(props) => props.theme.typography.fontWeightBold};
      font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    }

    svg {
      color: ${(props) => props.theme.sidebar.linkOpen.color};
      opacity: 1;
    }
  }

  &.open {
    background: ${(props) => getBackground(props)};

    svg {
      color: ${(props) => props.theme.sidebar.linkOpen.color};
      opacity: 1;
    }

    span {
      font-weight: ${(props) => props.theme.typography.fontWeightBold};
      color: ${(props) => props.theme.sidebar.linkOpen.color};
      opacity: 1;
    }
  }
`

const getBackground = (props: any) => {
  const hasChildren = props.children && React.Children.count(props.children) > 2
  const isDepthValid = props.depth && props.depth > 0

  if (isDepthValid && hasChildren) {
    return darken(0.03, props.theme.sidebar.linkActive.background)
  }
  return isDepthValid ? 'rgba(0,0,0,0)' : darken(0.03, props.theme.sidebar.linkActive.background)
}
//
const StyledCollapse = styled(Collapse)`
  position: relative;

  .MuiCollapse-wrapper::after {
    content: '';
    position: absolute;
    left: 25px;
    top: 0;
    height: 100%;
    width: 1px;
    opacity: 1;
    background: ${(props) => props.theme.sidebar.linkActive.background};
  }
`

type TitleType = {
  depth: number
}

const Title = styled(ListItemText)<TitleType>`
  margin: 0;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, props.depth && props.depth > 0 ? 0.7 : 1)};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(2)};
  }
`

const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;

  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`

const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
  width: 20px !important;
  height: 20px !important;
`

const ExpandMoreIcon = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
  width: 20px !important;
  height: 20px !important;
`

const CustomRouterLink = forwardRef<any, NavLinkProps>((props, ref) => (
  <div ref={ref}>
    <NavLink {...props} />
  </div>
))

type SidebarNavListItemProps = ListItemProps & {
  className?: string
  depth: number
  href: string
  icon: React.FC<any>
  pages?: MenuItemsTypes[]
  title: string
  badge?: string
  open?: boolean
  openSubMenu: { title: string; depth: number }[]
  index: number
  handleToggle: (title: string, depth: number) => void
}

export function SidebarNavListItem(props: SidebarNavListItemProps) {
  const { t } = useTranslation()
  const {
    title,
    depth = 0,
    icon: Icon,
    href,
    pages,
    children,
    badge,
    handleToggle,
    openSubMenu
  } = props

  const isSubMenuOpen = openSubMenu?.some(
    (entry) => entry.title === props.title && entry.depth === depth
  )
  if (pages) {
    return (
      <React.Fragment>
        <Item
          depth={depth}
          onClick={() => handleToggle(title, depth)}
          title={t(title)}
          className={`${isSubMenuOpen ? 'open' : ''} ${
            props.pages && props.depth > 0 && props.pages.length > 0 ? 'modified' : ''
          }`}
        >
          {Icon && <Icon />}
          <Title depth={depth}>
            {t(props.title)}
            {badge && <Badge label={badge} />}
          </Title>
          {isSubMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Item>
        <StyledCollapse in={isSubMenuOpen}>{children}</StyledCollapse>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Item
        depth={depth}
        component={CustomRouterLink}
        to={href}
        activeclassname="active"
        title={t(props.title)}
      >
        {Icon && <Icon />}
        <Title depth={depth}>
          {t(props.title)}
          {badge && <Badge label={badge} />}
        </Title>
      </Item>
    </React.Fragment>
  )
}
