import { type Ctx, fetcher, fetcherParse, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  CreateUpdateStaffForm,
  staffDefaultSchema,
  StaffDetails,
  StaffsList
} from 'api/models/staffs'
import { IdReturn } from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const staffsRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<StaffsList>({
      url: `${BASE_URL}${API.STAFFS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getOne: async (id: number) =>
    fetcher<StaffDetails>({
      url: `${BASE_URL}${urlHelper(API.STAFF, { id })}`,
      method: 'GET',
      token: token
    }),
  getDefault: async () =>
    fetcherParse({
      url: `${BASE_URL}${urlHelper(API.DEFAULT_STAFF_ATTRIBUTABLE, {})}`,
      method: 'GET',
      token: token,
      schema: staffDefaultSchema
    }),
  updateDefault: async (staffId: number) => {
    return fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.DEFAULT_STAFF_ATTRIBUTABLE, {})}`,
      method: 'POST',
      token: token,
      body: { staff: staffId }
    })
  },
  create: async (data: CreateUpdateStaffForm) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${API.STAFFS}`,
      method: 'POST',
      token: token,
      body: data
    }),
  update: async (data: CreateUpdateStaffForm) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.STAFF, { id: data.id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  updateLanguage: async (language: number, id: number) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.UPDATE_STAFF_LANGUAGE, { id })}`,
      method: 'PATCH',
      token: token,
      body: { language: language }
    }),
  updateIsAttributable: async (isAttributable: boolean, id: number) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.UPDATE_STAFF_IS_ATTRIBUTABLE, { id })}`,
      method: 'PATCH',
      token: token,
      body: { isAttributable: isAttributable }
    }),
  createOrUpdatePhoneSystem: async (data: CreateUpdateStaffForm) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.STAFF_PHONE_SYSTEM, { id: data.id })}`,
      method: 'PATCH',
      token: token,
      body: data
    })
})
