import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { Container } from '@mui/system'
import { FiltersBox } from 'app/components/filters/filter-box'
import dayjs from 'dayjs'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { ClientRequest } from 'api/models/stats'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import getLocales from 'app/utils/apexlocales.util'
import { RangePeriodPicker } from 'app/components/filters/range-period-picker'

export const StatsClientRequestView = () => {
  const { t, i18n } = useTranslation()
  const { filtersList, initFilters, handleFilter } = useList()
  const { getStatsClientRequests, searchParams, setSearchParams } = useFetcher()
  const [clientRequest, setClientRequest] = useState<any>([])
  const [locales, setLocales] = useState<any>([])
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['centers', 'centers']])
  )
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const labels = useMemo(() => {
    const dateBegin = dayjs(searchParams.get('begin')).startOf('month')
    const dateEnd = dayjs(searchParams.get('end')).endOf('month')
    const diffBetweenDates = dayjs(dateEnd).diff(dayjs(dateBegin), 'month')
    const dates = []
    for (let i = 0; i <= diffBetweenDates; i++) {
      const formattedDate = dateBegin.add(i, 'month').format('MM/DD/YYYY')
      dates.push(formattedDate)
    }
    return dates
  }, [dayjs, searchParams, listIsLoading])

  const options = useMemo(() => {
    const data: ApexOptions = {
      chart: {
        locales: [locales],
        defaultLocale: i18n.language,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        }
      },
      yaxis: {
        labels: {
          formatter: (val) => String(Math.floor(val))
        }
      },
      xaxis: {
        type: 'datetime',
        categories: labels
      },
      legend: {
        position: 'top'
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        x: {
          formatter: (val) => dayjs(val).format('MMM YYYY')
        }
      }
    }

    return data
  }, [i18n.language, locales, labels])

  const refreshList = useCallback(async () => {
    setListIsLoading(true)
    const response = await getStatsClientRequests.mutateAsync()
    const data = retrieveData(response)
    const formattedData = formatData(data)
    setClientRequest(formattedData)
    setListIsLoading(false)
  }, [getStatsClientRequests])

  const formatData = useCallback((inputData: any) => {
    const formattedData: any[] = []

    inputData.forEach((dataArray: any, index: number) => {
      dataArray.forEach((item: any) => {
        const existingIndex = formattedData.findIndex((obj) => obj.name === item.label)
        if (existingIndex !== -1) {
          formattedData[existingIndex].data[index] = item.value
        } else {
          const newData = Array.from({ length: inputData.length }).fill(0)
          newData[index] = item.value
          formattedData.push({ name: item.label, data: newData })
        }
      })
    })

    return formattedData
  }, [])

  const retrieveData = useCallback((data: ClientRequest) => {
    let items: object[] = []
    data.forEach((item) => {
      let currentItem
      if (item.items.length > 2) {
        currentItem = item.items[item.items.length - 1]
      } else {
        currentItem = item.items[0]
      }
      items.push(currentItem.items)
    })
    return items
  }, [])

  useEffect(() => {
    getLocales().then((locales: any) => setLocales(locales))
    initFilters(commonFilters).then(() => refreshList())
  }, [i18n.language])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('requests_client')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePeriodPicker
            slugStart="begin"
            slugEnd="end"
            labelStart={t('begin')}
            labelEnd={t('end')}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersBox>
      </Grid>
      {listIsLoading ? (
        <CardSkeleton />
      ) : (
        <Box className="bar">
          <Chart options={options} series={clientRequest} type="bar" height="350" />
        </Box>
      )}
    </Container>
  )
}
