import { type Ctx, fetcher, uploader } from 'api'
import { BASE_URL, API } from 'api/constants'
import { AssetsList } from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const assetsRouter = ({ token }: Ctx) => ({
  list: async (type: string, id: string) =>
    fetcher<AssetsList>({
      url: `${BASE_URL}${urlHelper(API.ASSETS, { type, id })}`,
      method: 'GET',
      token: token
    }),
  delete: async (type: string, id: string, imageId: string) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.ASSET, { type, id, imageId })}`,
      method: 'DELETE',
      token: token
    }),
  add: async (id: string, type: string, formData: FormData) =>
    uploader<void>({
      url: `${BASE_URL}${urlHelper(API.ASSETS, { id, type })}`,
      method: 'POST',
      token: token,
      body: formData
    })
})
