import { useTranslation } from 'react-i18next'
import { Box, Paper as MuiPaper } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { TitleComponent } from 'app/components/titles/title.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'

export function EnterpriseConsumptionsComponent() {
  const { t } = useTranslation()
  const { getConsumptionsClient } = useFetcher()
  const [consumptionsMap, setConsumptionsMap] = useState<Map<string, any>>(new Map())
  const { id } = useParams()

  useEffect(() => {
    handleConsumptionsEnterprise().then()
  }, [])

  const handleConsumptionsEnterprise = useCallback(async () => {
    if (!id) return

    const response = await getConsumptionsClient.mutateAsync(id)

    setConsumptionsMap(() => {
      const map = new Map()
      map.set('items', [
        {
          label: t('reference').toUpperCase(),
          value: 'reference',
          link: '/consumption-contracts/:id'
        },
        { label: t('center').toUpperCase(), value: 'center' }
      ])
      map.set('data', response)
      return map
    })
  }, [id])

  return (
    <MuiPaper>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <TitleComponent text={t('consumptions')} variant={'h3'} paddingLeft={12} />
      </Box>
      <ListSheetComponent isLoading={consumptionsMap.size === 0} data={consumptionsMap} />
    </MuiPaper>
  )
}
