import React from 'react'
import { CommitmentsList, TypologiesList } from 'api/models'
import { useTranslation } from 'react-i18next'
import { Card, CardContent, CardHeader, Grid, TextField, Typography, Box } from '@mui/material'
import { Controller } from 'react-hook-form'

export const DiscountGridForm: React.FC<{
  methods: any
  typologies: TypologiesList
  commitments: CommitmentsList
}> = ({ methods, typologies, commitments }) => {
  const { t } = useTranslation()

  const handleNumberInput =
    (onChange: (value: number | null) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      if (value === '') {
        onChange(null)
      } else {
        const numValue = Number(value)
        if (!isNaN(numValue) && numValue >= -100 && numValue <= 100) {
          onChange(numValue)
        }
      }
    }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader
            title={
              <Box display="flex" alignItems="center">
                <Box
                  component="span"
                  sx={{
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    bgcolor: 'primary.main',
                    mr: 1
                  }}
                />
                <Typography variant="h3" fontWeight="bold">
                  {t('discount_rate_typology')}
                </Typography>
              </Box>
            }
          />
          <CardContent>
            {typologies.map((typology, index) => (
              <Grid container spacing={2} alignItems="center" key={typology.id}>
                <Grid item xs={6}>
                  <Typography>{typology.label}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name={`typologyRates.${index}.rate`}
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        value={field.value ?? ''}
                        onChange={handleNumberInput(field.onChange)}
                        inputProps={{ min: -100, max: 100, step: 'any' }}
                        InputProps={{ endAdornment: <Typography>%</Typography> }}
                        size="small"
                        sx={{ width: '100px' }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ))}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader
            title={
              <Box display="flex" alignItems="center">
                <Box
                  component="span"
                  sx={{
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    bgcolor: 'primary.main',
                    mr: 1
                  }}
                />
                <Typography variant="h3" fontWeight="bold">
                  {t('discount_rate_commitment')}
                </Typography>
              </Box>
            }
          />
          <CardContent>
            {commitments.items.map((commitment, index) => (
              <Grid container spacing={2} alignItems="center" key={commitment.id}>
                <Grid item xs={6}>
                  <Typography>{commitment.label}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name={`commitmentRates.${index}.rate`}
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        value={field.value ?? ''}
                        onChange={handleNumberInput(field.onChange)}
                        inputProps={{ min: -100, max: 100, step: 'any' }}
                        InputProps={{ endAdornment: <Typography>%</Typography> }}
                        size="small"
                        sx={{ width: '100px' }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ))}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
