import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Box, Card, CardContent, useTheme } from '@mui/material'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import ApexCharts from 'react-apexcharts'
import React, { useMemo } from 'react'
import { ApexOptions } from 'apexcharts'
import { t } from 'i18next'
import dayjs from 'dayjs'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'

export const DomiciliationAveragePriceComponent = function () {
  const theme = useTheme()
  const { Stats } = useFetcherV2()
  const { searchParams } = useFetcher()

  const { data: stats = null, isLoading } = useQueryFetcher({
    queryKey: ['stats', 'domiciliation-average-price', searchParams.toString()],
    queryFn: () => {
      if (!searchParams.get('center')) return null
      return Stats.domiciliationsAveragePrice(searchParams.toString())
    }
  })
  const categories = stats ? Object.keys(stats) : []
  const seriesData = stats ? Object.values(stats).map((value) => Math.ceil(Number(value))) : []
  const options = useMemo(() => {
    const data: ApexOptions = {
      series: [
        {
          name: t('average_price_per_contract'),
          data: seriesData
        }
      ],
      theme: {
        palette: 'palette5',
        mode: theme.palette.mode
      },
      colors: ['#4CAF50'],
      stroke: {
        width: 3
      },
      chart: {
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        stacked: false
      },
      xaxis: {
        type: 'category',
        categories: categories,
        labels: {
          formatter: function (value) {
            return dayjs(value).format('MMM YY')
          }
        },
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        title: {
          text: t('amount')
        },
        labels: {
          formatter: function (value) {
            return value + ' €'
          }
        },
        min: 0
      },
      tooltip: {
        x: {
          formatter: function (val) {
            return dayjs(categories[val - 1]).format('MMMM YYYY')
          }
        }
      },
      markers: {
        size: 4,
        hover: {
          size: 4
        }
      }
    }
    return data
  }, [categories, seriesData, theme.palette.mode])

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardContent>
        {isLoading ? (
          <CardSkeleton height={300} />
        ) : (
          <Box className="line">
            <ApexCharts options={options} series={options.series} type="line" height={350} />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
