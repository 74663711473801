import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'
import { Company } from 'api/models/companies'
import InfoCard from 'app/components/card/info-card.component'
interface ICompanyDetailsProps {
  company: Company
  children?: React.ReactNode
}

export const CompanyInformations = ({ company }: ICompanyDetailsProps) => {
  const { t } = useTranslation()
  const columns = useMemo(
    () => [
      {
        label: t('id'),
        value: company.id
      },
      {
        label: t('reference'),
        value: company.reference
      },
      {
        label: t('company_name'),
        value: company.companyName
      },
      {
        label: t('address'),
        value: company.address
      },
      {
        label: t('zipcode'),
        value: company.zipCode
      },
      {
        label: t('city'),
        value: company.city
      },
      {
        label: t('country'),
        value: company.country
      },
      {
        label: t('siret'),
        value: company.siret
      },
      {
        label: t('vat'),
        value: company.vat
      },
      {
        label: t('currency'),
        value: company.currencyLabel
      }
    ],
    [company]
  )

  return <InfoCard title={t('informations')} columns={columns}></InfoCard>
}
