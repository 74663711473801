import { ApexOptions } from 'apexcharts'
import i18n from 'i18next'
import { chartPalette, reversedChartPalette } from 'app/helpers/color.helper'
import getLocales from 'app/utils/apexlocales.util'
import merge from 'deepmerge'
import { scaleLinear } from 'd3-scale'

const locales = async () => await getLocales()

export const getChartOptions = async (
  options?: ApexOptions,
  reversedColorScheme: boolean = false
) => {
  return merge(
    {
      chart: {
        stacked: true,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        locales: [await locales()],
        defaultLocale: i18n.language,
        animations: {
          enabled: true,
          easing: 'easeout',
          speed: 800,
          animateGradually: {
            enabled: false
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      fill: {
        opacity: 1
      },
      stroke: {
        show: true,
        width: 2
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      states: {
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none'
          }
        },
        normal: {
          filter: {
            type: 'none'
          }
        },
        hover: {
          filter: {
            type: 'darken',
            value: 0.85
          }
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40,
        onItemClick: {
          toggleDataSeries: true
        },
        onItemHover: {
          highlightDataSeries: false
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        intersect: false,
        shared: true
      },
      colors: reversedColorScheme ? reversedChartPalette : chartPalette
    } as ApexOptions,
    options as ApexOptions
  )
}

export const getScaledPalette = (length: number, reversedColorScheme: boolean = false) => {
  const colors = reversedColorScheme ? reversedChartPalette : chartPalette
  let domains = colors.map((value, index) => index)
  const scale = scaleLinear(domains, colors)
  let computedColors = []
  for (let i = 0; i < length; i++) {
    computedColors.push(scale((i / length) * colors.length))
  }
  return computedColors
}

export type ApexChartData =
  | {
      x: any
      y: any
    }
  | number
