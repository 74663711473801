import { UseQueryOptions } from '@tanstack/react-query'
import { ListType } from 'api/models/commons'
import { useList } from 'app/providers/list.provider'
import { useState } from 'react'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'

export const useQueryFetcherList = function <T>(params: UseQueryOptions<ListType<T>>) {
  const { refetch, isLoading, ...props } = useQueryFetcher({
    ...params,
    refetchOnMount: false
  })
  const { setIsLast, setOffset, setTotal } = useList()
  const [items, setItems] = useState<T[]>([])

  const refreshList = async (reset: boolean = true) => {
    refetch().then(({ data }) => {
      if (!data) return
      setIsLast(data.last)
      setOffset(data.actual)
      setTotal(data.total)
      setItems(reset ? data.items : [...items, ...data.items])
    })
  }

  return {
    ...props,
    isLoading,
    items,
    refreshList
  }
}
