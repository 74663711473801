import { useTranslation } from 'react-i18next'
import React, { useCallback, useMemo } from 'react'
import { OptionService } from 'api/models'
import { formatCurrency, formatDate, formatYesNo } from 'app/utils/format'
import { useApp } from 'app/providers/app.provider'
import InfoCard from 'app/components/card/info-card.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Tooltip } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Stack } from '@mui/system'

interface IOptionDetailsProps {
  optionService: OptionService
  children?: React.ReactNode
}

export const OptionServiceDetails = ({ optionService }: IOptionDetailsProps) => {
  const { t } = useTranslation()
  const { getLanguages } = useApp()
  const { useGetServiceTypeAccountingById } = useFetcher()
  const { data: serviceTypeAccounting } = useGetServiceTypeAccountingById(
    optionService.serviceTypeId
  )

  const formatAccountingInfoAttribute = useCallback(
    (label: string, value: string, serviceTypeValue: string | undefined) => {
      let formattedValue = <></>

      if (value) formattedValue = <>{value}</>

      if (!value && serviceTypeValue) {
        formattedValue = (
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <i>{serviceTypeValue}</i>
            <Tooltip title={t('service_type_info')}>
              <InfoOutlinedIcon color="action" fontSize="small" />
            </Tooltip>
          </Stack>
        )
      }

      return {
        label,
        value: formattedValue
      }
    },
    [t]
  )

  const { savedLabels, info, accounting_info } = useMemo(() => {
    let savedLabels = getLanguages().map((language) => ({
      label: language.label,
      value:
        optionService.savedLabels.find((savedLabel) => savedLabel.language.id === language.id)
          ?.label ?? ''
    }))

    const info = [
      {
        label: t('type'),
        value: optionService.serviceTypeLabel
      },
      { label: '', value: '' },
      {
        label: t('installation_costs'),
        value: optionService.isFreeFees
          ? t('free')
          : formatCurrency(Number(optionService.commissioningFees))
      },
      {
        label: t('monthly_price'),
        value: optionService.isFreePrice
          ? t('free')
          : formatCurrency(Number(optionService.monthlyPrice))
      },
      {
        label: t('begin'),
        value: optionService.begin ? formatDate(optionService.begin) : ''
      },
      {
        label: t('end'),
        value: optionService.end ? formatDate(optionService.end) : ''
      },
      {
        label: t('available_online'),
        value: t(formatYesNo(optionService.isOnline))
      }
    ]

    const accounting_info = [
      formatAccountingInfoAttribute(
        t('accounting_number'),
        optionService.firstAccountingNumber,
        serviceTypeAccounting?.accountingNumber
      ),
      formatAccountingInfoAttribute(
        t('analytic_plan'),
        optionService.firstAnalyticPlan,
        serviceTypeAccounting?.analyticPlan
      ),
      formatAccountingInfoAttribute(
        t('accounting_name'),
        optionService.firstAccountingName,
        serviceTypeAccounting?.accountingName
      )
    ]

    return { savedLabels, info, accounting_info }
  }, [optionService, t, serviceTypeAccounting, formatAccountingInfoAttribute, getLanguages])

  return (
    <>
      <InfoCard title={t('label')} columns={savedLabels} />
      <InfoCard title={t('informations')} columns={info} />
      <InfoCard title={t('accounting_informations')} columns={accounting_info} />
    </>
  )
}
