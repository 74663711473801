import { Alert, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const ForbiddenView = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Alert severity="error" style={{ margin: 32 }} variant="outlined">
      <Typography variant="h1">{t('forbidden_access')}</Typography>
      <p>{t('forbidden_message')}</p>
      <Link to="/">
        <Button variant="contained" color="primary" href="/home">
          {t('go_to_home')}
        </Button>
      </Link>
    </Alert>
  )
}
