import { useTranslation } from 'react-i18next'
import { Box, Paper, Stack, Typography } from '@mui/material'
import { Circle, ContrastRounded, ReceiptLongOutlined } from '@mui/icons-material'
import { formatCurrency, formatDateWithFormat } from 'app/utils/format'
import { Link } from 'app/components/link.component'
import React from 'react'
import { AccoutingStatementItem } from 'api/models'

export const TimelineInvoiceCard = function ({ item }: { item: AccoutingStatementItem }) {
  const { t } = useTranslation()
  return (
    <Paper
      variant={'outlined'}
      sx={{
        width: '560px',
        maxWidth: '50vw',
        position: 'absolute',
        padding: 2
      }}
    >
      <Stack
        paddingX={2}
        direction={'row'}
        flexWrap={['wrap', 'nowrap']}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          {item.typeId === 1 ? (
            <ContrastRounded color={'primary'} />
          ) : (
            <ReceiptLongOutlined color={'primary'} />
          )}
          <Box textAlign={'left'}>
            <Typography variant={'caption'}>
              {item.typeLabel} - {t('due_date')} : {formatDateWithFormat(item.effectDate, 'L')}
            </Typography>
            <Typography variant={'body2'} fontWeight={'bold'}>
              <Link to={`/invoices/${item.id}`}>{item.reference}</Link>
            </Typography>
          </Box>
        </Stack>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Typography variant={'caption'}>{item.statusLabel}</Typography>
          <Circle
            color={item.statusId === 2 ? 'success' : item.statusId === 1 ? 'warning' : 'error'}
            sx={{ fontSize: '0.85rem' }}
          />
        </Stack>
        <Typography variant={'caption'}>{formatCurrency(item.amount)}</Typography>
      </Stack>
    </Paper>
  )
}
