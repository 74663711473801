import { type Ctx, fetcher } from 'api'
import { BASE_URL, API } from 'api/constants'
import { ClientCenter } from 'api/models'
import { ClientCenterForm, ClientCenterMailForm } from 'api/models/forms/client_center'
import urlHelper from 'app/helpers/url.helper'

export const clientCenterRouter = ({ token }: Ctx) => ({
  get: async (id: string, centerId: string) =>
    fetcher<ClientCenter>({
      url: `${BASE_URL}${urlHelper(API.CLIENT_CENTERS, { id })}${
        centerId ? `?center=${centerId}` : ''
      }`,
      method: 'GET',
      token: token
    }),
  update: async (id: string, clientCenterId: string, data: ClientCenterForm) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_CLIENT_CENTER, { id, clientCenterId })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  updateMail: async (id: string, clientCenterId: string, data: ClientCenterMailForm) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_CLIENT_CENTER_MAIL, { id, clientCenterId })}`,
      method: 'PATCH',
      token: token,
      body: data
    })
})
