import React, { useEffect, useMemo, useState } from 'react'
import { Box, Card, CardContent } from '@mui/material'
import { ApexOptions } from 'apexcharts'
import { formatCurrency } from 'app/utils/format'
import getLocales from 'app/utils/apexlocales.util'
import i18n from 'i18next'
import localeData from 'dayjs/plugin/localeData'
import dayjs from 'dayjs'
import { ThemedChart } from 'app/components/charts/timeline.component'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'
import { ChartBpColor, chartPalette, reversedChartPalette } from 'app/helpers/color.helper'
import { useChartOptions } from 'app/hooks/use-chart-options.hook'
import merge from 'deepmerge'
import { renderToString } from 'react-dom/server'
import { useTranslation } from 'react-i18next'
dayjs.extend(localeData)

type TooltipCaBpProps = {
  initialSeries: any
  dataPointIndex: number
  totals: { data: number[] }
  title: string
  differenceTitle: string
}

const TooltipCaBp = function ({
  initialSeries,
  dataPointIndex,
  totals,
  title,
  differenceTitle
}: TooltipCaBpProps) {
  if (!totals || !totals.data) return null
  let diff = 0
  const test = initialSeries.map((serie: any) => {
    if (serie.name === 'BP') {
      diff = serie.data[dataPointIndex] - totals.data[dataPointIndex]
    }
    return {
      name: serie.name,
      color: serie.color,
      value: serie.data[dataPointIndex]
    }
  })

  return (
    <>
      <div
        className="apexcharts-tooltip-title"
        style={{ fontFamily: 'Helvetica, Arial, sans-serif', fontSize: '12px' }}
      >
        {title}
      </div>
      {test.map((item: any, index: number) => (
        <div
          key={index}
          className={`apexcharts-tooltip-series-group apexcharts-tooltip-series-group-${index} apexcharts-active`}
          style={{ display: 'flex' }}
        >
          <span
            className="apexcharts-tooltip-marker"
            style={{
              backgroundColor: item.color ?? reversedChartPalette[index % chartPalette.length]
            }}
          ></span>
          <div
            className="apexcharts-tooltip-text"
            style={{ fontFamily: 'Helvetica, Arial, sans-serif', fontSize: '12px' }}
          >
            <div className="apexcharts-tooltip-y-group">
              <span className="apexcharts-tooltip-text-y-label">{item.name} :</span>
              <span className="apexcharts-tooltip-text-y-value">{formatCurrency(item.value)}</span>
            </div>
          </div>
        </div>
      ))}
      <div
        className={`apexcharts-tooltip-series-group apexcharts-tooltip-series-group-total apexcharts-active`}
        style={{ display: 'flex' }}
      >
        <span
          className="apexcharts-tooltip-marker"
          style={{ backgroundColor: 'transparent' }}
        ></span>
        <div
          className="apexcharts-tooltip-text"
          style={{ fontFamily: 'Helvetica, Arial, sans-serif', fontSize: '12px' }}
        >
          <div className="apexcharts-tooltip-y-group">
            <span className="apexcharts-tooltip-text-y-label">{differenceTitle} :</span>
            <span className="apexcharts-tooltip-text-y-value">{formatCurrency(diff)}</span>
          </div>
        </div>
      </div>
    </>
  )
}

export const CaVsBpChartComponent = function ({
  height,
  params,
  toolbarShow,
  labels,
  types,
  notIncludeOptions = false,
  byLabels = false
}: {
  height: number
  params: URLSearchParams
  toolbarShow: boolean
  labels: Array<any>
  types?: string[]
  notIncludeOptions?: boolean
  byLabels?: boolean
}) {
  const [locales, setLocales] = useState<any>([])
  const { Stats } = useFetcherV2()
  const { t } = useTranslation()

  const { data = [], isFetching: isLoading } = useQueryFetcher({
    refetchOnWindowFocus: false,
    queryKey: ['stats', 'ca-vs-bp', types, notIncludeOptions, byLabels, params.toString()],
    queryFn: async () => {
      const searchParams = new URLSearchParams(params)
      if (types) {
        searchParams.set('types', JSON.stringify(types))
      }
      searchParams.set('byLabels', byLabels ? 'true' : 'false')
      searchParams.set('notIncludeOptions', notIncludeOptions ? 'true' : 'false')
      const data = await Stats.CA(searchParams.toString())
      if (!data) return []
      const formatted: ApexAxisChartSeries = Object.entries(data)
        .filter((values) => values[0] !== 'Total' && values[0] !== 'BP')
        .map(([name, values], index) => ({
          name: name,
          data: Object.values(values).map((value) => (value === 0 ? null : value)),
          type: 'bar',
          color: reversedChartPalette[index % chartPalette.length]
        }))

      formatted.push({
        name: 'BP',
        data: data.BP ? Object.values(data.BP) : [],
        type: 'line',
        color: ChartBpColor
      })

      const totals = {
        name: 'Total',
        data: data.Total ? Object.values(data.Total) : [],
        type: 'line',
        color: ChartBpColor
      }
      return [formatted, totals]
    }
  })

  const [formattedRows, totals] = data as [ApexAxisChartSeries, any]

  useEffect(() => {
    getLocales().then((locales: any) => setLocales(locales))
  }, [])

  const defaultOptions = useChartOptions()
  const options = useMemo(() => {
    return merge(defaultOptions, {
      chart: {
        locales: [locales],
        defaultLocale: i18n.language,
        toolbar: {
          show: toolbarShow,
          tools: {
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false
          }
        }
      },
      stroke: {
        width: 2,
        curve: 'smooth'
      },
      fill: {
        opacity: 1
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      tooltip: {
        shared: true,
        intersect: false,
        custom: function ({ dataPointIndex, w }) {
          return renderToString(
            <TooltipCaBp
              initialSeries={w.globals.initialSeries}
              dataPointIndex={dataPointIndex}
              title={dayjs(labels[dataPointIndex]).format('MMMM YYYY')}
              differenceTitle={t('ca_vs_bp_difference')}
              totals={totals}
            />
          )
        }
      },
      xaxis: {
        type: 'category',
        categories: labels,
        tickAmount: labels.length,
        labels: {
          rotate: -45,
          rotateAlways: true,
          formatter: function (value) {
            return dayjs(value).format('MMM YY')
          }
        },
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        labels: {
          formatter: (val: number) => {
            return formatCurrency(val)
          }
        }
      },
      legend: {
        formatter: (seriesName) => {
          return seriesName
        },
        position: 'top',
        markers: {
          strokeWidth: 0,
          shape: 'circle'
        },
        onItemHover: {
          highlightDataSeries: true
        }
      },
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      }
    } as ApexOptions)
  }, [t, labels, totals, defaultOptions, locales, toolbarShow])

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardContent>
        {isLoading ? (
          <CardSkeleton height={height} />
        ) : (
          <Box className="line">
            <ThemedChart options={options} series={formattedRows} type="bar" height={height} />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
