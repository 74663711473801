import { Button, InputAdornment, TextField } from '@mui/material'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { Mail } from '@mui/icons-material'
import { ForgotPasswordInputs, forgotPasswordInputsSchema } from 'api/models'
import { useFeedback } from 'app/providers/feedback.provider'
import { authRouter } from 'api/routes'

export default function ForgottenPasswordForm() {
  const { t } = useTranslation()
  const { toast } = useFeedback()
  const methods = useForm<ForgotPasswordInputs>({
    defaultValues: {
      email: ''
    },
    resolver: zodResolver(forgotPasswordInputsSchema)
  })

  const onSubmit = async (data: ForgotPasswordInputs) => {
    try {
      await authRouter.forgottenPassword(data)

      toast({
        message: t('reset_password_email_sent'),
        variant: 'success'
      })
    } catch (e) {
      toast({
        message: t('sign_in_error'),
        variant: 'error'
      })
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <TextField
          label="Email"
          variant="outlined"
          required
          fullWidth
          margin="normal"
          error={!!methods.formState.errors.email}
          helperText={
            methods.formState.errors.email
              ? t(`errors.${methods.formState.errors.email?.type}`)
              : null
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Mail />
              </InputAdornment>
            )
          }}
          {...methods.register('email')}
        />
        <Button
          variant="contained"
          fullWidth
          type="submit"
          disabled={
            methods.formState.isSubmitting ||
            !methods.formState.isValid ||
            methods.formState.isSubmitSuccessful
          }
        >
          {t('reset_password')}
        </Button>
      </form>
    </FormProvider>
  )
}
