import { HourglassTop, SquareFoot } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Paper as MuiPaper } from '@mui/material'

import type { EnterpriseInformation } from 'api/models'
import {
  formatCommitment,
  formatContractState,
  formatCurrency,
  formatSurface
} from 'app/utils/format'
import React, { useEffect } from 'react'
import { List } from 'app/components/lists/list'
import { useList } from 'app/providers/list.provider'
import { TitleComponent } from 'app/components/titles/title.component'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useQueryFetcherList } from 'app/hooks/use-query-fetcher-list'

interface IEnterpriseContract {
  enterprise: EnterpriseInformation
}

export function EnterpriseContractsComponent({ enterprise }: IEnterpriseContract) {
  const { t } = useTranslation()
  const { orderBy, handleSort, handleFilter } = useList()
  const { Enterprises } = useFetcherV2()
  const { items, isLoading, refreshList } = useQueryFetcherList({
    queryKey: ['enterprises', 'contracts', enterprise.id],
    queryFn: () => Enterprises.listContracts(String(enterprise.id))
  })

  useEffect(() => {
    refreshList().then()
  }, [enterprise])

  return (
    <MuiPaper>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <TitleComponent text={t('contracts')} variant={'h3'} paddingLeft={12} />
      </Box>
      <Grid container columns={4}>
        <Grid item xs={4}>
          <List
            items={items}
            selectable={false}
            columns={[
              {
                label: '',
                slug: 'state',
                text: 'stateLabel',
                valueFormatter: formatContractState
              },
              {
                label: t('reference'),
                slug: 'reference',
                link: { base: '/contracts', slug: 'id' }
              },
              {
                label: t('type'),
                slug: 'contractTypeLabel'
              },
              {
                label: t('center'),
                slug: 'centerName'
              },
              {
                label: SquareFoot,
                slug: 'surface',
                valueFormatter: formatSurface,
                tooltip: t('surface')
              },
              {
                label: t('main_services'),
                slug: 'mainPrice',
                valueFormatter: formatCurrency
              },
              {
                label: t('options'),
                slug: 'optionsPrice',
                valueFormatter: formatCurrency
              },
              {
                label: HourglassTop,
                slug: 'remainingMonths',
                valueFormatter: formatCommitment,
                tooltip: t('remaining_months')
              }
            ]}
            handleReset={() => handleFilter(refreshList, true)}
            sort={orderBy}
            handleSort={(property) => handleSort(refreshList, property)}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </MuiPaper>
  )
}
