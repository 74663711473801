import React, { useCallback, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import type { Mandate } from 'api/models'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { formatDateWithTime, formatMandatePayment, formatMandateState } from 'app/utils/format'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { useList } from 'app/providers/list.provider'
import { useApp } from 'app/providers/app.provider'
import { FiltersContainer } from 'app/components/filters/filters-container'

export const MandatesView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { user } = useApp()
  const { isLast, total, orderBy, setOffset, setTotal, setIsLast, handleSort, handleFilter } =
    useList()
  const { getMandates, searchParams, setSearchParams } = useFetcher()
  const [mandates, setMandates] = useState<Mandate[]>([])
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['mandate_states', 'isActive']])
  )
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      setListIsLoading(true)
      await getMandates
        .mutateAsync()
        .then((data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setMandates(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))
    },
    [getMandates, setOffset, setIsLast, setTotal]
  )

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('mandats')}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          commonFilters={commonFilters}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          onChange={() => handleFilter(refreshList, true)}
        >
          <SearchCenterInput
            slug="center"
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
            initialValue={user?.mainCenter}
          />
          <SearchClientInput
            slug="client"
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersContainer>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {mandates.length} / {total}
          </Typography>
        </Grid>
        <List
          items={mandates}
          columns={[
            { label: t('state'), slug: 'isActive', valueFormatter: formatMandateState },
            {
              label: t('mandat'),
              slug: 'partnerPaymentMethodId',
              link: { base: '/mandates', slug: 'id' }
            },
            { label: t('partner'), slug: 'partnerLabel' },
            {
              label: t('client'),
              slug: 'clientName',
              link: { base: '/enterprises', slug: 'clientId' }
            },
            {
              label: t('center'),
              slug: 'centerName',
              link: { base: '/centers', slug: 'centerId' }
            },
            { label: t('created_at'), slug: 'createdAt', valueFormatter: formatDateWithTime },
            {
              label: t('payments'),
              slug: 'nbPaymentsConfirmed',
              text: 'nbPaymentsTotal',
              valueFormatter: formatMandatePayment
            }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {mandates.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
