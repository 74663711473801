import React, { useCallback, useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'

export const TypologiesView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { orderBy, handleSort, handleFilter } = useList()
  const { searchParams } = useFetcher()
  const { Typologies } = useFetcherV2()
  const {
    data: typologies = [],
    isLoading,
    refetch
  } = useQueryFetcher({
    queryKey: ['typologies', 'list'],
    queryFn: () => Typologies.list(searchParams.toString())
  })

  const getData = useCallback(async () => {
    refetch()
  }, [refetch])

  useEffect(() => {
    getData().then()
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('typologies')}
        </Typography>
      </Box>
      <Grid>
        <List
          items={typologies}
          columns={[
            {
              label: t('id'),
              slug: 'id'
            },
            {
              label: t('reference'),
              slug: 'reference'
            },
            {
              label: t('label'),
              slug: 'label'
            }
          ]}
          handleReset={() => handleFilter(getData, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(getData, property)}
          isLoading={isLoading}
        />
      </Grid>
    </Container>
  )
}
