import { TitleComponent } from 'app/components/titles/title.component'
import { Grid, Typography, Select, MenuItem, TextField, InputAdornment } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ConsumptionService, FormItem, OptionService, OptionServiceBody } from 'api/models'
import { useTranslation } from 'react-i18next'
import { UseFormReturn } from 'react-hook-form'
import { ListSkeleton } from 'app/components/skeletons/list.skeleton'
import dayjs from 'dayjs'
import { Euro } from '@mui/icons-material'
import { ControlledCheckboxField } from 'app/components/form/controlled-checkbox.component'
import { ControlledDatePicker } from 'app/components/form/controlled-datepicker.component'
import { ControlledTextField } from 'app/components/form/controlled-textfield.component'
import { Controller } from 'react-hook-form'
import { useFetcher } from 'app/providers/fetcher.provider'

type IProps =
  | {
      type: 'option'
      optionService: OptionService
      methods: UseFormReturn<OptionServiceBody>
      formItems: FormItem
    }
  | {
      type: 'consumption'
      optionService: ConsumptionService
      methods: UseFormReturn<OptionServiceBody>
      formItems: FormItem
    }

export default function OptionInformationsComponent({
  type,
  methods,
  optionService,
  formItems
}: IProps) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const isFreePriceWatch = methods.watch('isFreePrice')
  const isFreeFeesWatch = methods.watch('isFreeFees')
  const serviceType = methods.watch('serviceType')

  const { useGetServiceTypeAccountingById } = useFetcher()
  const { data: selectedServiceTypeAccounting } = useGetServiceTypeAccountingById(serviceType)

  useEffect(() => {
    if (!isLoading) {
      const defaultValue = formItems.values.filter(
        (type: any) => type.label === optionService.serviceTypeLabel
      )
      if (defaultValue.length > 0) {
        methods.setValue('serviceType', Number(defaultValue[0].id))
      }
    }
  }, [formItems, isLoading])

  useEffect(() => {
    if (isFreePriceWatch) {
      methods.setValue('price', null, { shouldValidate: true })
    }
    if (isFreeFeesWatch) {
      methods.setValue('commissioningFees', null, { shouldValidate: true })
    }
  }, [isFreeFeesWatch, isFreePriceWatch])

  useEffect(() => {
    methods.setValue(
      'commissioningFees',
      optionService.commissioningFees !== null ? Number(optionService.commissioningFees) : null
    )
    methods.setValue('isFreeFees', optionService.isFreeFees)
    methods.setValue(
      'price',
      type === 'consumption' ? Number(optionService.price) : Number(optionService.monthlyPrice)
    )
    methods.setValue('isFreePrice', optionService.isFreePrice)
    methods.setValue('begin', optionService.begin !== null ? dayjs(optionService.begin) : null)
    methods.setValue('end', optionService.end !== null ? dayjs(optionService.end) : null)
    methods.setValue(
      'serviceAccounting.0.accountingNumber',
      optionService.firstAccountingNumber ?? ''
    )
    methods.setValue('serviceAccounting.0.analyticPlan', optionService.firstAnalyticPlan ?? '')
    methods.setValue('serviceAccounting.0.accountingName', optionService.firstAccountingName ?? '')
    methods.setValue('isOnline', optionService.isOnline)
    setIsLoading(false)
  }, [optionService])

  return (
    <>
      <TitleComponent
        text={t('main_informations')}
        variant={'h3'}
        paddingTop={0}
        paddingLeft={12}
        marginBottom={4}
      />
      {isLoading ? (
        <ListSkeleton />
      ) : (
        <Grid container spacing={5} marginBottom={5}>
          <Grid container item xs={12} sm={6}>
            <Grid item xs={12} sm={4} margin={'auto'}>
              <Typography>{t('type')} *</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Controller
                name="serviceType"
                control={methods.control}
                defaultValue={Number(formItems.values[0].id)}
                render={({ field: { value, name } }) => {
                  return (
                    <Select
                      size={'small'}
                      fullWidth
                      value={value}
                      name={name}
                      onChange={(e) => methods.setValue(name, Number(e.target.value))}
                    >
                      {formItems.values.map((v: any) => {
                        return (
                          <MenuItem key={v.id} value={Number(v.id)}>
                            {v.label}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  )
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={6}></Grid>
          <Grid container item xs={12} sm={6}>
            <Grid item xs={12} sm={4} margin={'auto'}>
              <Typography>
                {type === 'consumption' ? t('fees') : t('installation_costs')}{' '}
                {!isFreeFeesWatch ? '*' : ''}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                fullWidth
                size={'small'}
                variant="outlined"
                type="number"
                disabled={Boolean(isFreeFeesWatch)}
                InputProps={{
                  inputProps: {
                    step: 0.01,
                    min: 0
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Euro fontSize={'small'} />
                    </InputAdornment>
                  )
                }}
                {...methods.register('commissioningFees', {
                  valueAsNumber: true
                })}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={6}>
            <Grid item xs={12} sm={4} margin={'auto'}>
              <Typography>{t('free_fees')}</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ControlledCheckboxField name={'isFreeFees'} control={methods.control} />
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={6}>
            <Grid item xs={12} sm={4} margin={'auto'}>
              <Typography>
                {type === 'consumption' ? t('price') : t('monthly_price')}{' '}
                {!isFreePriceWatch ? '*' : ''}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                fullWidth
                size={'small'}
                variant="outlined"
                type="number"
                disabled={Boolean(isFreePriceWatch)}
                InputProps={{
                  inputProps: {
                    step: 0.01,
                    min: 0
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Euro fontSize={'small'} />
                    </InputAdornment>
                  )
                }}
                {...methods.register('price', {
                  valueAsNumber: true
                })}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={6}>
            <Grid item xs={12} sm={4} margin={'auto'}>
              <Typography>{t('free_price')}</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ControlledCheckboxField name={'isFreePrice'} control={methods.control} />
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={6}>
            <Grid item xs={12} sm={4} margin={'auto'}>
              <Typography>{t('begin')}</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ControlledDatePicker name={'begin'} control={methods.control} />
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={6}>
            <Grid item xs={12} sm={4} margin={'auto'}>
              <Typography>{t('end')}</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ControlledDatePicker name={'end'} control={methods.control} />
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={6}>
            <>
              <Grid item xs={12} sm={4} margin={'auto'}>
                <Typography>{t('accounting_number')}</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <ControlledTextField
                  name={'serviceAccounting.0.accountingNumber'}
                  control={methods.control}
                  placeholder={selectedServiceTypeAccounting?.accountingNumber}
                  variant="outlined"
                />
              </Grid>
            </>
            <>
              <Grid item xs={12} sm={4} margin={'auto'} mt={4}>
                <Typography>{t('analytic_plan')}</Typography>
              </Grid>
              <Grid item xs={12} sm={8} mt={4}>
                <ControlledTextField
                  name={'serviceAccounting.0.analyticPlan'}
                  control={methods.control}
                  placeholder={selectedServiceTypeAccounting?.analyticPlan}
                  variant="outlined"
                />
              </Grid>
            </>
            <>
              <Grid item xs={12} sm={4} margin={'auto'} mt={4}>
                <Typography>{t('accounting_name')}</Typography>
              </Grid>
              <Grid item xs={12} sm={8} mt={4}>
                <ControlledTextField
                  name={'serviceAccounting.0.accountingName'}
                  control={methods.control}
                  placeholder={selectedServiceTypeAccounting?.accountingName}
                  variant="outlined"
                />
              </Grid>
            </>
          </Grid>
          <Grid container item xs={12} sm={6} height="fit-content">
            <Grid item xs={12} sm={4} margin={'auto'}>
              <Typography>{t('available_online')}</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ControlledCheckboxField name={'isOnline'} control={methods.control} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}
