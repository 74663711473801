import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Grid, Typography, Container } from '@mui/material'
import { ServiceType } from 'api/models'
import { Collections } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { List } from 'app/components/lists/list'
import { useFeedback } from 'app/providers/feedback.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { isImageAssociated } from 'app/utils/format'
import {
  EditImagesDialog,
  DialogRef,
  typeTags
} from 'app/components/dialog/edit-images-dialog.component'

export const ServicesTypesView = () => {
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { getServicesTypes } = useFetcher()
  const dialogRef = useRef<DialogRef>(null)
  const [servicesTypes, setServicesTypes] = useState<ServiceType[]>([])
  const [currentServiceType, setCurrentServiceType] = useState<ServiceType | null>(null)
  const { isLast, total, orderBy, setOffset, setTotal, setIsLast, handleFilter, handleSort } =
    useList()
  const refreshList = useCallback(
    async (reset: boolean) => {
      await handleMutation({
        onStart: () => setIsLoading(true),
        mutation: getServicesTypes,
        onSuccess: (data) => {
          setIsLast(data.last)
          setOffset(data.actual)
          setTotal(data.total)
          setServicesTypes(reset ? data.items : (prev) => [...prev, ...data.items])
          setCurrentServiceType(null)
        },
        onEnd: () => setIsLoading(false)
      })
    },
    [getServicesTypes, setIsLast, setOffset, setTotal, setIsLoading, handleMutation]
  )

  const handleOpenDialog = (item: ServiceType) => {
    setCurrentServiceType(item)
    dialogRef.current?.open()
  }

  useEffect(() => {
    refreshList(true).then()
  }, [])

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('default_images')}
        </Typography>
      </Box>
      <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
        <Typography variant="body2" gutterBottom>
          {servicesTypes?.length} / {total}
        </Typography>
      </Grid>
      <List
        items={servicesTypes || []}
        columns={[
          {
            label: t('label'),
            unsorted: true,
            slug: 'label'
          },
          {
            label: Collections,
            slug: 'isImageAssociated',
            unsorted: true,
            condition: (item: ServiceType) => isImageAssociated(item, handleOpenDialog)
          }
        ]}
        handleReset={() => handleFilter(refreshList, true)}
        sort={orderBy}
        handleSort={(property) => handleSort(() => refreshList(true), property)}
        isLoading={isLoading}
      />
      <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
        <Typography variant="body2" gutterBottom marginTop={2}>
          {servicesTypes?.length} / {total}
        </Typography>
      </Grid>
      {!isLast && <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />}
      <EditImagesDialog
        type={typeTags.serviceTypes}
        entityLabel={currentServiceType?.label ?? ''}
        entityId={currentServiceType?.id ?? ''}
        ref={dialogRef}
        onClose={() => {
          refreshList(true)
        }}
      />
    </Container>
  )
}
