import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { Litigation, LitigationItem, type LitigationList } from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const litigationsRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<LitigationList>({
      url: `${BASE_URL}${API.LITIGATIONS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getOne: async (id: number) =>
    fetcher<Litigation>({
      url: `${BASE_URL}${urlHelper(API.LITIGATION, { id })}`,
      method: 'GET',
      token: token
    }),
  getContracts: async (id: number) =>
    fetcher<LitigationItem>({
      url: `${BASE_URL}${urlHelper(API.LITIGATION_CONTRACTS, { id })}`,
      method: 'GET',
      token: token
    }),
  getInvoices: async (id: number) =>
    fetcher<LitigationItem>({
      url: `${BASE_URL}${urlHelper(API.LITIGATION_INVOICES, { id })}`,
      method: 'GET',
      token: token
    }),
  update: async (id: number, reference: string) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.LITIGATION, { id: id })}`,
      method: 'PATCH',
      token: token,
      body: { reference }
    }),
  close: async (id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.CLOSE_LITIGATION, { id: id })}`,
      method: 'PATCH',
      token: token
    })
})
