import { type Ctx, fetcher } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  CreateHappeningForm,
  HappeningsList,
  HappeningsListPartner,
  HappeningsDetails,
  UpdateHappening,
  CreateHappeningPartnerForm
} from 'api/models/happenings'
import urlHelper from 'app/helpers/url.helper'
import { IdReturn } from 'api/models'

export const happeningsRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<HappeningsList>({
      url: `${BASE_URL}${API.HAPPENINGS}?${params}`,
      method: 'GET',
      token: token
    }),
  getPartners: async (params: string) =>
    fetcher<HappeningsListPartner>({
      url: `${BASE_URL}${API.HAPPENINGS_PARTNERS}?${params}`,
      method: 'GET',
      token: token
    }),
  getRead: async (id: number) =>
    fetcher<HappeningsDetails>({
      url: `${BASE_URL}${urlHelper(API.HAPPENING, { id: id })}`,
      method: 'GET',
      token: token
    }),
  create: async (data: CreateHappeningForm) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${API.HAPPENINGS}`,
      method: 'POST',
      token: token,
      body: data
    }),
  update: async (id: number, data: UpdateHappening) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.HAPPENING, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  createPartners: async (data: CreateHappeningPartnerForm) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${API.HAPPENINGS_PARTNERS}`,
      method: 'POST',
      token: token,
      body: data
    })
})
