import dayjs from 'dayjs'
import {
  Business,
  Cancel,
  Check,
  CircleRounded,
  Computer,
  ErrorOutline,
  Home,
  QuestionMark,
  NotificationImportant,
  Notifications,
  NotificationsActive,
  Remove,
  CreditCardOff,
  AccountBalance,
  Payment,
  PointOfSale,
  PriceCheck,
  Circle,
  People,
  Close,
  Groups
} from '@mui/icons-material'
import { Chip, Tooltip, Badge, Typography, Box, Alert, Button } from '@mui/material'
import { ClockIcon } from '@mui/x-date-pickers'
import i18n from 'i18next'
import { OverridableStringUnion } from '@mui/types'
import { SvgIconPropsColorOverrides } from '@mui/material/SvgIcon/SvgIcon'
import { Main, QuotationSolution, ServiceType } from 'api/models'
import React from 'react'
import styled from '@emotion/styled'
import { Bureau24, CoWorking, Domiciliation } from 'app/assets/icon-svg'
import { Stack } from '@mui/system'
import { ZodError, ZodIssue } from 'zod'
import { AlertColor } from '@mui/material/Alert/Alert'

export const formatNumbers = (value: number | string) => {
  return Number(value)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

const StyledIcon = styled.svg`
  width: 20px;
`

export const formatDiscountGridType = (value: string) => {
  if (value === '1') {
    return (
      <Box display="flex" alignItems="center" gap={2}>
        <Tooltip title={i18n.t('full_office')} placement="top">
          <StyledIcon as={Bureau24} />
        </Tooltip>
        <Tooltip title={i18n.t('full_coworking')} placement="top">
          <StyledIcon as={CoWorking} />
        </Tooltip>
      </Box>
    )
  }
  if (value === '2') {
    return (
      <Box display="flex" alignItems="center">
        <Tooltip title={'Domiciliation'} placement="top">
          <StyledIcon as={Domiciliation} />
        </Tooltip>
      </Box>
    )
  }
}

export const formatCurrency = (value: number | string, text: string = '', toFixed: number = 2) => {
  if (value !== '' && !isNaN(Number(value))) {
    // Convert value to a number
    let numValue = Number(value);

    // Round value based on the decimal part
    numValue = Math.round(numValue); // Rounding to nearest integer

    // Format the number with a space as thousand separator
    const formattedValue = numValue
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' €';

    // Return formatted value with optional text
    return text !== '' ? formattedValue + ' (+' + text + ' €) ' : formattedValue;
  }
  return String(value);
}

export const formatYesNo = (value: boolean) => {
  return value ? 'yes' : 'no'
}

export const formatActive = (value: boolean) => {
  return <CircleRounded color={value ? 'success' : 'error'} fontSize={'small'} />
}

export const formatReductionOrPrice = (item: QuotationSolution) => {
  return item.reduction > 0 ? item.reductionPrice : item.price
}

export const formatSurfacePrice = (value: number | string) => {
  return Number(value).toFixed(2).replace('.', ',') + ' €/m²'
}

export const formatSurface = (value: number | string, text: string = '', showSingleDecimal: boolean = false) => {
  const parsedValue = Number(value);
  const isInteger = parsedValue % 1 === 0;

  if (isInteger) {
    return Math.floor(parsedValue) + ' m²' + (text !== '' ? ` (+' + text + ' m²)` : '');
  }
  if (showSingleDecimal && parsedValue > 0.1) {
    return parsedValue.toFixed(1).replace('.', ',') + ' m²' + (text !== '' ? ` (+' + text + ' m²)` : '');
  }
  return parsedValue.toFixed(2).replace('.', ',') + ' m²' + (text !== '' ? ` (+' + text + ' m²)` : '');
}

export const formatRate = (value: number | string, showSingleDecimal: boolean = false) => {
  const parsedValue = parseFloat(String(value));
  const isInteger = parsedValue % 1 === 0;

  if (isInteger) {
    return Math.floor(parsedValue) + ' %';
  }
  if (showSingleDecimal && parsedValue > 0.1) {
    return parsedValue.toFixed(1).replace('.', ',') + ' %';
  }
  return parsedValue.toFixed(2).replace('.', ',') + ' %';
}

export const formatDate = (value: string) => {
  return value && dayjs(value).format('L')
}

export const formatIconCheck = (value: string) => {
  return value === '1' ? <Check color="primary" /> : <Remove color="error" />
}

export const formatDeviceState = (value: number) => {
  return <CircleRounded color={value === 1 ? 'success' : 'error'} />
}

export const formatMonth = (value: string) => {
  let date = new Date(value)
  let months = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
  ]
  return date.getDate() + ' ' + months[Number(date.getMonth() + 1) - 1]
}

export const formatDateWithTime = (value: string) => {
  return value && dayjs(value).format('L LT')
}

export const formatDateWithFormat = (value: string, format: string): string | undefined => {
  return value && dayjs(value).format(format)
}

export const formatPercentage = (value: number | string) => {
  return (Number(value) * 100).toFixed(2).replace('.', ',') + ' %'
}

export const formatContractType = (value: string, text: string = '') => {
  let icon: JSX.Element
  switch (value.toLowerCase()) {
    case 'full_desktop':
      icon = <Business />
      break
    case 'nomade_plan':
      icon = <Computer />
      break
    case 'domiciliation':
      icon = <Home />
      break
    default:
      icon = <QuestionMark />
  }

  if (text !== '') {
    return (
      <Tooltip title={text} placement="top">
        {icon}
      </Tooltip>
    )
  }
  return icon
}

export const formatContractTypeId = (value: string, text: string = '') => {
  let icon: JSX.Element
  switch (value) {
    case '1':
      icon = <Business />
      break
    case '2':
      icon = <Groups />
      break
    case '3':
      icon = <Computer />
      break
    case '4':
      icon = <Home />
      break
    default:
      icon = <QuestionMark />
  }

  if (text !== '') {
    return (
      <Tooltip title={text} placement="top">
        {icon}
      </Tooltip>
    )
  }
  return icon
}

export const formatCommitment = (value: string, text: string = '') => {
  value = value + ' ' + i18n.t('month')
  return text !== '' ? value + ' (+' + text + ' ' + i18n.t('month') + ') ' : value
}

export const formatCustomerReservationStatus = (value: string, text: string, id?: string) => {
  let color: OverridableStringUnion<
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning',
    SvgIconPropsColorOverrides
  > = 'warning'

  switch (Number(value)) {
    case 1:
      color = 'secondary'
      break
    case 2:
      color = 'success'
      break
    case 3:
      color = 'warning'
      break
  }

  return (
    <Box component="span" display="flex" alignItems="center" justifyContent="center">
      <Tooltip title={text} placement="top">
        <CircleRounded color={color} style={{ marginRight: 8 }} />
      </Tooltip>
      <Typography component={'span'}>{id ?? ''}</Typography>
    </Box>
  )
}

export const formatClientRequestStatus = (value: string, text: string) => {
  let color: OverridableStringUnion<
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning',
    SvgIconPropsColorOverrides
  > = 'success'

  switch (Number(value)) {
    case 1:
      color = 'secondary'
      break
    case 2:
      color = 'info'
      break
    case 4:
      color = 'disabled'
      break
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start">
      <CircleRounded color={color} style={{ marginRight: 8 }} />
      <Typography>{text}</Typography>
    </Box>
  )
}

export const formatContractState = (value: string, text: string) => {
  let color: OverridableStringUnion<
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning',
    SvgIconPropsColorOverrides
  > = 'warning'

  switch (Number(value)) {
    case 1:
      color = 'secondary'
      break
    case 2:
      color = 'info'
      break
    case 3:
      color = 'success'
      break
    case 4:
      color = 'action'
      break
    case 6:
      color = 'disabled'
      break
  }
  return (
    <Tooltip title={text} placement="top">
      <CircleRounded color={color} fontSize="small" />
    </Tooltip>
  )
}

export const formatStaffBadge = (value: string, text: string) => {
  return (
    <Box textAlign={'center'}>
      <Tooltip title={text} placement="top">
        <Badge badgeContent={value} color="primary" />
      </Tooltip>
    </Box>
  )
}

export const formatMandateState = (value: number) => {
  let color: OverridableStringUnion<
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning',
    SvgIconPropsColorOverrides
  > = 'secondary'
  let text = 'Non actif'

  if (Number(value) === 1) {
    text = 'Actif'
    color = 'success'
  }

  return (
    <Tooltip title={text} placement="top">
      <CircleRounded color={color} fontSize={'small'} />
    </Tooltip>
  )
}

export const formatMandatePayment = (value: number, text: string) => {
  return value + ' / ' + text
}

export const formatInvoiceStatus = (value: number, text: string) => {
  let color:
    | 'primary'
    | 'default'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined
  let icon
  switch (Number(value)) {
    case 2:
      color = 'primary'
      icon = <Notifications />
      break
    case 3:
      color = 'secondary'
      icon = <NotificationsActive />
      break
    case 4:
      color = 'success'
      icon = <Check />
      break
    case 5:
      color = 'warning'
      icon = <NotificationImportant />
      break
    case 6:
      color = 'default'
      icon = <Cancel />
      break
    default:
      color = 'error'
      icon = <ErrorOutline />
      break
  }
  return (
    <Tooltip title={text}>
      <Chip icon={icon} color={color} size="small" />
    </Tooltip>
  )
}

export const formatQuotationStatus = (state: number, stateLabel: string, id: number) => {
  let color: 'primary' | 'error' | 'success' | 'warning' | 'disabled'
  switch (Number(state)) {
    case 1:
      color = 'primary'
      break
    case 2:
      color = 'success'
      break
    case 3:
      color = 'error'
      break
    case 0:
      color = 'warning'
      break
    default:
      color = 'disabled'
  }

  return (
    <Box display="flex" alignItems={'center'}>
      <Tooltip title={stateLabel} sx={{ marginRight: 4 }}>
        <CircleRounded fontSize="small" color={color} />
      </Tooltip>
      {id}
    </Box>
  )
}

export const formatInvoiceStatusForDetails = (value: number, text: string) => {
  let color: OverridableStringUnion<
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning',
    SvgIconPropsColorOverrides
  >

  switch (Number(value)) {
    case 2:
      color = 'primary'
      break
    case 3:
      color = 'secondary'
      break
    case 4:
      color = 'success'
      break
    case 5:
      color = 'warning'
      break
    case 6:
      color = 'disabled'
      break
    default:
      color = 'error'
      break
  }
  return (
    <Tooltip title={text}>
      <CircleRounded color={color} />
    </Tooltip>
  )
}

export const formatOpportunityStatus = (value: string, text: string) => {
  let color:
    | 'primary'
    | 'default'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined

  switch (Number(value)) {
    case 1:
      color = 'secondary'
      break
    case 2:
      color = 'info'
      break
    case 3:
      color = 'success'
      break
    case 4:
      color = 'warning'
      break
    case 5:
      color = 'error'
      break
    default:
      color = undefined
  }

  return (
    <Tooltip title={text}>
      <CircleRounded color={color} fontSize={'small'} />
    </Tooltip>
  )
}

export const formatCreditState = (value: string, text: string) => {
  let color: 'primary' | 'inherit' | 'secondary' | 'error' | 'info' | 'success' | 'warning'

  switch (Number(value)) {
    case 1:
      color = 'secondary'
      break
    case 2:
      color = 'info'
      break
    case 3:
      color = 'inherit'
      break
    case 4:
      color = 'success'
      break
    case 5:
      color = 'warning'
      break
    default:
      color = 'error'
  }

  return (
    <Tooltip title={text}>
      <CircleRounded color={color} fontSize={'small'} />
    </Tooltip>
  )
}

export const formatDocumentStatus = (value: string, text: string) => {
  let color:
    | 'primary'
    | 'default'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined

  switch (Number(value)) {
    case 1:
      color = 'primary'
      break
    case 2:
      color = 'info'
      break
    case 3:
      color = 'success'
      break
    case 4:
      color = 'warning'
      break
    case 5:
      color = 'error'
      break
    default:
      color = undefined
  }

  return (
    <Tooltip title={text}>
      <CircleRounded color={color} fontSize="small" />
    </Tooltip>
  )
}

export const formatEventStatus = function (value: string, text: string) {
  let color:
    | 'primary'
    | 'default'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined

  switch (Number(value)) {
    case 1:
      color = 'warning'
      break
    case 2:
      color = 'info'
      break
    case 3:
      color = 'error'
      break
    case 4:
      color = 'success'
      break
    case 5:
      color = 'primary'
      break
    default:
      color = undefined
  }

  return (
    <>
      <Tooltip title={text}>
        <CircleRounded color={color} fontSize="small" />
      </Tooltip>
    </>
  )
}

export const formatBeginWithLate = function (late: string, date: string, status: string) {
  return (
    <>
      {late === '1' && String(status) === '1' && (
        <ClockIcon sx={{ fontSize: '1rem', verticalAlign: 'middle' }} color={'warning'} />
      )}
      {late === '2' && String(status) === '1' && (
        <ClockIcon sx={{ fontSize: '1rem', verticalAlign: 'middle' }} color={'error'} />
      )}
      {formatDateWithTime(date)}
    </>
  )
}

export const formatAsChip = (value: string) => <Chip label={value} size={'small'} />

export const formatPaymentStatus = (value: number, text: string) => {
  let color:
    | 'primary'
    | 'action'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined

  switch (Number(value)) {
    case 1:
      color = 'success'
      break
    case 2:
    case 8:
      color = 'action'
      break
    case 3:
      color = 'info'
      break
    case 4:
      color = 'secondary'
      break
    case 5:
    case 11:
      color = 'warning'
      break
    case 6:
    case 7:
    case 9:
    case 10:
      color = 'error'
      break
    default:
      color = 'primary'
      break
  }
  return (
    <Tooltip title={text}>
      <Circle fontSize="small" color={color} />
    </Tooltip>
  )
}

export const formatPaymentMode = (value: number, text: string) => {
  let icon
  switch (Number(value)) {
    case 1: //check
      icon = <PriceCheck />
      break
    case 2: //bank transfer
      icon = <AccountBalance />
      break
    case 3: //withdraw
      icon = <Payment />
      break
    case 4: //bank card
      icon = <CreditCardOff />
      break
    case 5: //tpe
      icon = <PointOfSale />
      break
    default:
      icon = <QuestionMark />
      break
  }
  return <Tooltip title={text}>{icon}</Tooltip>
}

export const formatClient = function (value: string, text: string) {
  let icon = null
  if (text === 'enterprise') {
    icon = <Business />
  } else if (text === 'individual') {
    icon = <People />
  }

  return (
    <>
      {icon} {value}
    </>
  )
}

export const formatAccountingStatus = function (value: string, text: string) {
  return (
    <Tooltip title={text}>
      <Circle fontSize="small" color={value === '1' ? 'success' : 'error'} />
    </Tooltip>
  )
}

export const formatCurrencyDifference = function (value: number, by: string) {
  return formatCurrency(Number(value) - Number(by))
}

export const formatVisorAccess = function (type: number, bookingKey: string | null) {
  if (type !== 4 && type !== 5) {
    return
  }

  return bookingKey !== null ? (
    <Chip label={'OK'} color={'success'} icon={<Check />} size="small" />
  ) : (
    <Chip label={'KO'} color={'error'} icon={<Close />} size="small" />
  )
}

export const formatFullName = function (firstname: string, lastname: string) {
  return `${firstname} ${lastname}`
}

export const formatDiscountStatus = function (id: number, status: string) {
  let color = 'disabled' as 'success' | 'error' | 'disabled' | 'warning'
  let title = 'discount_state_other'
  switch (status) {
    case 'active':
      color = 'success'
      title = 'discount_state_inprogress'
      break
    case 'inactive':
      color = 'error'
      title = 'discount_state_terminated'
      break
    case 'future':
      color = 'warning'
      title = 'discount_state_future'
      break
  }

  return (
    <Stack gap={1} direction={'row'}>
      <Tooltip title={i18n.t(title)}>
        <CircleRounded fontSize={'small'} color={color} />
      </Tooltip>
      {id}
    </Stack>
  )
}

export const formatAcquisitionSource = function (value: string) {
  return value === 'unknown' ? i18n.t('unknown_source') : value
}

export const formatCenterAcquisition = function (value: string) {
  return value === 'unknown' ? i18n.t('unknown_center') : value
}

const formatZodIssue = (issue: ZodIssue): string => {
  const { path, message } = issue
  const pathString = path.join('.')

  return `${pathString}: ${message}`
}

export const formatZodError = (error: ZodError): string => {
  const { issues } = error
  if (issues.length) {
    const currentIssue = issues[0]
    return formatZodIssue(currentIssue)
  }
  return ''
}

type isImageAssociatedItem = Main | ServiceType
type ImageAssociatedOptionKey =
  | 'images_associated'
  | 'images_associated_service_type'
  | 'no_image_associated'
type ImageAssociatedOptions = {
  [key in ImageAssociatedOptionKey]: {
    title: string
    actionText: string
    severity: AlertColor
  }
}

const isImageAssociatedOptions: ImageAssociatedOptions = {
  images_associated: {
    title: 'images_associated',
    severity: 'success',
    actionText: 'manage'
  },
  images_associated_service_type: {
    title: 'images_associated_service_type',
    severity: 'info',
    actionText: 'add'
  },
  no_image_associated: {
    title: 'no_image_associated',
    severity: 'error',
    actionText: 'add'
  }
}

const getOptionKey = (item: isImageAssociatedItem): ImageAssociatedOptionKey => {
  if (item.isImageAssociated == '1') return 'images_associated'
  if ('isServiceTypeImageAssociated' in item && item.isServiceTypeImageAssociated == '1')
    return 'images_associated_service_type'
  return 'no_image_associated'
}

export function isImageAssociated<T extends isImageAssociatedItem>(
  item: T,
  handleOpenDialog: (item: T) => void
) {
  const data = isImageAssociatedOptions[getOptionKey(item)]

  return (
    <Alert
      severity={data.severity}
      sx={{
        width: 'fit-content',
        fontSize: 12,
        alignItems: 'center',
        '& .MuiAlert-message': {
          whiteSpace: 'initial',
          width: 135
        },
        '& .MuiAlert-icon': { fontSize: 18 },
        '& > div': { paddingBlock: 0 }
      }}
      action={
        <Button
          variant="contained"
          size="small"
          onClick={() => handleOpenDialog(item)}
          sx={{ width: 64 }}
        >
          {i18n.t(data.actionText)}
        </Button>
      }
    >
      {i18n.t(data.title)}
    </Alert>
  )
}
