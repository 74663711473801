import { Box, Card, CardContent, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApexOptions } from 'apexcharts'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import dayjs from 'dayjs'
import { ThemedChart } from 'app/components/charts/timeline.component'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import getLocales from 'app/utils/apexlocales.util'
import { formatCurrency } from 'app/utils/format'

export const PricePlaceParkingComponent = ({ params }: { params: URLSearchParams }) => {
  const [locales, setLocales] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const [rows, setRows] = useState<Array<{ name: string; data: number[] }>>([])
  const { handleMutation } = useFeedback()
  const { getStatsPricePlaceParking } = useFetcher()

  const labels = useMemo(() => {
    let startDate = dayjs().startOf('month')
    const dateBegin = params.get('date_begin')
    const dateEnd = params.get('date_end')
    if (dateBegin !== null) {
      startDate = dayjs(dateBegin).startOf('month')
    }

    const diffBetweenDates = dayjs(dateEnd).diff(dayjs(dateBegin), 'month')
    const dates = []
    for (let i = 0; i <= diffBetweenDates; i++) {
      const formattedDate = startDate.add(i, 'month').format('YYYY-MM-DD')
      dates.push(formattedDate)
    }
    return dates
  }, [params])

  const refreshList = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getStatsPricePlaceParking,
      onSuccess: (data) => {
        const formattedData = [
          {
            name: t('average_price_of_a_parking_space'),
            data: Object.values(data).map((item) => item.averageServicePrice)
          },
          {
            name: t('price_parking_occupied'),
            data: Object.values(data).map((item) => item.averageContractServicePrice)
          }
        ]
        setRows(formattedData)
      },
      onEnd: () => setIsLoading(false)
    })
  }, [getStatsPricePlaceParking, handleMutation])

  useEffect(() => {
    refreshList().then()
  }, [params])

  useEffect(() => {
    getLocales().then((locales: any) => setLocales(locales))
  }, [i18n.language])

  const options = useMemo(() => {
    const data: ApexOptions = {
      chart: {
        toolbar: {
          show: false
        },
        stacked: false,
        locales: [locales],
        defaultLocale: i18n.language
      },
      colors: ['#F86624', '#4CAF50'],
      stroke: {
        width: 3
      },
      xaxis: {
        type: 'category',
        categories: labels,
        tickAmount: labels.length,
        labels: {
          rotate: -45,
          rotateAlways: true,
          formatter: function (value) {
            return dayjs(value).format('MMM YY')
          }
        },
        tooltip: {
          enabled: false
        }
      },
      tooltip: {
        x: {
          formatter: function (val) {
            return dayjs(labels[val - 1]).format('MMMM YYYY')
          }
        },
        y: {
          title: {
            formatter: function (val) {
              return val + ' :'
            }
          }
        }
      },
      markers: {
        size: 3,
        hover: {
          size: 4
        }
      },
      yaxis: {
        min: 0,
        labels: {
          formatter: (val: number) => {
            return formatCurrency(val)
          }
        },
        title: {
          text: t('amount')
        }
      },
      legend: {
        position: 'top'
      },
      dataLabels: {
        enabled: false
      },
      theme: {
        palette: 'palette5',
        mode: theme.palette.mode
      }
    }
    return data
  }, [locales, labels, t, theme.palette.mode])

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardContent>
        {isLoading ? (
          <CardSkeleton />
        ) : (
          <Box className="line">
            <ThemedChart options={options} series={rows} type="line" />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
