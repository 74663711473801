import { type Ctx, fetcher, fetcherParse, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { staffsListAttributionSchema, type AttributionList } from 'api/models'

export const attributionsRouter = ({ token }: Ctx) => ({
  list: async (params: string) => {
    return await fetcherParse({
      url: `${BASE_URL}${API.ATTRIBUTION_STAFFS}${formatParams(params)}`,
      method: 'GET',
      token: token,
      schema: staffsListAttributionSchema
    })
  },
  logsList: async (params: string) =>
    fetcher<AttributionList>({
      url: `${BASE_URL}${API.ATTRIBUTIONS_LOGS}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
})
