import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  CenterDiscountsList,
  CenterDiscountsNotActive,
  CreateCenterDataDiscount,
  CreateCenterDiscount
} from 'api/models/center_discounts'

export const centerDiscountRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<CenterDiscountsList>({
      url: `${BASE_URL}${API.CENTER_DISCOUNTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  notActive: async () =>
    fetcher<CenterDiscountsNotActive>({
      url: `${BASE_URL}${API.CENTER_DISCOUNTS_NOT_ACTIVE}`,
      method: 'GET',
      token: token
    }),
  create: async (data: CreateCenterDataDiscount) =>
    fetcher<CreateCenterDiscount>({
      url: `${BASE_URL}${API.CENTER_DISCOUNTS}`,
      method: 'POST',
      token: token,
      body: data
    })
})
