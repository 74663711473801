import { z, ZodType } from 'zod'
import {
  DefaultError,
  InfiniteData,
  QueryKey,
  UseInfiniteQueryOptions
} from '@tanstack/react-query'

export const itemListSchema = (itemSchema: ZodType) =>
  z.object({
    total: z.number(),
    actual: z.number(),
    last: z.boolean(),
    items: z.array(itemSchema)
  })

export type ListType<T> = {
  total: number
  actual: number
  last: boolean
  items: T[]
}

const anyListSchema = itemListSchema(z.any())
type AnyListType = z.infer<typeof anyListSchema>

export function infiniteQueryDefaultParams<T extends AnyListType>(searchParams: URLSearchParams) {
  const params: Pick<
    UseInfiniteQueryOptions<T, DefaultError, InfiniteData<T>, T, QueryKey, number>,
    'getNextPageParam' | 'initialPageParam'
  > = {
    getNextPageParam(lastPage) {
      return lastPage.last ? undefined : lastPage.actual
    },
    initialPageParam: parseInt(searchParams.get('offset') ?? '0')
  }

  return params
}

const idLabelItemSchema = z.object({
  id: z.number(),
  label: z.string()
})

export type IdLabelItem = z.infer<typeof idLabelItemSchema>

const idLabelItemsSchema = z.array(idLabelItemSchema)
export type IdLabelItems = z.infer<typeof idLabelItemsSchema>
