import {
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  FormControl,
  Grid,
  InputLabel
} from '@mui/material'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { KeyboardArrowDown } from '@mui/icons-material'
import { FormCard } from 'app/components/form/form-card.component'
import { useForm, FormProvider } from 'react-hook-form'
import {
  Activities,
  Activity,
  CreditDeclarePayment,
  CreditInvoice,
  CreditPayment,
  DeclarePaymentSchema,
  FormItems
} from 'api/models'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  formatCurrency,
  formatDate,
  formatInvoiceStatus,
  formatPaymentStatus
} from 'app/utils/format'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { useTranslation } from 'react-i18next'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { API } from 'api/constants'
import { ActivitiesContainer } from 'modules/activities/components/activities'

import dayjs from 'dayjs'
interface IProps {
  dataFilters: FormItems
  isLoading: boolean
  invoiceSelectValues: { id: string; value: string }[]
  translation: any
  isPaymentAdd: boolean
  isEditable: boolean
  isPayable: boolean
  invoicesValues: CreditInvoice[]
  paymentsValues: CreditPayment[]
  setRefresh: Dispatch<SetStateAction<boolean>>
  activityTypes: any
}

export const ActionsComponent = ({
  dataFilters,
  isLoading,
  invoiceSelectValues,
  translation,
  isPaymentAdd,
  isPayable,
  isEditable,
  invoicesValues,
  paymentsValues,
  setRefresh,
  activityTypes
}: IProps) => {
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const [invoiceId, setInvoiceId] = useState('')
  const [expanded, setExpanded] = useState<string | false>('panel1')
  const [dataActivities, setDataActivities] = useState<Activities>({} as Activities)
  const [isLoadingActivities, setIsLoadingActivities] = useState<boolean>(true)
  const [activityType, setActivityType] = useState<string>('')
  const { updateCreditApplyInvoice, searchParams, addCreditDeclarePayment, getCreditActivities } =
    useFetcher()
  const [invoicesMap, setInvoicesMap] = useState<Map<string, any>>(new Map())
  const [paymentsMap, setPaymentsMap] = useState<Map<string, any>>(new Map())

  const onApplyInvoice = useCallback(async (invoiceId: string) => {
    await handleMutation({
      confirm: {
        content: t('confirm_apply_invoice')
      },
      mutation: updateCreditApplyInvoice,
      data: { invoiceId: invoiceId, id: String(searchParams.get('id')) },
      toastSuccess: t('credit_apply_invoice_success'),
      toastError: t('error'),
      onEnd: () => setRefresh((prevState: boolean) => !prevState)
    })
  }, [])

  const onDeclarePayment = useCallback(async (data: any) => {
    data.id = String(searchParams.get('id'))

    await handleMutation({
      confirm: {
        content: t('confirm_declare_payment')
      },
      mutation: addCreditDeclarePayment,
      data: data,
      toastSuccess: t('credit_declare_payment_success'),
      toastError: t('error'),
      onEnd: () => setRefresh((prevState: boolean) => !prevState)
    })
  }, [])

  const handleActivitiesChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  const methods = useForm<CreditDeclarePayment>({
    mode: 'onChange',
    resolver: zodResolver(DeclarePaymentSchema),
    defaultValues: {
      payedDate: dayjs()
    }
  })

  useEffect(() => {
    setIsLoadingActivities(true)
    getDataActivities().then(() => setIsLoadingActivities(false))
  }, [activityType])

  const getDataActivities = useCallback(async (): Promise<void> => {
    const response = await getCreditActivities.mutateAsync(activityType)
    setDataActivities(response)
  }, [activityType])

  useEffect(() => {
    if (invoicesValues.length > 0) {
      setInvoicesMap(() => {
        const map = new Map()
        map.set('items', [
          {
            label: translation('status').toUpperCase(),
            component: (row: any) => formatInvoiceStatus(row.status, row.statusLabel)
          },
          {
            label: translation('reference').toUpperCase(),
            value: 'reference',
            link: API.INVOICES_INFORMATIONS
          },
          { label: translation('date').toUpperCase(), value: 'createdAt', format: formatDate },
          {
            label: translation('amount').toUpperCase() + ' €',
            value: 'totalCost',
            format: formatCurrency
          },
          {
            label: translation('amount_apply').toUpperCase() + ' €',
            value: 'applyAmount',
            format: formatCurrency
          },
          {
            label: translation('due_amount').toUpperCase() + ' €',
            value: 'dueAmount',
            format: formatCurrency
          }
        ])
        map.set('data', invoicesValues)
        return map
      })
    }
  }, [invoicesValues])

  const handleChange = (response: Activity) => {
    setDataActivities((prev) => {
      return {
        ...prev,
        activities: [
          {
            type: response.type,
            message: response.message,
            createdAt: response.createdAt,
            staff: response.staff
          },
          ...prev.activities
        ]
      }
    })
  }

  useEffect(() => {
    if (paymentsValues.length > 0) {
      setPaymentsMap(() => {
        const map = new Map()
        map.set('items', [
          {
            label: translation('status').toUpperCase(),
            component: (row: any) => formatPaymentStatus(row.status, row.statusLabel)
          },
          { label: translation('reference').toUpperCase(), value: 'reference' },
          {
            label: translation('created_at').toUpperCase(),
            value: 'createdAt',
            format: formatDate
          },
          {
            label: translation('amount').toUpperCase() + ' €',
            value: 'amount',
            format: formatCurrency
          }
        ])
        map.set('data', paymentsValues)
        return map
      })
    }
  }, [paymentsValues])

  return (
    <>
      {isEditable && (
        <Accordion sx={{ paddingX: '1rem' }}>
          <AccordionSummary
            expandIcon={<KeyboardArrowDown />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{translation('apply_invoice')}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ marginBottom: '20px' }}>
            {invoiceSelectValues.length === 1 && invoiceSelectValues[0].id === '' ? (
              <Typography>{invoiceSelectValues[0].value}</Typography>
            ) : (
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel>{translation('select_invoice')}</InputLabel>
                    <Select
                      size="small"
                      value={invoiceId}
                      onChange={(event: any) => setInvoiceId(event.target.value)}
                      label={translation('select_invoice')}
                    >
                      <MenuItem value="">{translation('select_invoice')}</MenuItem>
                      {invoiceSelectValues.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={invoiceId === ''}
                    onClick={() => onApplyInvoice(invoiceId)}
                  >
                    {translation('apply')}
                  </Button>
                </Grid>
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {isPaymentAdd && (
        <Accordion sx={{ paddingX: '1rem' }}>
          <AccordionSummary
            expandIcon={<KeyboardArrowDown />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{translation('report_payments')}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ marginBottom: '20px' }}>
            {isPaymentAdd && isPayable ? (
              <FormProvider {...methods}>
                <FormCard
                  isLoading={isLoading}
                  control={methods.control}
                  items={[
                    {
                      type: 'select',
                      label: translation('payment_mode_label'),
                      name: 'paymentMode',
                      formItem: dataFilters.credit_payment_mode,
                      required: true
                    },
                    {
                      type: 'number',
                      label: translation('amount'),
                      name: 'amount',
                      required: true
                    },
                    {
                      type: 'textfield',
                      label: translation('reference'),
                      name: 'reference',
                      required: true
                    },
                    {
                      type: 'datepicker',
                      label: translation('paid_at'),
                      name: 'payedDate',
                      required: true
                    }
                  ]}
                />
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={methods.control.handleSubmit(onDeclarePayment)}
                >
                  {translation('save')}
                </Button>
              </FormProvider>
            ) : (
              <Typography>{translation('credit_sold_not_allowed')}</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion sx={{ paddingX: '1rem' }}>
        <AccordionSummary
          expandIcon={<KeyboardArrowDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{translation('invoice_list_applied')}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ marginBottom: '20px' }}>
          {invoicesMap.size === 0 ? (
            <Typography>{translation('invoice_not_found')}</Typography>
          ) : (
            <ListSheetComponent isLoading={false} data={invoicesMap} />
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ paddingX: '1rem' }}>
        <AccordionSummary
          expandIcon={<KeyboardArrowDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{translation('payment_list_applied')}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ marginBottom: '20px' }}>
          {paymentsMap.size === 0 ? (
            <Typography>{translation('payment_not_found')}</Typography>
          ) : (
            <ListSheetComponent isLoading={false} data={paymentsMap} />
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleActivitiesChange('panel1')}
        sx={{ paddingX: '1rem' }}
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{translation('internal_notes')}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ marginBottom: '20px' }}>
          <ActivitiesContainer
            isLoading={isLoadingActivities}
            activityTypes={activityTypes}
            activities={dataActivities.activities}
            data={{ credit: String(searchParams.get('id')) }}
            onChange={handleChange}
            onTypeChange={(type: any) => setActivityType(type)}
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}
