import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import { ClientLinkList, FormItem, FormItems } from 'api/models'
import {
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material'
import { Link } from 'app/components/link.component'
import { useTranslation } from 'react-i18next'
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { formatCurrency } from 'app/utils/format'
import dayjs from 'dayjs'
import { DeleteForever } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'
import { ControlledSelectField } from 'app/components/form/controlled-select.component'
import { zodResolver } from '@hookform/resolvers/zod'
import { ControlledTextField } from 'app/components/form/controlled-textfield.component'
import { FormList } from 'app/components/form/form-list.component'
import { useFeedback } from 'app/providers/feedback.provider'
import {
  ConsumptionItem,
  ConsumptionItemForm,
  FormProps,
  formSchema
} from 'api/models/forms/consumptions'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'

const initialSelector = {
  service: '',
  consumptionId: ''
}

type ClientInfo = {
  labelledName: string
  id: number
  isEnterprise: boolean
}

export const ConsumptionAddView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    getFormItems,
    getClientContracts,
    getClientConsumptions,
    createContractServices,
    createContract,
    getEnterpriseMembers,
    getIndividual,
    searchParams
  } = useFetcher()
  const [client, setClient] = useState({} as ClientInfo)
  const [members, setMembers] = useState([] as ClientLinkList)
  const { enterpriseId, individualId } = useParams()
  const [contracts, setContracts] = useState([] as FormItem['values'])
  const { toast, handleMutation } = useFeedback()
  const navigate = useNavigate()
  const [selector, setSelector] = useState(initialSelector)
  const { Accent } = useApp()
  const [formItems, setFormItems] = useState({} as FormItems)
  const [consumptionsTypes, setConsumptionsTypes] = useState([] as ConsumptionItem[])
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['consumption_types', 'type'],
      ['centers', 'center']
    ])
  )
  const methods = useForm<FormProps>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    values: {
      contractId: Number(searchParams.get('contract')) || 0,
      owner: 0,
      client: 0,
      center: Number(searchParams.get('center')) || 0,
      items: []
    }
  })
  const { append, remove } = useFieldArray({
    control: methods.control,
    name: 'items'
  })
  const items = useWatch({ name: 'items', control: methods.control })
  const selects = useWatch({ name: ['center', 'owner', 'client'], control: methods.control })

  const getConsumptionService = useCallback(
    (event: SelectChangeEvent) => {
      setSelector({ consumptionId: '', service: event.target.value })
      setConsumptionsTypes([])
      const values = methods.getValues()
      getClientConsumptions
        .mutateAsync({
          serviceTypeReference: String(event.target.value),
          clientId: values.client,
          center: values.center
        })
        .then((data: any) => {
          setConsumptionsTypes(data)
          setSelector({
            consumptionId: data.length > 0 ? data[0].id : '',
            service: event.target.value
          })
        })
    },
    [methods, selector, getClientConsumptions, setConsumptionsTypes]
  )

  const refreshContracts = useCallback(async () => {
    const values = methods.getValues()
    if (!values.center || !values.owner || !values.client) return
    getClientContracts
      .mutateAsync({ clientId: values.client, centerId: values.center, individualId: values.owner })
      .then((data) => {
        let contracts: FormItem['values'] = []
        let hasConso = false

        if (data?.items?.length > 0) {
          contracts = data.items.map((contract) => {
            let label = contract.reference
            if (String(contract.type) === '5') {
              hasConso = true
              label += ` (${contract.typeLabel})`
            }
            return {
              label: label,
              id: contract.id
            }
          })
        }

        if (!hasConso) {
          contracts.push({
            label: t('consumption_contract'),
            id: '-1'
          })
        }

        setContracts(contracts)
        //Si contract type = 5 dans la liste alors pas d'ajout du -1 sinon oui
        //Et ajouter entre parenthèse ( Consommation ) si type 5

        methods.setValue(
          'contractId',
          searchParams.get('contract') !== null
            ? Number(searchParams.get('contract'))
            : data.items.length > 0
            ? Number(data.items[0].id)
            : -1
        )
      })
  }, [getClientContracts, setContracts, methods])

  const { Enterprises } = useFetcherV2()

  const init = useCallback(
    async (commonOptions: Map<string, string>) => {
      try {
        const optionsData = await getFormItems.mutateAsync(Array.from(commonOptions.keys() as any))
        setFormItems(optionsData as FormItems)

        if (enterpriseId) {
          const client = await Enterprises.getOne(String(enterpriseId))
          const data = await getEnterpriseMembers.mutateAsync(String(enterpriseId))
          setClient({ labelledName: client.name, isEnterprise: true, id: client.id })
          setMembers(data.items)
          methods.setValue('client', client.id)
          methods.setValue(
            'center',
            searchParams.get('center') ? Number(searchParams.get('center')) : client.mainCenter
          )
          methods.setValue('owner', data.items[0].individualId)
        } else if (individualId) {
          const client = await getIndividual.mutateAsync(String(individualId))
          setClient({ labelledName: client.labelledName, isEnterprise: false, id: client.id })
          methods.setValue('client', client.id)
          methods.setValue(
            'center',
            searchParams.get('center') ? Number(searchParams.get('center')) : client.mainCenter
          )
          methods.setValue('owner', client.id)
        }

        await refreshContracts()
      } catch {
        toast({
          message: 'error_retrieving_data',
          variant: 'error'
        })
      }
    },
    [
      methods,
      getEnterpriseMembers,
      getIndividual,
      getFormItems,
      enterpriseId,
      individualId,
      refreshContracts,
      searchParams
    ]
  )

  const handleSubmit = async (data: FormProps) => {
    if (data.contractId === -1) {
      await handleMutation({
        confirm: {
          content: t('confirm_create_consumption')
        },
        mutation: createContract,
        data: data,
        toastSuccess: t('success_create_consumption'),
        toastError: t('error_create_consumption'),
        onSuccess: (res) => {
          if (res.id) {
            navigate(`/consumption-contracts/${res.id}`)
          }
        }
      })
    } else {
      await handleMutation({
        confirm: {
          content: t('confirm_create_consumption')
        },
        mutation: createContractServices,
        data: { id: data.contractId, params: data },
        toastSuccess: t('success_create_consumption'),
        toastError: t('error_create_consumption'),
        onSuccess: (res) => {
          if (res.id) {
            navigate(`/consumption-contracts/${res.id}`)
          }
        }
      })
    }
  }

  const addConsumptionService = () => {
    const consumption = consumptionsTypes.find(
      (c: ConsumptionItem) => c.id === selector.consumptionId
    )

    if (consumption) {
      append({
        isFreePrice: consumption.isFreePrice,
        serviceType: selector.service,
        isFreeFees: consumption.isFreeFees,
        customprice: Number(consumption.dailyPrice ?? consumption.price ?? 0),
        customcomment: selector.service === 'CUSTOM' ? '' : undefined,
        customlabel: selector.service === 'CUSTOM' ? '' : undefined,
        customisrecurent: undefined,
        reduction: 0,
        label: consumption.label ?? '',
        quantity: 1,
        begin: dayjs.utc().hour(9).startOf('hour'),
        end: dayjs.utc().hour(17).startOf('hour'),
        fees: consumption.isFreeFees === '1' ? 0 : Number(consumption.commissioningFees),
        price: consumption.dailyPrice ?? consumption.price ?? '0',
        service: consumption.id,
        consumption: consumption
      })
    }
  }

  useEffect(() => {
    refreshContracts().then()
  }, [selects])

  useEffect(() => {
    for (let index in items) {
      if (items[index].price !== getPrice(items[index])) {
        methods.setValue(`items.${Number(index)}.price`, getPrice(items[index]))
      }
    }
  }, [methods, items])

  useEffect(() => {
    init(commonOptions)
  }, [])

  const totalFees = useMemo((): string => {
    return formatCurrency(
      items.reduce((acc, item) => {
        return acc + Number(item.fees)
      }, 0)
    )
  }, [items])

  const total = useMemo((): string => {
    return formatCurrency(
      items.reduce((acc, item) => {
        return acc + Number(item.price)
      }, 0)
    )
  }, [items])

  const getPrice = useCallback((field: ConsumptionItemForm) => {
    const diff = field.end.diff(field.begin, 'hours')
    let price = field.customprice
    if (field.serviceType === 'COWORKING' && diff > 0) {
      if (diff < 4) {
        price = field.consumption.hourlyPrice * diff
      } else if (diff < 5) {
        price = field.consumption.halfdayPrice
      } else {
        price = field.consumption.dailyPrice * Math.ceil(diff / 24)
      }
    }
    return String(price * (1 - field.reduction / 100) * field.quantity)
  }, [])

  const cellLabel = useCallback(
    (field: ConsumptionItemForm, index: number) => {
      return field.serviceType === 'CUSTOM' ? (
        <ControlledTextField
          control={methods.control}
          name={`items.${index}.customlabel`}
          type={'text'}
        />
      ) : (
        field.label
      )
    },
    [methods]
  )

  const cellFees = useCallback(
    (field: ConsumptionItemForm, index: number) => {
      return field.isFreeFees === '1' ? (
        <ControlledTextField control={methods.control} name={`items.${index}.fees`} type={'text'} />
      ) : (
        <Typography>{formatCurrency(String(items[index]?.fees))}</Typography>
      )
    },
    [items, methods]
  )

  const cellPrice = useCallback(
    (field: ConsumptionItemForm, index: number) => {
      return (
        <>
          {field.isFreePrice === '1' && (
            <ControlledTextField
              control={methods.control}
              name={`items.${index}.customprice`}
              type={'text'}
            />
          )}
          <Typography>{formatCurrency(items[index] ? items[index].price : field.price)}</Typography>
        </>
      )
    },
    [methods, items]
  )

  const removeItem = useCallback(
    (_: ConsumptionItemForm, index: number) => remove(index),
    [items, remove]
  )
  const onConsumptionTypeChange = useCallback(
    (event: SelectChangeEvent) => setSelector({ ...selector, consumptionId: event.target.value }),
    [setSelector, selector]
  )

  return (
    <Container>
      <Grid container columns={12} sx={{ marginBottom: 4 }}>
        <Grid item xs={12}>
          <Typography variant="h1">
            {t('add_consumption')} |{' '}
            {
              <Link to={`/${client.isEnterprise ? 'enterprises' : 'individuals'}/${client.id}`}>
                {client.labelledName}
              </Link>
            }
          </Typography>
        </Grid>
      </Grid>

      <FormProvider {...methods}>
        <Grid item xs={11}>
          <Grid container columns={12} columnSpacing={4} sx={{ marginBottom: 4 }}>
            <Grid item xs={4}>
              <ControlledSelectField
                name={'center'}
                control={methods.control}
                label={t('center')}
                formItem={formItems.centers}
              />
            </Grid>
            <Grid item xs={4}>
              <ControlledSelectField
                formItem={{
                  values: contracts
                }}
                label={t('contract')}
                name={'contractId'}
                control={methods.control}
              />
            </Grid>
            {members.length > 1 && (
              <Grid item xs={4}>
                <ControlledSelectField
                  formItem={{
                    values: members.map((member) => ({
                      label: member.firstname + ' ' + member.lastname,
                      id: member.individualId
                    }))
                  }}
                  label={t('owner')}
                  name={'owner'}
                  control={methods.control}
                />
              </Grid>
            )}
          </Grid>
          <Paper sx={{ padding: 4, marginBottom: 4 }}>
            <Grid container columns={12} columnSpacing={4}>
              <Grid item xs={4}>
                <FormControl size={'small'} fullWidth>
                  <InputLabel>{t('service_type')}</InputLabel>
                  <Select
                    label={t('service_category')}
                    value={selector.service}
                    onChange={getConsumptionService}
                  >
                    {formItems.consumption_types?.values.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl size={'small'} fullWidth>
                  <InputLabel>{t('consumption_type')}</InputLabel>
                  <Select
                    size={'small'}
                    label={t('consumption_type')}
                    fullWidth
                    value={selector.consumptionId}
                    onChange={onConsumptionTypeChange}
                  >
                    {consumptionsTypes.map((item: { id: string; label: string }) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <Button variant={'contained'} onClick={addConsumptionService}>
                  {t('add_service')}
                </Button>
              </Grid>
            </Grid>
            <Divider sx={{ marginY: 4 }} />
            <Grid container columns={24} alignItems={'center'}>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  <Accent>{t('fees')}:</Accent> {totalFees}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  <Accent>{t('total')}:</Accent> {total}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Button
                  type="submit"
                  size={'small'}
                  disabled={
                    !methods.formState.isValid ||
                    methods.formState.isSubmitting ||
                    items.length == 0
                  }
                  onClick={methods.handleSubmit(handleSubmit)}
                  variant={'contained'}
                >
                  {t('validate')}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Grid container>
              <Grid item xs={12}>
                <FormList
                  itemsName={'items'}
                  methods={methods}
                  items={methods.getValues().items}
                  rows={[
                    {
                      columns: [
                        {
                          label: t('label'),
                          slug: 'label',
                          type: 'text',
                          cellFormatter: cellLabel
                        },
                        { label: t('quantity'), slug: 'quantity', type: 'textfield' },
                        { label: t('begin'), slug: 'begin', type: 'datetime' },
                        { label: t('end'), slug: 'end', type: 'datetime', minDate: 'begin' },
                        { label: t('discount'), slug: 'reduction', type: 'textfield' },
                        { label: t('fees'), slug: 'fees', type: 'text', cellFormatter: cellFees },
                        {
                          label: t('price'),
                          slug: 'price',
                          type: 'text',
                          cellFormatter: cellPrice
                        },
                        {
                          buttonLabel: DeleteForever,
                          label: '',
                          type: 'button',
                          onClick: removeItem
                        }
                      ]
                    },
                    {
                      displayCondition: (field) => field.serviceType === 'CUSTOM',
                      columns: [
                        {
                          label: t('comment'),
                          slug: 'customcomment',
                          type: 'textfield',
                          props: { colSpan: 4 }
                        },
                        { label: t('price'), slug: 'customprice', type: 'textfield' }
                      ]
                    }
                  ]}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </FormProvider>
    </Container>
  )
}
