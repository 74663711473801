import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { Commitment } from 'api/models'
import { ListPagination } from 'app/components/lists/list-pagination'

export const CommitmentsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    initSort,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    handleSort,
    handleFilter
  } = useList()
  const { getCommitments } = useFetcher()
  const [commitments, setCommitments] = useState<Commitment[]>([])
  const { handleMutation } = useFeedback()
  const [isLoading, setIsLoading] = useState(true)

  const getData = useCallback(
    async (reset: boolean) => {
      await handleMutation({
        onStart: () => setIsLoading(true),
        mutation: getCommitments,
        onSuccess: (data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setCommitments(reset ? data.items : (prev) => [...prev, ...data.items])
        },
        onEnd: () => setIsLoading(false)
      })
    },
    [getCommitments]
  )

  useEffect(() => {
    initSort([{ property: 'id', order: 'asc' }]).then(() => getData(true).then())
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('commitments')}
        </Typography>
      </Box>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {commitments.length} / {total}
          </Typography>
        </Grid>
        <List
          items={commitments}
          columns={[
            {
              label: t('id'),
              slug: 'id'
            },
            {
              label: t('label'),
              slug: 'label'
            },
            {
              label: t('reference'),
              slug: 'reference'
            }
          ]}
          handleReset={() => handleFilter(getData, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(getData, property)}
          isLoading={isLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {commitments.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !isLoading && (
          <ListPagination handleRedirect={() => handleFilter(getData, false)} />
        )}
      </Grid>
    </Container>
  )
}
