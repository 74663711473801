import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { Box, Container, Grid, Paper as MuiPaper, Typography } from '@mui/material'
import { FiltersBox } from 'app/components/filters/filter-box'
import { List } from 'app/components/lists/list'
import { useFeedback } from 'app/providers/feedback.provider'
import { MonthlyStats } from 'api/models/stats'
import { CaVsBpLineChartComponent } from 'modules/stats/components/ca-vs-bp-line-chart.component'
import { RangePeriodPicker } from 'app/components/filters/range-period-picker'

export const StatsCaVsBpView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { setSearchParams, searchParams, getMonthlyStatsCaVsBp } = useFetcher()
  const { filtersList, orderBy, handleSort, handleFilter, initFilters } = useList()
  const { handleMutation } = useFeedback()
  const commonFilters = new Map<string, string>([['', '']])

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [originalData, setOriginalData] = useState<MonthlyStats>()

  const transformData = (data: any[]) => {
    return data.map((item) => ({
      center: item.centerName,
      to: item.to,
      toVsBP: item.toVsBp,
      caVsBP: item.caVsBp,
      deltaCAYTDvsCaBpYTD: item.caYtdVsCaBpYtd
    }))
  }

  const dataPerCenter = useMemo(
    () => (originalData ? transformData(originalData) : []),
    [originalData]
  )

  useEffect(() => {
    initFilters(commonFilters).then()
    setIsLoading(true)
    setIsLoading(false)
  }, [])

  const refreshList = useCallback(async () => {
    await handleMutation({
      onStart: () => {
        setIsLoading(true)
      },
      mutation: getMonthlyStatsCaVsBp,
      onSuccess: (data: MonthlyStats) => {
        setOriginalData(data)
      },
      onEnd: () => setIsLoading(false)
    })
  }, [searchParams])

  useEffect(() => {
    refreshList().then()
  }, [refreshList])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('dashboard_ca_vs_bp')}
        </Typography>
      </Box>
      <Grid paddingBottom={6}>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePeriodPicker
            slugStart="date_begin"
            slugEnd="date_end"
            labelStart={t('begin_date')}
            labelEnd={t('end_date')}
            onChange={() => handleFilter(async () => {}, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersBox>
      </Grid>
      <Grid container spacing={6}>
        <Grid container item spacing={6} direction="row">
          <Grid item xs={12} sm={6}>
            <MuiPaper>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingTop={6}
                paddingX={6}
              >
                <Typography variant="h3">{t('result_for_month')}</Typography>
              </Box>
              <Box padding={6} height="400px" sx={{ overflow: 'auto' }}>
                <List
                  items={dataPerCenter}
                  columns={[
                    { label: t('center'), slug: 'center' },
                    {
                      label: t('occupation_rate'),
                      slug: 'to',
                      valueFormatter: (value: string) => (Number(value) !== 0 ? `${value}%` : 'N/A')
                    },
                    {
                      label: t('Delta TO vs BP'),
                      slug: 'toVsBP',
                      valueFormatter: (value: string) => (Number(value) !== 0 ? `${value}%` : 'N/A')
                    },
                    {
                      label: t('ca_vs_bp'),
                      slug: 'deltaCAYTDvsCaBpYTD',
                      valueFormatter: (value: string) => (Number(value) !== 0 ? `${value}%` : 'N/A')
                    }
                  ]}
                  handleReset={() => handleFilter(refreshList, true)}
                  sort={orderBy}
                  handleSort={(property) => handleSort(refreshList, property)}
                  isLoading={isLoading}
                />
              </Box>
            </MuiPaper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPaper>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingTop={6}
                paddingX={6}
              >
                <Typography variant="h3">
                  {t('ca')} {t('vs')} {t('BP')}
                </Typography>
              </Box>
              <Box padding={6} height={400}>
                <CaVsBpLineChartComponent height={300} params={searchParams} />
              </Box>
            </MuiPaper>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
