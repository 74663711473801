import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { PaymentsSum, type PaymentsList, Payment } from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const paymentsRouter = ({ token }: Ctx) => ({
  read: async (id: number) =>
    fetcher<Payment>({
      url: `${BASE_URL}${urlHelper(API.PAYMENT, { id })}`,
      method: 'GET',
      token: token
    }),
  list: async (params: string) =>
    fetcher<PaymentsList>({
      url: `${BASE_URL}${API.PAYMENTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getSum: async (params: string) =>
    fetcher<PaymentsSum>({
      url: `${BASE_URL}${API.PAYMENTS_SUM}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  update: async (id: number, state: number) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PAYMENT, { id })}`,
      method: 'PATCH',
      token: token,
      body: { state: state }
    }),
  delete: async (id: number) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PAYMENT, { id })}`,
      method: 'DELETE',
      token: token
    })
})
