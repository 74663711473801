import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { FiltersBox } from 'app/components/filters/filter-box'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { useList } from 'app/providers/list.provider'
import { formatCurrency, formatDate } from 'app/utils/format'
import { useFeedback } from 'app/providers/feedback.provider'
import { CenterPrice } from 'api/models/centers'
import { useApp } from 'app/providers/app.provider'
import { Add, AddCircle } from '@mui/icons-material'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { AddCenterPriceComponent } from 'modules/centerprices/components/add-center-price.component'

export const CenterPricesView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initSort,
    handleSort,
    handleFilter
  } = useList()
  const { getRight } = useApp()
  const { getCenterPrices, searchParams, setSearchParams } = useFetcher()
  const [centerPrices, setCenterPrices] = useState<CenterPrice[]>([])
  const [centerId, setCenterId] = useState<number>(-1)
  const { handleMutation } = useFeedback()
  const { user } = useApp()
  const dialogRef = useRef<DialogRef>(null)

  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      await handleMutation({
        onStart: () => setListIsLoading(true),
        mutation: getCenterPrices,
        onSuccess: (data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setCenterPrices(reset ? data.items : (prev) => [...prev, ...data.items])
        },
        onEnd: () => setListIsLoading(false)
      })
    },
    [getCenterPrices, setIsLast, setOffset, setTotal, setCenterPrices]
  )

  const handleAddPrice = useCallback(() => {
    setCenterId(Number(searchParams.get('center')))
    dialogRef.current?.open()
  }, [dialogRef, setCenterId, searchParams])

  useEffect(() => {
    if (!user) return
    if (!searchParams.get('center')) {
      searchParams.set('center', String(user.mainCenter))
      setSearchParams(searchParams)
    }
    initSort([{ property: 'begin', order: 'desc' }]).then(() => refreshList(true).then())
  }, [user])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('center_prices')}
        </Typography>
        {(getRight('centerprice', 'isAdd') || getRight('centerprice', 'isEdit')) && (
          <AddCenterPriceComponent
            centerId={centerId}
            dialogRef={dialogRef}
            onSave={refreshList}
            onClose={() => setCenterId(-1)}
          />
        )}
        {getRight('centerprice', 'isAdd') && centerPrices.length === 0 && (
          <Box display="inline">
            <IconButton
              title={t('add_centerprice')}
              aria-label={t('add_centerprice')}
              color="primary"
              onClick={handleAddPrice}
              style={{ marginLeft: 7 }}
            >
              <AddCircle fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
      <Grid>
        <FiltersBox
          filters={{}}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <SearchCenterInput
            slug="center"
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            disableClearable={true}
            byPassAllCenters={true}
            initialValue={user?.mainCenter}
          />
        </FiltersBox>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {centerPrices.length} / {total}
          </Typography>
        </Grid>
        <List
          items={centerPrices}
          columns={[
            {
              label: t('center'),
              slug: 'center'
            },
            {
              label: `${t('price')}/${t('month')}/${t('m2')}`,
              slug: 'price',
              valueFormatter: formatCurrency
            },
            {
              label: t('begin'),
              slug: 'begin',
              valueFormatter: formatDate
            },
            {
              label: t('end'),
              slug: 'end',
              condition: (item: CenterPrice) => {
                if (item.end) return formatDate(item.end)
                return (
                  <IconButton onClick={handleAddPrice} size={'small'} color={'primary'}>
                    <Add fontSize={'small'} />
                  </IconButton>
                )
              }
            },
            {
              label: t('member'),
              slug: 'staff'
            }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {centerPrices.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
