import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { type PresenceConsumptionList } from 'api/models'
import { type PresenceConsumptionSum } from 'api/models'

export const presencesConsumptionRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<PresenceConsumptionList>({
      url: `${BASE_URL}${API.PRESENCES_CONSUMPTION}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getSum: async (params: string) =>
    fetcher<PresenceConsumptionSum>({
      url: `${BASE_URL}${API.PRESENCES_CONSUMPTION_SUM}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
})
