import { type Ctx, fetcher, fetcherParse, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { type PresenceLogsList } from 'api/models'
import { presenceScheduleListSchema } from 'api/models'

export const presencesRouter = ({ token }: Ctx) => ({
  logsList: async (params: string) =>
    fetcher<PresenceLogsList>({
      url: `${BASE_URL}${API.PRESENCES_LOGS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  schedulesList: async (params: string) =>
    fetcherParse({
      url: `${BASE_URL}${API.PRESENCES_SCHEDULE}${formatParams(params)}`,
      method: 'GET',
      token: token,
      schema: presenceScheduleListSchema
    })
})
