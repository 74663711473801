import { z } from 'zod'

const referenceEnum = z.enum([
  'access_status',
  'client_status',
  'enterprise_big_account',
  'commitment',
  'contract_type',
  'contract_status',
  'invoice_is_mandate',
  'invoice_type',
  'invoice_status',
  'payment_status',
  'individual_roles',
  'languages',
  'floors',
  'offices',
  'activity_types',
  'main_services_typologies',
  'parking_services_typologies',
  'client_centers',
  'centers',
  'rooms_services_type',
  'consumption_type',
  'staff_centers',
  'is_image_associated',
  'canals',
  'subcanals',
  'sources'
])

export const filterSchema = z.object({
  reference: referenceEnum,
  label: z.string(),
  values: z.array(
    z.object({
      id: z.string(),
      label: z.string()
    })
  ),
  multiple: z.boolean(),
  default: z.array(z.string())
})

export type Filter = z.infer<typeof filterSchema>

const filtersSchema = z.record(referenceEnum, filterSchema)

export type Filters = z.infer<typeof filtersSchema>

const filtersInput = z.array(referenceEnum)

export type FiltersInput = z.infer<typeof filtersInput>

const filterClientInputSchema = z.object({
  name: z.string().min(2),
  id: z.string().nullable().optional(),
  is_individual: z.boolean(),
  is_enterprise: z.boolean()
})

export type FilterClientInput = z.infer<typeof filterClientInputSchema>

const clientSchema = z.object({
  id: z.number(),
  text: z.string(),
  type: z.enum(['individuals', 'enterprise'])
})

export type Client = z.infer<typeof clientSchema>

const filterClientSchema = z.array(
  z.object({
    id: z.number().optional(),
    text: z.string(),
    children: clientSchema.array().optional()
  })
)

export type FilterClient = z.infer<typeof filterClientSchema>

const centerSchema = z.object({
  id: z.string(),
  text: z.string(),
  type: z.enum(['center', 'cluster'])
})

export type Center = z.infer<typeof centerSchema>

const filterCenterSchema = z.array(
  z.object({
    id: z.number().optional(),
    text: z.string(),
    children: centerSchema.array().optional()
  })
)

export type FilterCenter = z.infer<typeof filterCenterSchema>

const selectCentersSchema = z.array(
  z.object({
    id: z.number().optional(),
    text: z.string(),
    children: centerSchema.array().optional()
  })
)

export type SelectCenters = z.infer<typeof selectCentersSchema>

const filterCenterInputSchema = z.object({
  name: z.string().min(2),
  is_cluster: z.boolean(),
  is_center: z.boolean(),
  all_centers: z.boolean().optional(),
  bypass_all_centers: z.boolean().optional()
})

export type FilterCenterInput = z.infer<typeof filterCenterInputSchema>

const staffSchema = z.object({
  id: z.number(),
  text: z.string()
})

export type Staff = z.infer<typeof staffSchema>

const filterStaffSchema = z.array(
  z.object({
    id: z.number().optional(),
    text: z.string(),
    children: staffSchema.array().optional()
  })
)

export type FilterStaff = z.infer<typeof filterStaffSchema>

const filterStaffInputSchema = z.object({
  name: z.string().min(2)
})

export type FilterStaffInput = z.infer<typeof filterStaffInputSchema>

const memberFilterSchema = z.object({
  id: z.number(),
  text: z.string()
})

export type MemberFilter = z.infer<typeof memberFilterSchema>

const filterMembersSchema = z.array(
  z.object({
    id: z.number().optional(),
    text: z.string(),
    children: memberFilterSchema.array().optional()
  })
)

export type FilterMembers = z.infer<typeof filterMembersSchema>

const filterMembersInputSchema = z.object({
  id: z.string(),
  name: z.string().min(2)
})

export type FilterMemberInput = z.infer<typeof filterMembersInputSchema>

const filterSelectInputSchema = z.object({
  id: z.number().optional(),
  name: z.string().min(2).optional()
})

export type FilterSelectInput = z.infer<typeof filterSelectInputSchema>

const filterSearchSchema = z.object({
  id: z.number(),
  name: z.string()
})

export type FilterSearchItem = z.infer<typeof filterSearchSchema>

const filterSelectSearchSchema = z.array(filterSearchSchema)

export type FilterSelectSearch = z.infer<typeof filterSelectSearchSchema>

const staffCenterSchema = z.object({
  center: z.object({ id: z.number() }),
  isMain: z.boolean()
})
export type StaffCenter = z.infer<typeof staffCenterSchema>

const staffCentersSchema = z.array(staffCenterSchema)
export type StaffCenters = z.infer<typeof staffCentersSchema>
