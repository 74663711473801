import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { ClientRequestList, ClientRequestSum } from 'api/models'
import urlHelper from 'app/helpers/url.helper'
import { ClientRequestFormProps } from 'api/models/forms/client_requests'

export const clientRequestsRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<ClientRequestList>({
      url: `${BASE_URL}${API.CLIENT_REQUESTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getSum: async (params: string) =>
    fetcher<ClientRequestSum>({
      url: `${BASE_URL}${API.CLIENT_REQUESTS_SUM}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getOne: async (id: string) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.CLIENT_REQUEST, { id: id })}`,
      method: 'GET',
      token: token
    }),
  update: async (data: ClientRequestFormProps) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.CLIENT_REQUEST, { id: data.id })}`,
      method: 'PATCH',
      token: token,
      body: data
    })
})
