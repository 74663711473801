import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { OptionList } from '../models'

export const optionsRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<OptionList>({
      url: `${BASE_URL}${API.OPTIONS}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
})
