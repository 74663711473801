import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { Filters, PlanningDesktops } from 'api/models'

export const planningsRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<PlanningDesktops>({
      url: `${BASE_URL}${API.PLANNINGS_DESKTOPS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getFloors: async (params: string) =>
    fetcher<Filters>({
      url: `${BASE_URL}${API.PLANNINGS_DESKTOPS_FLOORS}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
})
