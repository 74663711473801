import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Paper as MuiPaper, Stack } from '@mui/material'
import type { ClientLink, EnterpriseInformation, FormItems, LinkIndividualProps } from 'api/models'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormList, IFormListRow } from 'app/components/form/form-list.component'
import { TitleComponent } from 'app/components/titles/title.component'
import { ClientLinkList, linkIndividualSchema } from 'api/models'
import { useApp } from 'app/providers/app.provider'
import SplitButton from 'app/components/buttons/split-button.component'
import { Edit } from '@mui/icons-material'
import { zodResolver } from '@hookform/resolvers/zod'
import LinkExistingUserForm from 'app/forms/enterprises/LinkExistingUserForm'
import { useNavigate } from 'react-router-dom'
import { ModulesEnum } from 'app/constants/modules'
import { formatYesNo } from 'app/utils/format'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useMutationFetcher } from 'app/hooks/use-mutation-fetcher'

interface IEnterpriseDocument {
  enterprise: EnterpriseInformation
  options: FormItems
}

export function EnterpriseMembersComponent({ enterprise, options }: IEnterpriseDocument) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const methods = useForm({
    values: { items: [] as ClientLinkList }
  })
  const linkUserControl = useForm<LinkIndividualProps>({
    resolver: zodResolver(linkIndividualSchema)
  })
  const { user, getModule } = useApp()
  const navigate = useNavigate()
  const [linkMode, setLinkMode] = useState<string | null>(null)
  const { Enterprises, Individuals } = useFetcherV2()
  const updateClientLink = useMutationFetcher({
    confirm: {
      content: t('confirm_update_client_link')
    },
    mutationKey: ['clients', 'links', 'update'],
    mutationFn: (data: ClientLink) => {
      return Individuals.updateClientLink({
        id: String(data.id),
        individualId: String(data.individualId),
        data: data
      })
    },
    toastSuccess: t('update_success'),
    toastError: t('an_error_occurred'),
    onSuccess: () => refreshList().then()
  })
  const refreshList = useCallback(async () => {
    setIsLoading(true)
    try {
      const data = await Enterprises.listMembers(String(enterprise.id))
      methods.setValue('items', data.items)
    } finally {
      setIsLoading(false)
    }
  }, [enterprise.id])

  const handleSave = useCallback(
    async (data: ClientLink) => {
      await updateClientLink(data)
    },
    [updateClientLink]
  )

  const rowItems = useMemo((): IFormListRow<ClientLink>[] => {
    const _module = getModule(ModulesEnum.WIFI)
    const _rows: IFormListRow<ClientLink>[] = [
      {
        columns: [
          {
            type: 'text',
            label: t('reference'),
            slug: 'reference',
            link: { base: '/individuals', slug: 'individualId' }
          },
          {
            type: 'text',
            label: t('firstname'),
            slug: 'firstname'
          },
          {
            type: 'text',
            label: t('lastname'),
            slug: 'lastname'
          },
          {
            type: 'select',
            label: t('role'),
            slug: 'role',
            formItem: options.individual_roles
          },
          {
            type: 'textfield',
            label: t('job'),
            slug: 'job'
          },
          {
            type: 'text',
            label: t('center'),
            slug: 'center'
          },
          {
            type: 'checkbox',
            label: t('on_site'),
            slug: 'isPresent'
          }
        ]
      }
    ]

    if (_module) {
      _rows[0].columns.push({
        type: 'text',
        label: t('wi-fi'),
        slug: 'hasWifi',
        valueFormatter: formatYesNo
      })
    }

    _rows[0].columns.push({
      type: 'button',
      label: '',
      buttonLabel: t('submit'),
      onClick: handleSave
    })

    return _rows
  }, [])

  const linkEnterpriseMember = useMutationFetcher({
    confirm: {
      content: t('confirm_link_client')
    },
    mutationKey: ['individuals', 'link'],
    mutationFn: ({ id, data }: { id: string; data: LinkIndividualProps }) =>
      Enterprises.linkMember(id, data),
    toastSuccess: t('update_success'),
    toastError: t('an_error_occurred'),
    onSuccess: () => {
      refreshList().then()
      setLinkMode(null)
    }
  })
  const handleLinkMember = useCallback(async (data: LinkIndividualProps) => {
    await linkEnterpriseMember({
      id: String(enterprise.id),
      data: data
    })
  }, [])

  useEffect(() => {
    refreshList().then()
  }, [refreshList])

  return (
    <MuiPaper>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <TitleComponent text={t('members')} variant={'h3'} paddingLeft={12} />
          {user?.rights.client.isEdit && (
            <SplitButton
              iconComponent={Edit}
              iconButtonOptions={{ color: 'primary', size: 'small' }}
              items={[
                {
                  key: 'link_existing_user',
                  label: t('link_existing_user'),
                  action: () => {
                    setLinkMode('link_existing_user')
                  }
                },
                {
                  key: 'add_user',
                  label: t('create_an_user'),
                  action: () => navigate(`/enterprises/${enterprise.id}/individuals/add`)
                }
              ]}
            />
          )}
        </Stack>
      </Box>
      {linkMode === 'link_existing_user' && (
        <Grid container columns={12}>
          <LinkExistingUserForm methods={linkUserControl} options={options} />
          <Grid item xs={12} textAlign={'right'} paddingX={6}>
            <Button sx={{ marginRight: 3 }} onClick={() => setLinkMode(null)}>
              {t('cancel')}
            </Button>
            <Button
              variant={'contained'}
              disabled={!linkUserControl.formState.isValid}
              onClick={linkUserControl.handleSubmit(handleLinkMember)}
            >
              {t('link')}
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid container columns={4}>
        <Grid item xs={4}>
          <FormList
            items={methods.getValues().items}
            rows={rowItems}
            methods={methods}
            isLoading={isLoading}
            itemsName={'items'}
          />
        </Grid>
      </Grid>
    </MuiPaper>
  )
}
