import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { formatDateWithTime } from 'app/utils/format'
import { FiltersBox } from 'app/components/filters/filter-box'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { useList } from 'app/providers/list.provider'
import { Happening } from 'api/models'
import { RangePicker } from 'app/components/filters/range-picker'
import { AddCircle } from '@mui/icons-material'
import { useFeedback } from 'app/providers/feedback.provider'

export const EventsCentersView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter
  } = useList()
  const { getHappeningsList, setSearchParams, searchParams } = useFetcher()
  const [events, setEvents] = useState<Happening[]>([])
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['organizations', 'organizedBy'],
      ['active_status', 'active']
    ])
  )
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const { handleMutation } = useFeedback()

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      await handleMutation({
        onStart: () => setListIsLoading(true),
        mutation: getHappeningsList,
        onSuccess: (data) => {
          setEvents((prevState) => (reset ? data.items : [...prevState, ...data.items]))
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
        },
        onEnd: () => setListIsLoading(false)
      })
    },
    [getHappeningsList, setIsLast, setOffset, setTotal, setEvents, setListIsLoading]
  )

  useEffect(() => {
    initFilters(commonOptions).then(() => refreshList(true).then())
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('client_evenment')}
          <IconButton
            title={t('add_happening')}
            aria-label={t('add_happening')}
            color="primary"
            href={'/happenings/add'}
            style={{ marginLeft: 7 }}
          >
            <AddCircle fontSize="small" />
          </IconButton>
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <SearchCenterInput
            slug="center"
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
          />
          <RangePicker
            label="Date"
            slugs={{ begin: 'begin', end: 'end' }}
            slug="date"
            onChange={refreshList}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersBox>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {events.length} / {total}
          </Typography>
        </Grid>
        <List
          items={events}
          columns={[
            {
              label: t('name'),
              slug: 'name',
              link: { base: '/happenings', slug: 'id' }
            },
            {
              label: t('center'),
              slug: 'center'
            },
            {
              label: t('begin'),
              slug: 'begin',
              valueFormatter: formatDateWithTime
            },
            {
              label: t('end'),
              slug: 'end',
              valueFormatter: formatDateWithTime
            },
            {
              label: t('publicated_at'),
              slug: 'publicatedAt',
              valueFormatter: formatDateWithTime
            },
            {
              label: t('organized_by'),
              slug: 'organizedBy'
            },
            {
              label: t('updated_at'),
              slug: 'updatedAt',
              valueFormatter: formatDateWithTime
            }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {events.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
