import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { NotificationList, NotificationUpdate } from 'api/models'
import urlHelper from 'app/helpers/url.helper'
export const notificationsRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<NotificationList>({
      url: `${BASE_URL}${API.NOTIFICATIONS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  update: async (id: number, data: NotificationUpdate) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.UPDATE_NOTIFICATION, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    })
})
