import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { BankAccount, MandatesList, PaymentMethod, Payments } from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const mandatesRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<MandatesList>({
      url: `${BASE_URL}${API.MANDATES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  paymentMethod: async (id: number) =>
    fetcher<PaymentMethod>({
      url: `${BASE_URL}${urlHelper(API.MANDATE_PAYMENT_METHOD, { id })}`,
      method: 'GET',
      token: token
    }),
  bankAccount: async (id: number) =>
    fetcher<BankAccount>({
      url: `${BASE_URL}${urlHelper(API.MANDATE_BANK_ACCOUNT, { id })}`,
      method: 'GET',
      token: token
    }),
  payments: async (id: number) =>
    fetcher<Payments>({
      url: `${BASE_URL}${urlHelper(API.MANDATE_PAYMENT, { id })}`,
      method: 'GET',
      token: token
    }),
  cancelled: async (id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.MANDATE_CANCELLED, { id })}`,
      method: 'PATCH',
      token: token
    })
})
