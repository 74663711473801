import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useQueryFetcherList } from 'app/hooks/use-query-fetcher-list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'

export const useEnterprisesList = () => {
  const { Enterprises } = useFetcherV2()
  const { searchParams } = useFetcher()
  return useQueryFetcherList({
    queryKey: ['enterprises', 'list'],
    queryFn: () => Enterprises.list(searchParams.toString())
  })
}

export const useEnterprise = (id: string) => {
  const { Enterprises } = useFetcherV2()
  return useQueryFetcher({
    queryKey: ['enterprises', 'get', id],
    queryFn: () => Enterprises.getOne(id)
  })
}
