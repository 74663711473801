import { type Ctx, fetcher } from 'api'
import { BASE_URL, API } from 'api/constants'
import { CryptResType } from 'api/models/crypt'

export const cryptRouter = ({ token }: Ctx) => ({
  decrypt: async (data: { text: String }) =>
    fetcher<CryptResType>({
      url: `${BASE_URL}${API.DECRYPT}`,
      method: 'POST',
      token: token,
      body: data
    }),
  encrypt: async (data: { text: String }) =>
    fetcher<CryptResType>({
      url: `${BASE_URL}${API.ENCRYPT}`,
      method: 'POST',
      token: token,
      body: data
    })
})
