import { useTranslation } from 'react-i18next'
import { formatCurrency } from 'app/utils/format'
import React, { useMemo } from 'react'
import { Company, CompanyConfiguration } from 'api/models/companies'
import { useApp } from 'app/providers/app.provider'
import { RolesEnum } from 'app/constants/roles'
import InfoCard from 'app/components/card/info-card.component'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'

interface ICompanyCenters {
  company: Company
}

export function CompanyConfigurationsComponent({ company }: ICompanyCenters) {
  const { t } = useTranslation()
  const { Companies } = useFetcherV2()
  const { data: configuration = {} as CompanyConfiguration, isFetching: isLoading } =
    useQueryFetcher({
      queryKey: ['companies', 'configuration', company.id],
      queryFn: () => Companies.getConfigurations(company.id)
    })
  const { isRole } = useApp()

  const columns = useMemo(() => {
    const _items = [
      {
        label: t('max_amount_direct_debit'),
        value:
          configuration.maxAmountDirectDebit !== undefined
            ? formatCurrency(configuration.maxAmountDirectDebit)
            : null
      }
    ]

    if (isRole(RolesEnum.ADMIN)) {
      _items.push({
        label: t('gc_access_token'),
        value: configuration.gcAccessToken ?? '-'
      })
      _items.push({
        label: t('stripe_public_key'),
        value: configuration.stripePublicKey ?? '-'
      })

      _items.push({
        label: t('stripe_secret_key'),
        value: configuration.stripeSecretKey ?? '-'
      })
    }

    return _items
  }, [configuration, isRole])

  return <InfoCard title={t('configuration')} columns={columns} isLoading={isLoading}></InfoCard>
}
