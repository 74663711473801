import { useTranslation } from 'react-i18next'
import { Box, Card, Grid } from '@mui/material'
import React from 'react'
import { List } from 'app/components/lists/list'
import { useList } from 'app/providers/list.provider'
import { TitleComponent } from 'app/components/titles/title.component'
import { Company, CompanyCenters } from 'api/models/companies'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'

interface ICompanyCenters {
  company: Company
}

export function CompanyCentersComponent({ company }: ICompanyCenters) {
  const { t } = useTranslation()
  const { orderBy, handleSort, handleFilter } = useList()
  const { Companies } = useFetcherV2()
  const {
    data: items = [] as CompanyCenters,
    isFetching: isLoading,
    refetch
  } = useQueryFetcher({
    queryKey: ['companies', 'bank_accounts', company.id],
    queryFn: () => Companies.getCenters(Number(company.id))
  })

  const refreshList = async (reset: boolean) => {
    await refetch()
  }

  return (
    <Card>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <TitleComponent text={t('centers')} variant={'h3'} paddingLeft={12} />
      </Box>
      <Grid container columns={4}>
        <Grid item xs={4}>
          <List
            items={items}
            selectable={false}
            columns={[
              {
                label: t('id'),
                slug: 'id'
              },
              {
                label: t('reference'),
                slug: 'reference'
              },
              {
                label: t('name'),
                slug: 'centerName'
              },
              {
                label: t('city'),
                slug: 'city'
              }
            ]}
            handleReset={() => handleFilter(refreshList, true)}
            sort={orderBy}
            handleSort={(property) => handleSort(refreshList, property)}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Card>
  )
}
