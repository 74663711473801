import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Stack } from '@mui/system'
import { Box, Typography, Input, Card, CircularProgress, IconButton } from '@mui/material'
import { useFetcher } from 'app/providers/fetcher.provider'
import { AddCircle } from '@mui/icons-material'
import { CopyButton } from 'app/components/buttons/copy-button.component'
import { Source } from 'api/models/sources'
import { CollapsableItem } from 'app/components/card/collapsable-item.component'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useQueryFetcherList } from 'app/hooks/use-query-fetcher-list'

const CollapsableSource = function ({
  customs,
  source,
  depth
}: {
  customs: Record<number, (source: Source) => string | React.ReactNode>
  source: Source
  depth: number
}) {
  let value: string | React.ReactNode = null
  if (customs && customs[depth]) {
    value = customs[depth](source)
  }

  return (
    <CollapsableItem label={source.label} content={value} depth={0}>
      {source.sources &&
        source.sources.map((source) => {
          let value: string | React.ReactNode = null
          if (customs && customs[1]) {
            value = customs[1](source)
          }
          return (
            <CollapsableItem key={source.id} label={source.label} content={value} depth={1}>
              {source.sources &&
                source.sources.map((source) => {
                  let value: string | React.ReactNode = null
                  if (customs && customs[2]) {
                    value = customs[2](source)
                  }
                  return (
                    <CollapsableItem key={source.id} label={source.label} content={value} depth={2}>
                      {' '}
                    </CollapsableItem>
                  )
                })}
            </CollapsableItem>
          )
        })}
    </CollapsableItem>
  )
}

export const SourcesView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { searchParams } = useFetcher()
  const { Sources } = useFetcherV2()
  const {
    items: sources,
    refreshList,
    isFetching: listIsLoading
  } = useQueryFetcherList({
    queryKey: ['sources', 'list'],
    queryFn: () => Sources.list(searchParams.toString())
  })

  useEffect(() => {
    refreshList(true).then()
  }, [])

  const custom = useMemo(
    () => ({
      2: (row: Source) => (
        <Stack paddingY={3} paddingLeft={4} flexDirection={'row'} gap={3} alignItems={'center'}>
          <Typography>{t('url')}:</Typography>
          <Input fullWidth readOnly value={row.link} sx={{ color: 'inherit' }} />
          <CopyButton text={row.link} />
        </Stack>
      )
    }),
    [t]
  )

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('sources')}
          <IconButton
            title={t('add_source')}
            aria-label={t('add_source')}
            color="primary"
            href={'/sources/add'}
            style={{ marginLeft: 7 }}
          >
            <AddCircle fontSize="small" />
          </IconButton>
        </Typography>
      </Box>
      <Box alignItems={'center'} justifyContent={'center'}>
        {listIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack sx={{ width: '100%' }}>
            {sources.map((source, index) => (
              <Card key={index} sx={{ marginBottom: 10 }}>
                <CollapsableSource source={source} depth={0} customs={custom} />
              </Card>
            ))}
          </Stack>
        )}
      </Box>
    </Container>
  )
}
