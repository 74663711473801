import React, { createContext, useContext, ReactNode, useMemo } from 'react'
import { appRouterV2 } from 'api'
import { useApp } from 'app/providers/app.provider'

type IFetcherContext = ReturnType<typeof appRouterV2>
const FetcherContext = createContext<IFetcherContext>({} as IFetcherContext)
interface IFetcherProviderProps {
  children: ReactNode
}

export const FetcherProvider = ({ children }: IFetcherProviderProps): React.JSX.Element => {
  const { user } = useApp()
  const fetchers = useMemo(() => appRouterV2({ token: user?.token }), [user])
  return <FetcherContext.Provider value={fetchers}>{children}</FetcherContext.Provider>
}

export const useFetcherV2 = () => useContext(FetcherContext)
